// In the database new line characters are stored using the '\n' character.
// For display in html we need to convert the newline to a <br> tag.
export default fields => context => {
  if (!Array.isArray(fields)) throw new Error('fields must be an array')

  fields.forEach(field => {
    if (context.result[field]) {
      context.result[field] = context.result[field].replace(/\n/g, '<br>')
    }
  })

  return context
}
