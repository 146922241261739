import React from 'react'
import { CardActions, CreateButton, RefreshButton } from 'react-admin'

const ListAcions = ({
  basePath,
  displayedFilters,
  filters,
  filterValues,
  resource,
  showFilter,
  disableCreate,
  extraActions,
}) => (
  <CardActions>
    {extraActions}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    {disableCreate ? null : <CreateButton basePath={basePath} />}
    <RefreshButton />
  </CardActions>
)

export default ListAcions
