import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import {
  DashboardMenuItem,
  MenuItemLink,
  WithPermissions,
  translate,
} from 'react-admin'
import { getResources } from 'ra-core/lib/reducer'
import _ from 'lodash'
import { MuseumIcon } from './resource/museum'
import getMuseumId from './util/getMuseumId'
import { requireOne } from './util/permissions'
import AttachMoney from '@material-ui/icons/AttachMoney'

const Menu = ({ onMenuTap, resources, museumId, translate }) => (
  <div>
    <DashboardMenuItem onClick={onMenuTap} />
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['director', 'staff'])(permissions) ? (
          <MenuItemLink
            key={'museum'}
            // TODO: Find a better way to get the users museum id
            to={`/museum/${museumId || getMuseumId()}`}
            primaryText={translate('resources.museum.name')}
            leftIcon={<MuseumIcon />}
            onClick={onMenuTap}
          />
        ) : null
      }
    </WithPermissions>
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['admin'])(permissions) ? (
          <MenuItemLink
            key={'museum'}
            to={`/museum`}
            primaryText={translate('resources.museum.name')}
            leftIcon={<MuseumIcon />}
            onClick={onMenuTap}
          />
        ) : null
      }
    </WithPermissions>
    {resources
      .filter(r => r.hasList && r.name !== 'museum')
      .map(resource => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={translate(`resources.${resource.name}.name`)}
          leftIcon={<resource.icon />}
          onClick={onMenuTap}
        />
      ))}
    <WithPermissions>
      {({ permissions }) => {
        return requireOne(['vinmasStaff', 'admin', 'director'])(permissions) ? (
          <MenuItemLink
            key={'turnover'}
            to={getMuseumId() ? `/turnover/${getMuseumId()}` : `/turnover`}
            primaryText={translate('resources.turnover.report')}
            leftIcon={<AttachMoney />}
            onClick={onMenuTap}
          />
        ) : null
      }}
    </WithPermissions>
  </div>
)

const mapStateToProps = state => ({
  museumId: _.get(state, 'admin.resources.museum.list.ids[0]'),
  resources: getResources(state),
})

const enhance = compose(
  translate,
  connect(mapStateToProps)
)

export default enhance(Menu)
