import React from 'react'
import {
  BulkDeleteWithConfirmButton,
  Create,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  FormDataConsumer,
  List,
  ReferenceField,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
} from 'react-admin'
import withStyles from '@material-ui/core/styles/withStyles'
import { TranslatedTextField } from '../components'
import { requireOne } from '../util/permissions'
import UserActions from '../components/ListActions'

export { default as UserIcon } from '@material-ui/icons/Group'

export const UserList = ({ permissions, ...props }) => (
  <List
    {...props}
    bulkActionButtons={<BulkDeleteWithConfirmButton />}
    actions={<UserActions />}
  >
    {requireOne(['admin'])(permissions) ? (
      <Datagrid>
        <TextField source="name" validate={[required()]} />
        <TextField source="email" validate={[required()]} />
        <TranslatedTextField
          source="role"
          namespace="roles"
          validate={[required()]}
        />
        <ReferenceField
          source="museum"
          reference="museum"
          validate={[required()]}
          linkType="show"
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
        <EditButton basePath="/user" />
      </Datagrid>
    ) : requireOne(['director'])(permissions) ? (
      <Datagrid>
        <TextField source="name" validate={[required()]} />
        <TextField source="email" validate={[required()]} />
        <TranslatedTextField
          source="role"
          namespace="roles"
          validate={[required()]}
        />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
        <EditButton basePath="/user" />
      </Datagrid>
    ) : null}
  </List>
)

const UserTitle = ({ record }) => {
  return <span>{record ? `${record.name}` : ''}</span>
}

const museumConstraint = value =>
  value !== 'admin' && value !== 'user' && value !== 'vinmasStaff'

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}

const EditToolbar = withStyles(toolbarStyles)(props => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteWithConfirmButton />
  </Toolbar>
))

export const UserEdit = ({ permissions, ...props }) => (
  <Edit title={<UserTitle />} {...props}>
    {requireOne(['admin'])(permissions) ? (
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput source="name" validate={[required()]} />
        <TextInput source="email" type="email" />
        <TextInput
          label="labels.reset_password"
          source="password"
          type="password"
        />
        <SelectInput
          source="role"
          validate={[required()]}
          choices={[
            { id: 'user', name: 'roles.user' },
            { id: 'admin', name: 'roles.admin' },
            { id: 'director', name: 'roles.director' },
            { id: 'staff', name: 'roles.staff' },
            { id: 'vinmasStaff', name: 'roles.vinmasStaff' },
          ]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            museumConstraint(formData.role) && (
              <ReferenceInput
                {...rest}
                source="museum"
                validate={[required()]}
                reference="museum"
                perPage={Infinity}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    ) : requireOne(['director'])(permissions) ? (
      <SimpleForm>
        <TextInput source="name" validate={[required()]} />
        <TextInput source="email" type="email" />
        <TextInput
          label="labels.reset_password"
          source="password"
          type="password"
        />
        <SelectInput
          {...props}
          source="role"
          validate={[required()]}
          choices={[
            { id: 'staff', name: 'roles.staff' },
            { id: 'user', name: 'roles.user' },
          ]}
        />
      </SimpleForm>
    ) : null}
  </Edit>
)

export const UserCreate = ({ permissions, ...props }) => (
  <Create title="titles.create_user" {...props}>
    {requireOne(['admin'])(permissions) ? (
      <SimpleForm redirect="list">
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            museumConstraint(formData.role) && (
              <ReferenceInput
                {...rest}
                source="museum"
                reference="museum"
                validate={[required()]}
                perPage={Infinity}
                allowEmpty
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <SelectInput
          {...props}
          source="role"
          choices={[
            { id: 'user', name: 'roles.user' },
            { id: 'admin', name: 'roles.admin' },
            { id: 'director', name: 'roles.director' },
            { id: 'staff', name: 'roles.staff' },
            { id: 'vinmasStaff', name: 'roles.vinmasStaff' },
          ]}
        />
        <TextInput source="name" validate={[required()]} />
        <TextInput source="email" type="email" validate={[required()]} />
        <TextInput source="password" type="password" validate={[required()]} />
      </SimpleForm>
    ) : requireOne(['director'])(permissions) ? (
      <SimpleForm redirect="list">
        <TextInput source="name" validate={[required()]} />
        <TextInput source="email" type="email" validate={[required()]} />
        <TextInput source="password" type="password" validate={[required()]} />
        <SelectInput
          {...props}
          source="role"
          choices={[
            { id: 'staff', name: 'roles.staff' },
            { id: 'user', name: 'roles.user' },
          ]}
        />
      </SimpleForm>
    ) : null}
  </Create>
)
