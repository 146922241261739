import React from 'react'
import {
  BulkDeleteWithConfirmButton,
  CheckboxGroupInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  FormTab,
  ImageField,
  ImageInput,
  List,
  NumberField,
  NumberInput,
  required,
  SimpleForm,
  TabbedForm,
  TextInput,
  WithPermissions,
} from 'react-admin'
import {
  IndoorFloorMapInput,
  ReviewActions,
  TextWithIndicator,
  Toolbar,
  MultiInput,
} from '../../components'
import { requireOne } from '../../util/permissions'
import FloorActions from '../../components/ListActions'

const langs = process.env.REACT_APP_SUPPORTED_LANGUAGES.split(',')
const baseLang = process.env.REACT_APP_BASE_LANGUAGE

export { default as FloorIcon } from '@material-ui/icons/Label'

export const FloorList = props => (
  <List
    {...props}
    sort={{ field: 'number', order: 'DESC' }}
    bulkActionButtons={<BulkDeleteWithConfirmButton />}
    actions={<FloorActions />}
  >
    <Datagrid>
      <NumberField source="number" />
      <TextWithIndicator source="name" />
      <EditButton basePath="/floor" />
    </Datagrid>
  </List>
)

const FloorTitle = ({ record }) => {
  return <span>{record ? `${record.number} ${record.name}` : ''}</span>
}

const CommonFormTab = ({ lang, ...props }) => (
  <FormTab {...props}>
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['admin', 'director'])(permissions) ? (
          <CheckboxGroupInput
            source="publishedTo"
            choices={[
              { id: 'WEBSITE', name: 'labels.website' },
              { id: 'APP', name: 'labels.app', disabled: true },
            ]}
            optionText="name"
            optionValue="id"
            label="resources.artifact.fields.publishedTo"
          />
        ) : null
      }
    </WithPermissions>
    <TextInput
      label="labels.name"
      source={lang === baseLang ? 'name' : `i18n.${lang}.name`}
      validate={lang === baseLang ? [required()] : []}
    />
    <NumberInput source="number" validate={[required()]} />
    <ImageInput
      label="labels.diagram"
      source={lang === baseLang ? 'diagram' : `i18n.${lang}.diagram`}
      accept="image/*"
    >
      <ImageField source="uri" title="title" />
    </ImageInput>
    <ImageInput
      label="labels.legend"
      source={lang === baseLang ? 'legend' : `i18n.${lang}.legend`}
      accept="image/*"
    >
      <ImageField source="uri" title="title" />
    </ImageInput>
    <IndoorFloorMapInput label="GeoJSON" source="location.geoJson" />
  </FormTab>
)

const CommonForm = props => (
  <SimpleForm {...props}>
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['admin', 'director'])(permissions) ? (
          <CheckboxGroupInput
            source="publishedTo"
            choices={[
              { id: 'WEBSITE', name: 'labels.website' },
              { id: 'APP', name: 'labels.app', disabled: true },
            ]}
            optionText="name"
            optionValue="id"
            label="resources.artifact.fields.publishedTo"
          />
        ) : null
      }
    </WithPermissions>
    <NumberInput source="number" validate={[required()]} />
    <MultiInput
      baseLang={baseLang}
      inputs={(baseLang, lang, key) => [
        <TextInput
          key={key}
          label="labels.name"
          source={lang === baseLang ? 'name' : `i18n.${lang}.name`}
          validate={lang === baseLang ? [required()] : []}
        />,
        <ImageInput
          key={key}
          label="labels.diagram"
          source={lang === baseLang ? 'diagram' : `i18n.${lang}.diagram`}
          accept="image/*"
        >
          <ImageField source="uri" title="title" />
        </ImageInput>,
        <ImageInput
          key={key}
          label="labels.legend"
          source={lang === baseLang ? 'legend' : `i18n.${lang}.legend`}
          accept="image/*"
        >
          <ImageField source="uri" title="title" />
        </ImageInput>,
      ]}
    />
    <div style={{ height: 10 }} />
    <IndoorFloorMapInput label="GeoJSON" source="location.geoJson" />
  </SimpleForm>
)

export const FloorEdit = props => (
  <Edit
    {...props}
    title={<FloorTitle />}
    actions={<ReviewActions allowPublish={['admin', 'director']} />}
  >
    <WithPermissions>
      {({ permissions, ...props }) =>
        requireOne(['admin', 'vinmasStaff'])(permissions) ? (
          <CommonForm {...props} redirect="list" toolbar={<Toolbar />} />
        ) : (
          <TabbedForm {...props} redirect="list" toolbar={<Toolbar />}>
            {langs.map(lang => (
              <CommonFormTab
                label={`languages.${lang}`}
                key={lang}
                lang={lang}
              />
            ))}
          </TabbedForm>
        )
      }
    </WithPermissions>
  </Edit>
)

export const FloorCreate = props => (
  <Create title="titles.create_floor" {...props}>
    <WithPermissions>
      {({ permissions, ...props }) =>
        requireOne(['admin', 'vinmasStaff'])(permissions) ? (
          <CommonForm {...props} redirect="list" />
        ) : (
          <TabbedForm {...props} redirect="list">
            {langs.map(lang => (
              <CommonFormTab
                label={`languages.${lang}`}
                key={lang}
                lang={lang}
              />
            ))}
          </TabbedForm>
        )
      }
    </WithPermissions>
  </Create>
)
