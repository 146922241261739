import React, { Fragment } from 'react'
import ReactMapboxGl, { Feature, Layer, ZoomControl } from 'react-mapbox-gl'
import { addField } from 'react-admin'
import center from '@turf/center'
import * as turf from '@turf/helpers'
import { FloorLayer, RoomLayer, WallLayer } from '../components/MapLayers'
import FileReaderInput from 'react-file-reader-input'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import _ from 'lodash'
import pin from '../images/pin.png'

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  minZoom: 17.5,
  maxZoom: 21,
})

class IndoorFloorMapInput extends React.Component {
  createMarker = () => {
    const roomGeoJson = _.get(this.props, 'input.value.geoJson')

    if (!roomGeoJson) return null

    // Place default entrance and exit markers in the center of the room.
    return center(roomGeoJson)
  }

  state = {
    floors: [],
    currentFloor: 0,
    entranceMarker: _.get(
      this.props,
      'input.value.entrance',
      this.createMarker()
    ),
    exitMarker: _.get(this.props, 'input.value.exit', this.createMarker()),
  }

  async componentDidMount() {
    const res = await axios.get(
      `${process.env.REACT_APP_API_HOST ||
        window.env
          .API_URL}/graphql?query=%7B%0A%20%20museum%20%7B%0A%20%20%20%20floors%20%7B%0A%20%20%20%20%20%20data%20%7B%0A%20%20%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%20%20name%0A%20%20%20%20%20%20%20%20number%0A%20%20%20%20%20%20%20%20map%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D`,
      {
        headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY },
      }
    )
    const floors = _.get(res, 'data.data.museum.floors.data', [])
    this.setState({ floors })
  }

  render() {
    const entrancePinImage = new Image()
    const exitPinImage = new Image()
    entrancePinImage.src = pin
    exitPinImage.src = pin

    const { input, floor } = this.props
    const flr = _.find(this.state.floors, { _id: floor })
    const floorGeoJSON = _.get(flr, 'map')

    return (
      <Fragment>
        {input.value.geoJson && (
          <Map
            style="mapbox://styles/lihop/cjm75gm470dc32rmpfw2bd8x2" // eslint-disable-line
            containerStyle={{
              height: 500,
              width: 500,
              maxWidth: '100%',
            }}
            center={center(input.value.geoJson).geometry.coordinates}
            zoom={[17.5]}
            bearing={[14.5]}
          >
            <ZoomControl />
            {floorGeoJSON && <FloorLayer data={floorGeoJSON} />}
            <RoomLayer data={input.value.geoJson} />
            {floorGeoJSON && <WallLayer data={floorGeoJSON} />}
            {this.state.entranceMarker && (
              <Layer
                type="symbol"
                layout={{
                  'text-field': 'lối vào',
                  'icon-image': 'entrancePin',
                  'icon-size': 0.1,
                  'icon-anchor': 'bottom',
                }}
                images={['entrancePin', entrancePinImage]}
              >
                <Feature
                  coordinates={this.state.entranceMarker.geometry.coordinates}
                  draggable={true}
                  onDragEnd={({ lngLat: { lng, lat } }) => {
                    const point = turf.point([lng, lat])

                    this.props.input.onChange({
                      ...this.props.input.value,
                      entrance: point,
                    })

                    this.setState({ entranceMarker: point })
                  }}
                />
              </Layer>
            )}
            {this.state.exitMarker && (
              <Layer
                type="symbol"
                layout={{
                  'text-field': 'lối ra',
                  'icon-image': 'exitPin',
                  'icon-size': 0.1,
                  'icon-anchor': 'bottom',
                }}
                images={['exitPin', exitPinImage]}
              >
                <Feature
                  coordinates={this.state.exitMarker.geometry.coordinates}
                  draggable={true}
                  onDragEnd={({ lngLat: { lng, lat } }) => {
                    const point = turf.point([lng, lat])

                    this.props.input.onChange({
                      ...this.props.input.value,
                      exit: point,
                    })

                    this.setState({ exitMarker: point })
                  }}
                />
              </Layer>
            )}
          </Map>
        )}
        <FileReaderInput
          as="binary"
          id="geojson-file-input"
          onChange={(_, [[e]]) => {
            try {
              const value = JSON.parse(e.target.result.toString('utf8'))

              // Check value is geojson
              center(value)

              input.onChange({ ...this.props.input.value, geoJson: value })
            } catch (e) {
              input.onChange({ ...this.props.input.value, geoJson: null })
            }
          }}
        >
          <Button variant="outlined">Upload GeoJSON</Button>
        </FileReaderInput>
      </Fragment>
    )
  }
}

export default addField(IndoorFloorMapInput)
