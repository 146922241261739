// Automatically set the museum id based on the users auth token
export const queryClient = requestHandler => async (type, resource, params) => {
  if (
    ['GET_LIST'].includes(type) &&
    ['artifact', 'author', 'category', 'user'].includes(resource)
  ) {
    if (!params || !params.filter || !params.filter.q) {
      return requestHandler(type, resource, params)
    }

    const searchString = params.filter.q
    params.filter = {
      'name[$search]': searchString,
    }
  }
  return requestHandler(type, resource, params)
}

export default queryClient
