import React from 'react'
import {
  BooleanField,
  BooleanInput,
  BulkDeleteWithConfirmButton,
  CheckboxGroupInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  FileInput,
  FormDataConsumer,
  FormInput,
  FormTab,
  ImageField,
  ImageInput,
  List,
  LongTextInput,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  WithPermissions,
} from 'react-admin'
import {
  AudioField,
  IndoorBlockMapInput,
  MultiInput,
  MuseumSelector,
  ReviewActions,
  StatusBadge,
  TextWithIndicator,
  ThumbnailInput,
  Toolbar,
} from '../../components'
import { requireOne } from '../../util/permissions'
import BlockActions from '../../components/ListActions'
import _ from 'lodash'

export { default as BlockIcon } from '@material-ui/icons/Label'

const langs = process.env.REACT_APP_SUPPORTED_LANGUAGES.split(',')
const baseLang = process.env.REACT_APP_BASE_LANGUAGE

const BlockTitle = ({ record }) => {
  return <span>{record ? `${record.name}` : ''}</span>
}

export const BlockList = props => (
  <List
    {...props}
    bulkActionButtons={<BulkDeleteWithConfirmButton />}
    actions={<BlockActions />}
  >
    <Datagrid>
      <StatusBadge>
        <ReferenceField
          label="resources.block.fields.floor"
          source="floor"
          reference="floor"
        >
          <TextWithIndicator source="name" />
        </ReferenceField>
      </StatusBadge>
      <TextField source="name" />
      <TextField source="description" />
      <BooleanField label="labels.public" source="public" />
      <EditButton basePath="/block" />
    </Datagrid>
  </List>
)

const CommonFormTab = ({ lang, ...props }) => (
  <FormTab {...props}>
    <MuseumSelector {...props} />
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['admin', 'director'])(permissions) ? (
          <CheckboxGroupInput
            source="publishedTo"
            choices={[
              { id: 'WEBSITE', name: 'labels.website' },
              { id: 'APP', name: 'labels.app', disabled: true },
            ]}
            optionText="name"
            optionValue="id"
            label="resources.artifact.fields.publishedTo"
          />
        ) : null
      }
    </WithPermissions>
    <NumberInput source="number" />
    <TextInput
      label="labels.name"
      source={lang === baseLang ? 'name' : `i18n.${lang}.name`}
      fullWidth
    />
    <TextInput
      label="labels.description"
      source={lang === baseLang ? 'description' : `i18n.${lang}.description`}
      fullWidth
    />
    <BooleanInput label="labels.public" source="public" />
    <ReferenceInput
      label="titles.choose_floor"
      source="floor"
      reference="floor"
      perPage={Infinity}
      allowEmpty
    >
      <SelectInput
        optionText={baseLang === lang ? 'name' : `i18n.${lang}.name`}
      />
    </ReferenceInput>
    <FormDataConsumer>
      {({ formData, ...props }) => (
        <IndoorBlockMapInput
          {...props}
          label="GeoJSON"
          source="location"
          floor={_.get(formData, 'floor')}
        />
      )}
    </FormDataConsumer>
    <ImageInput
      label="labels.diagram"
      source={lang === baseLang ? 'diagram' : `i18n.${lang}.diagram`}
      accept="image/*"
    >
      <ImageField source="uri" title="title" />
    </ImageInput>
  </FormTab>
)

const CommonForm = props => (
  <SimpleForm {...props}>
    <MuseumSelector {...props} />
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['admin', 'director'])(permissions) ? (
          <CheckboxGroupInput
            source="publishedTo"
            choices={[
              { id: 'WEBSITE', name: 'labels.website' },
              { id: 'APP', name: 'labels.app', disabled: true },
            ]}
            optionText="name"
            optionValue="id"
            label="resources.artifact.fields.publishedTo"
          />
        ) : null
      }
    </WithPermissions>
    <NumberInput source="number" />
    <BooleanInput label="labels.public" source="public" />
    <ImageInput source="images" multiple accept="image/*">
      <ImageField source="uri" title="title" />
    </ImageInput>
    <FormDataConsumer>
      {({ formData, ...rest }) => (
        <ThumbnailInput
          {...rest}
          source="thumbnail"
          images={formData ? formData.images : []} // Sometimes when deleting a record formData will be undefined so this would throw an error without checking formData is truthy.
        />
      )}
    </FormDataConsumer>
    <MultiInput
      baseLang={baseLang}
      inputs={(baseLang, lang, key) => [
        <TextInput
          key={key}
          label="labels.name"
          source={lang === baseLang ? 'name' : `i18n.${lang}.name`}
          fullWidth
        />,
        <TextInput
          key={key}
          label="labels.description"
          source={
            lang === baseLang ? 'description' : `i18n.${lang}.description`
          }
          fullWidth
        />,
        <ReferenceInput
          key={key}
          label="titles.choose_floor"
          source="floor"
          reference="floor"
          perPage={Infinity}
          allowEmpty
        >
          <SelectInput
            optionText={baseLang === lang ? 'name' : `i18n.${lang}.name`}
          />
        </ReferenceInput>,
        <ImageInput
          key={key}
          label="labels.diagram"
          source={lang === baseLang ? 'diagram' : `i18n.${lang}.diagram`}
          accept="image/*"
        >
          <ImageField source="uri" title="title" />
        </ImageInput>,
        <FormInput
          key={key}
          input={
            <FileInput
              label="labels.audio"
              source={lang === baseLang ? 'audio' : `i18n.${lang}.audio`}
              accept="audio/*"
            >
              <AudioField source="uri" title="title" />
            </FileInput>
          }
        />,
        <FormInput
          key={key}
          input={
            <LongTextInput
              label="labels.transcript"
              source={
                lang === baseLang
                  ? 'audioTranscript'
                  : `i18n.${lang}.audioTranscript`
              }
            />
          }
        />,
      ]}
    />
    <div style={{ height: 10 }} />
    <FormDataConsumer>
      {({ formData, ...props }) => (
        <IndoorBlockMapInput
          {...props}
          label="GeoJSON"
          source="location"
          floor={_.get(formData, 'floor')}
        />
      )}
    </FormDataConsumer>
  </SimpleForm>
)

export const BlockEdit = props => (
  <Edit
    {...props}
    title={<BlockTitle />}
    actions={<ReviewActions allowPublish={['admin', 'director']} />}
  >
    <WithPermissions>
      {({ permissions, ...props }) =>
        requireOne(['admin', 'vinmasStaff'])(permissions) ? (
          <CommonForm {...props} toolbar={<Toolbar />} />
        ) : (
          <TabbedForm {...props} toolbar={<Toolbar />}>
            {langs.map(lang => (
              <CommonFormTab
                label={`languages.${lang}`}
                key={lang}
                lang={lang}
              />
            ))}
          </TabbedForm>
        )
      }
    </WithPermissions>
  </Edit>
)

export const BlockCreate = props => (
  <Create title="titles.create_block" {...props}>
    <WithPermissions>
      {({ permissions, ...props }) =>
        requireOne(['admin', 'vinmasStaff'])(permissions) ? (
          <CommonForm {...props} redirect="list" />
        ) : (
          <TabbedForm {...props} redirect="list">
            {langs.map(lang => (
              <CommonFormTab
                label={`languages.${lang}`}
                key={lang}
                lang={lang}
              />
            ))}
          </TabbedForm>
        )
      }
    </WithPermissions>
  </Create>
)
