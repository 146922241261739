import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import startsWith from 'lodash/startsWith'

// If the video is a blob we don't need a token, but we do need a token
// if it is being played from the vod server.
const getURL = url =>
  startsWith(url, 'blob')
    ? url
    : `${url}?access_token=${localStorage.getItem('feathers-jwt')}`

const styles = {
  list: {
    display: 'flex',
    listStyleType: 'none',
  },
  video: {
    margin: '0.5rem',
    maxWidth: 750,
  },
}

export const VideoField = ({ elStyle = {}, record, source, src, title }) => {
  const sourceValue = get(record, source)
  if (!sourceValue) {
    return <div />
  }

  if (Array.isArray(sourceValue)) {
    const style = {
      ...styles.list,
      ...elStyle,
    }
    return (
      <ul style={style}>
        {sourceValue.map((file, index) => {
          const titleValue = get(file, title) || title
          const srcValue = get(file, src) || title

          return (
            <li key={index}>
              <video
                controls
                alt={titleValue}
                title={titleValue}
                src={getURL(srcValue)}
                style={styles.video}
              />
            </li>
          )
        })}
      </ul>
    )
  }

  const titleValue = get(record, title) || title

  return (
    <div style={elStyle}>
      <video
        controls
        alt={titleValue}
        title={titleValue}
        src={getURL(sourceValue)}
        style={styles.video}
      />
    </div>
  )
}

VideoField.propTypes = {
  elStyle: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  title: PropTypes.string,
}

export default VideoField
