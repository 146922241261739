export const PREVIEW_RECORD = 'PREVIEW_RECORD'

export const previewRecord = (
  url /* Url at which the record can be previewed. The user should be redirected here. */,
  redirect /* Function that will be called to redirect the user */
) => {
  return {
    type: PREVIEW_RECORD,
    payload: {
      url,
      redirect,
    },
  }
}
