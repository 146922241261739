import _ from 'lodash'

const CHROME_MAX_URL_LENGTH = 5658 // Maximum URL length for https requests in Chrome browser.
const MAX_QUERY_LENGTH = CHROME_MAX_URL_LENGTH / 2 // To account for URL string separators such as '&' and '?' and other associated formatting.

export default () => async context => {
  const queryString = JSON.stringify(context.params.query)
  const limit = _.get(context, 'params.query.$limit')
  const includedIds = _.get(context, 'params.query._id.$in')

  if (!limit || !includedIds || queryString.length < MAX_QUERY_LENGTH) return

  // Break down request into 50 items at a time.
  const chunks = _.chunk(includedIds, 50)

  const results = await Promise.all(
    chunks.map(chunk => {
      return context.service.find({
        query: { $limit: chunk.length, _id: { $in: chunk } },
      })
    })
  )

  context.result = results.reduce(
    (result, chunk) => {
      result.total = result.total + chunk.total
      result.data = _.concat(result.data, chunk.data)
      return result
    },
    { total: 0, limit, skip: 0, data: [] }
  )
}
