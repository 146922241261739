import * as React from 'react'
import * as PropTypes from 'prop-types'

const containerStyle = {
  position: 'absolute',
  zIndex: 10,
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, .3)',
  border: '1px solid rgba(0, 0, 0, 0.1)',
}

const positions = {
  'top-right': { top: 10, right: 10, bottom: 'auto', left: 'auto' },
  'top-left': { top: 10, left: 10, bottom: 'auto', right: 'auto' },
  'bottom-right': { bottom: 10, right: 10, top: 'auto', left: 'auto' },
  'bottom-left': { bottom: 10, left: 10, top: 'auto', right: 'auto' },
}

const buttonStyle = {
  backgroundColor: '#f9f9f9',
  opacity: 0.95,
  transition: 'background-color 0.16s ease-out',
  cursor: 'pointer',
  border: 0,
  height: 26,
  width: 26,
  outline: 0,
}

const buttonStyleSelected = {
  backgroundColor: '#fff',
  opacity: 1,
}

const buttonStyleHovered = buttonStyleSelected

const buttonStylePlus = {
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  borderTopLeftRadius: 2,
  borderTopRightRadius: 2,
}

const [PLUS, MINUS] = [0, 1]
const POSITIONS = Object.keys(positions)

export default class ZoomControl extends React.Component {
  static defaultProps = {
    position: POSITIONS[1],
    zoomDiff: 0.5,
    onControlClick: (map, zoomDiff) => {
      map.zoomTo(map.getZoom() + zoomDiff)
    },
  }

  state = {
    hover: undefined,
  }

  static contextTypes = {
    map: PropTypes.object,
  }

  onMouseOut = () => {
    this.setState({ hover: undefined })
  }

  plusOver = () => {
    if (PLUS !== this.state.hover) {
      this.setState({ hover: PLUS })
    }
  }

  minusOver = () => {
    if (MINUS !== this.state.hover) {
      this.setState({ hover: MINUS })
    }
  }

  onClickPlus = () => {}

  render() {
    const { position, style, className, tabIndex } = this.props
    const { hover } = this.state
    const plusStyle = {
      ...buttonStyle,
      ...buttonStylePlus,
      ...(hover === PLUS ? buttonStyleHovered : {}),
    }

    return (
      <div
        className={className}
        tabIndex={tabIndex}
        style={{ ...containerStyle, ...positions[position], ...style }}
      >
        {this.props.levels.map((level, index) => (
          <button
            key={level.number}
            type="button"
            style={
              this.props.currentLevel === index
                ? { ...plusStyle, ...buttonStyleSelected }
                : plusStyle
            }
            aria-label={`Level ${level.number}`}
            onClick={() => this.props.onClick(level)}
          >
            {level.number}
          </button>
        ))}
      </div>
    )
  }
}
