// This file was copied directly from the react-admin source code:
// https://github.com/marmelab/react-admin-demo/blob/master/src/Login.js
// It was copied here so that modifications could be made to the appearance of the login page.
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, propTypes, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import Avatar from '@material-ui/core/Avatar'
import RaisedButton from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import LockIcon from '@material-ui/icons/LockOutline'
import {
  Notification,
  translate,
  userLogin as userLoginAction,
} from 'react-admin'

import theme from '../theme/default'
import logo from '../images/logo.png'

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    minWidth: 300,
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    display: 'flex',
  },
  hint: {
    textAlign: 'center',
    marginTop: '1em',
    color: '#ccc',
  },
}

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField error={touched && error} {...inputProps} {...props} fullWidth />
)

class Login extends Component {
  login = ({ username, password }) => {
    const { userLogin, location } = this.props
    userLogin(
      { username, password },
      location.state ? location.state.nextPathname : '/'
    )
  }

  render() {
    const { classes, handleSubmit, submitting, translate } = this.props
    return (
      <MuiThemeProvider theme={theme}>
        <div
          style={{
            ...styles.main,
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{ width: 300, paddingBottom: '1em' }}
          />
          <Card style={styles.card}>
            <div className={classes.avatar}>
              <Avatar
                style={{
                  backgroundColor: 'rgb(254, 64, 80)',
                  width: '60px',
                  height: '60px',
                }}
              >
                <LockIcon style={{ fontSize: '36px' }} />
              </Avatar>
            </div>
            <form onSubmit={handleSubmit(this.login)}>
              <div style={styles.form}>
                <div style={styles.input}>
                  <Field
                    name="username"
                    component={renderInput}
                    label={translate('ra.auth.username')}
                  />
                </div>
                <div style={styles.input}>
                  <Field
                    name="password"
                    component={renderInput}
                    label={translate('ra.auth.password')}
                    type="password"
                  />
                </div>
              </div>
              <CardActions>
                <RaisedButton
                  type="submit"
                  color="primary"
                  disabled={submitting}
                  variant="contained"
                  fullWidth
                >
                  {translate('ra.auth.sign_in')}
                </RaisedButton>
              </CardActions>
            </form>
          </Card>
          <Notification />
        </div>
      </MuiThemeProvider>
    )
  }
}

Login.propTypes = {
  ...propTypes,
  authClient: PropTypes.func,
  previousRoute: PropTypes.string,
  theme: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
}

Login.defaultProps = {
  theme: {},
}

const enhance = compose(
  translate,
  reduxForm({
    form: 'signIn',
    validate: (values, props) => {
      const errors = {}
      const { translate } = props
      if (!values.username)
        errors.username = translate('ra.validation.required')
      if (!values.password)
        errors.password = translate('ra.validation.required')
      return errors
    },
  }),
  connect(
    null,
    { userLogin: userLoginAction }
  ),
  withStyles(styles)
)

export default enhance(Login)
