import React from 'react'
import { SaveButton, Toolbar } from 'react-admin'
import withStyles from '@material-ui/core/styles/withStyles'

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}

export default withStyles(toolbarStyles)(props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
))
