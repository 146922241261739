import React from 'react'
import { ImageInput } from 'react-admin'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'

const DroppableImageInput = props => {
  const onDragEnd = ({ destination, source }) => {
    if (!destination || destination.index === source.index) return

    const { images, updateImages } = props
    const newImages = Array.from(images)

    const sourceImage = images[source.index]
    newImages.splice(source.index, 1)
    newImages.splice(destination.index, 0, sourceImage)
    updateImages(newImages)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="images" direction="horizontal">
        {provided => {
          return (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <ImageInput {...props} />
            </div>
          )
        }}
      </Droppable>
    </DragDropContext>
  )
}

const mapStateToProps = (state, props) => ({
  images: state.form['record-form']
    ? state.form['record-form'].values[props.source]
    : [],
})

const mapDispatchToProps = (dispatch, props) => ({
  updateImages: images =>
    dispatch({
      type: '@@redux-form/BLUR',
      meta: {
        form: 'record-form',
        field: props.source,
        touch: true,
      },
      payload: images,
    }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DroppableImageInput)
