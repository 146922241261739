import React, { Component } from 'react'
import Gallery from 'react-photo-gallery'
import Lightbox from 'react-images'

class ImagesField extends Component {
  state = {
    currentImage: 0,
  }

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    })
  }

  openLightbox = (event, obj) => {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    })
  }

  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
    })
  }

  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
    })
  }

  render() {
    const { source, record = { images: [] } } = this.props

    const sizes = ['1024', '800', '500', '320']
    const images = record[source].map(i => ({
      src: i.uri,
      //srcSet: sizes.map(size => `localhost:8081/${size}x/${i.uri} ${size}w`),
      sizes,
    }))

    return (
      <div {...this.props}>
        <Gallery
          margin={10}
          columns={4}
          photos={images}
          onClick={this.openLightbox}
          ImageComponent={props => {
            return (
              <img
                src={props.photo.src}
                alt={props.photo.alt || ''}
                onClick={e => props.onClick(e, { index: props.index })}
                style={{ margin: 2, width: 167 }}
              />
            )
          }}
        />
        <Lightbox
          images={images}
          onClose={this.closeLightbox}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          currentImage={this.state.currentImage}
          isOpen={this.state.lightboxIsOpen}
        />
      </div>
    )
  }
}

export default ImagesField
