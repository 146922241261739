import React from 'react'
import ReactMapboxGl, { ZoomControl } from 'react-mapbox-gl'
import { addField } from 'react-admin'
import * as turf from '@turf/helpers'
import buffer from '@turf/buffer'
import bbox from '@turf/bbox'
import bboxPolygon from '@turf/bbox-polygon'
import center from '@turf/center'
import rotate from '@turf/transform-rotate'
import _ from 'lodash'
import pin from '../images/pin.png'
import LevelControl from './LevelControl'
import { FloorLayer, WallLayer, ArtifactLayer } from '../components/MapLayers'
import ArtifactFeature from '../components/ArtifactFeature'
import axios from 'axios'
import * as meta from '@turf/meta'
import contains from '@turf/boolean-contains'
import globalData from "../global/global-data";

const DEFAULT_COORD = [105.8368718, 21.030667]

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  minZoom: 17.5,
  maxZoom: 21,
})

const createArtifactMarker = coordinates => {
  const point = turf.point(coordinates)
  const buff = buffer(point, 0.5, { units: 'meters' })
  const box = bbox(buff)
  const poly = bboxPolygon(box)
  return rotate(poly, 14.5, { mutate: false })
}

export const levelWithRoom = (roomId, floors) => {
  let i = 0
  let cent = DEFAULT_COORD

  if (!floors) return i

  i = floors.length - 1

  floors.forEach((floor, index) => {
    if (
      floor.map &&
      floor.map.features &&
      _.find(floor.map.features, {
        properties: { type: 'room', _id: roomId },
      })
    ) {
      i = index

      try {
        cent = center(
          _.find(floor.map.features, {
            properties: { type: 'room', _id: roomId },
          })
        ).geometry.coordinates
      } catch (e) {
        cent = DEFAULT_COORD
      }
    }
  })

  return [i, cent]
}

class IndoorMapInput extends React.Component {
  state = {
    artifactMarker: _.get(
      this,
      'props.input.value.geoJson',
      createArtifactMarker(DEFAULT_COORD)
    ),
    currentLevel: levelWithRoom(_.get(this, 'props.input.value.room'), globalData.data.floors)[0] || 0,
    floors: globalData.data.floors || [],
  }

  levelWithRoom = roomId => {
    let i = 0
    let cent = DEFAULT_COORD

    if (!this.state || !this.state.floors) return i

    i = this.state.floors.length - 1

    this.state.floors.forEach((floor, index) => {
      if (
        floor.map &&
        floor.map.features &&
        _.find(floor.map.features, {
          properties: { type: 'room', _id: roomId },
        })
      ) {
        i = index

        try {
          cent = center(
            _.find(floor.map.features, {
              properties: { type: 'room', _id: roomId },
            })
          ).geometry.coordinates
        } catch (e) {
          cent = DEFAULT_COORD
        }
      }
    })

    return [i, cent]
  }

  getRoomFromPoint = point => {
    let room = null

    const geojson = _.get(this, `state.floors.[${this.state.currentLevel}].map`)

    if (geojson) {
      meta.featureEach(geojson, feature => {
        if (contains(feature, center(point))) {
          room = _.get(feature, 'properties._id', room)
        }
      })
    }

    return room
  }

  async componentDidMount() {
    const room = _.get(this, 'props.input.value.room')

    this.setState({
      currentLevel: this.levelWithRoom(this.props.room || room)[0],
    })
  }

  componentDidUpdate(prevProps) {
    if (
      _.get(prevProps, 'input.value.room') !==
      _.get(this, 'props.input.value.room')
    ) {
      const [currentLevel, cent] = this.levelWithRoom(
        _.get(this, 'props.input.value.room')
      )
      const artifactMarker = createArtifactMarker(cent)
      this.setState({ currentLevel, artifactMarker })
    }
  }

  render() {
    const image = new Image()
    image.src = pin

    const geojson = _.get(
      this,
      `state.floors.[${this.state.currentLevel}].map`,
      { type: 'FeatureCollection', features: [] }
    )

    return (
      <Map
        style="mapbox://styles/lihop/cjm75gm470dc32rmpfw2bd8x2" // eslint-disable-line
        containerStyle={{
          height: 500,
          width: 500,
          maxWidth: '100%',
        }}
        center={center(this.state.artifactMarker).geometry.coordinates}
        zoom={[20]}
        bearing={[14.5]}
      >
        <ZoomControl />
        <LevelControl
          levels={this.state.floors}
          currentLevel={this.state.currentLevel}
          onClick={async level => {
            const levelIndex = this.state.floors.indexOf(level)
            await this.setState({ currentLevel: levelIndex })
            this.props.input.onChange({
              ...this.props.input.value,
              room: this.getRoomFromPoint(this.state.artifactMarker),
            })
          }}
        />
        <FloorLayer data={geojson} />
        <WallLayer data={geojson} />
        <ArtifactLayer data={geojson} hide={this.props.selectedId} />
        <ArtifactFeature
          data={this.state.artifactMarker}
          onDragEnd={artifactMarker => {
            const room = this.getRoomFromPoint(artifactMarker)

            this.props.input.onChange({
              room,
              geoJson: artifactMarker,
            })

            this.setState({
              artifactMarker,
              zoomLevel: 20,
            })
          }}
        />
        <ArtifactLayer data={this.state.artifactMarker} selected={true} />
      </Map>
    )
  }
}

export default addField(IndoorMapInput)
