import React, { Fragment } from 'react'
import { get } from 'lodash'
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  CheckboxGroupInput,
  Create,
  Edit,
  FileInput,
  Filter,
  FormInput,
  FormDataConsumer,
  FormTab,
  ImageField,
  ImageInput,
  List,
  LongTextInput,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  WithPermissions,
} from 'react-admin'
import {
  AudioField,
  DimensionInput,
  IndoorMapInput,
  MultiInput,
  MuseumSelector,
  QRCodeField,
  ReviewActions,
  ThumbnailInput,
  Toolbar,
} from '../../components'
import GridList from './GridList'
import validURL from '../../util/validURL'
import { requireOne } from '../../util/permissions'
import AutocompleteArrayInput from '../../components/AutocompleteArrayInput'
import ArtifactActions from '../../components/ListActions'
import getYouTubeID from 'get-youtube-id'
import DroppableImageInput from '../../components/DroppableImageInput/DroppableImageInput'
import DraggableImageField from '../../components/DroppableImageInput/DraggableImageField'

const langs = process.env.REACT_APP_SUPPORTED_LANGUAGES.split(',')
const baseLang = process.env.REACT_APP_BASE_LANGUAGE

const validYouTubeLink = value => {
  if (!value) return undefined
  return getYouTubeID(value) ? undefined : 'Địa chỉ URL YouTube không hợp lệ.'
}

export const ArtifactFilter = props => (
  <Filter {...props}>
    <TextInput label="labels.search_artifact" source="name[$search]" alwaysOn />
    <ReferenceInput source="authors" reference="author" perPage={Infinity}>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput source="category" reference="category" perPage={Infinity}>
      <SelectInput source="name" />
    </ReferenceInput>
    <ReferenceInput source="block" reference="block" perPage={Infinity}>
      <SelectInput source="name" />
    </ReferenceInput>
    <ReferenceArrayInput
      source="tags"
      reference="tag"
      perPage={Infinity}
      allowEmpty
    >
      <SelectArrayInput optionText="name" options={{ fullWidth: true }} />
    </ReferenceArrayInput>
    <SelectInput
      source="status"
      choices={[
        { id: 'onDisplay', name: 'resources.artifact.statuses.on_display' },
        { id: 'inStorage', name: 'resources.artifact.statuses.in_storage' },
        { id: 'onLoan', name: 'resources.artifact.statuses.on_loan' },
      ]}
    />
    <BooleanInput
      label="labels.expert_recommended"
      source="expertRecommended"
    />
  </Filter>
)

export { default as ArtifactIcon } from '@material-ui/icons/Star'

export const ArtifactList = props => (
  <List
    {...props}
    perPage={12}
    filters={<ArtifactFilter />}
    actions={<ArtifactActions />}
  >
    <GridList />
  </List>
)

const ArtifactTitle = ({ record }) => {
  return <span>{record ? `${record.name}` : ''}</span>
}

const CommonFormTab = ({ lang, edit, ...props }) => (
  <FormTab {...props}>
    <MuseumSelector {...props} />
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['admin'])(permissions) ? (
          <CheckboxGroupInput
            source="publishedTo"
            choices={[
              { id: 'WEBSITE', name: 'labels.website' },
              { id: 'APP', name: 'labels.app', disabled: true },
            ]}
            optionText="name"
            optionValue="id"
            label="resources.artifact.fields.publishedTo"
          />
        ) : null
      }
    </WithPermissions>
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['staff', 'director'])(permissions) ? (
          <CheckboxGroupInput
            source="publishedTo"
            choices={[{ id: 'WEBSITE', name: 'labels.website' }]}
            optionText="name"
            optionValue="id"
            label="resources.artifact.fields.publishedTo"
          />
        ) : null
      }
    </WithPermissions>
    <CheckboxGroupInput
      source="publishedLanguages"
      choices={[
        { id: 'vi', name: 'languages.vi' },
        { id: 'en', name: 'languages.en' },
        { id: 'fr', name: 'languages.fr' },
      ]}
    />
    <TextInput
      label="labels.name"
      source={baseLang === lang ? 'name' : `i18n.${lang}.name`}
      validate={baseLang === lang ? [required()] : []}
      fullWidth
    />
    <ReferenceInput
      source="category"
      reference="category"
      perPage={Infinity}
      allowEmpty
    >
      <SelectInput
        optionText={baseLang === lang ? 'name' : `i18n.${lang}.name`}
      />
    </ReferenceInput>
    <ReferenceArrayInput
      source="authors"
      reference="author"
      perPage={Infinity}
      allowEmpty
    >
      <AutocompleteArrayInput optionText="name" options={{ fullWidth: true }} />
    </ReferenceArrayInput>
    <NumberInput source="code" />
    {edit && <QRCodeField label="qrCode" source="qrCode.uri" title="name" />}
    <TextInput label="Năm khởi tạo" source="date" fullWidth />
    <DimensionInput />
    <NumberInput source="weight" />
    <ImageInput source="images" multiple accept="image/*">
      <ImageField source="uri" title="title" />
    </ImageInput>
    <ReferenceArrayInput
      source="tags"
      reference="tag"
      perPage={Infinity}
      allowEmpty
    >
      <AutocompleteArrayInput optionText="name" options={{ fullWidth: true }} />
    </ReferenceArrayInput>
    <RadioButtonGroupInput
      source="status"
      choices={[
        { id: 'onDisplay', name: 'resources.artifact.statuses.on_display' },
        { id: 'inStorage', name: 'resources.artifact.statuses.in_storage' },
        { id: 'onLoan', name: 'resources.artifact.statuses.on_loan' },
      ]}
    />
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        switch (formData.status) {
          case 'onDisplay':
            return (
              <Fragment>
                <ReferenceInput
                  {...rest}
                  label="labels.block"
                  source="location.room"
                  reference="block"
                  perPage={Infinity}
                  allowEmpty
                >
                  <SelectInput
                    optionText={
                      baseLang === lang ? 'name' : `i18n.${lang}.name`
                    }
                  />
                </ReferenceInput>
                <IndoorMapInput source="location" selectedId={formData._id} />
              </Fragment>
            )
          case 'inStorage':
            return (
              <ReferenceInput
                {...rest}
                label="labels.block"
                source={lang === baseLang ? 'block' : `i18n.${lang}.block`}
                reference="block"
                allowEmpty
                perPage={Infinity}
              >
                <SelectInput
                  optionText={baseLang === lang ? 'name' : `i18n.${lang}.name`}
                />
              </ReferenceInput>
            )
          case 'onLoan':
            return (
              <TextInput
                {...rest}
                source="borrowingOrganization"
                label="Tổ chức mượn"
              />
            )
          case 'sold':
            return <TextInput {...rest} source="salePerson" label="Người mua" />
          default:
            return null
        }
      }}
    </FormDataConsumer>
    <LongTextInput
      label="labels.description"
      source={lang === baseLang ? 'description' : `i18n.${lang}.description`}
    />
    <ArrayInput
      fullWidth
      label="resources.artifact.fields.referenceLinks"
      source="referenceLinks"
    >
      <SimpleFormIterator>
        <LongTextInput
          label="labels.name"
          source="name"
          validate={required()}
        />
        <LongTextInput
          label="URL"
          source="url"
          validate={[validURL, required()]}
        />
      </SimpleFormIterator>
    </ArrayInput>
  </FormTab>
)

const CommonForm = ({ edit, ...props }) => (
  <SimpleForm {...props}>
    <MuseumSelector {...props} />
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['admin'])(permissions) ? (
          <CheckboxGroupInput
            source="publishedTo"
            choices={[
              { id: 'WEBSITE', name: 'labels.website' },
              { id: 'APP', name: 'labels.app', disabled: true },
            ]}
            optionText="name"
            optionValue="id"
            label="resources.artifact.fields.publishedTo"
          />
        ) : null
      }
    </WithPermissions>
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['staff', 'director'])(permissions) ? (
          <CheckboxGroupInput
            source="publishedTo"
            choices={[{ id: 'WEBSITE', name: 'labels.website' }]}
            optionText="name"
            optionValue="id"
            label="resources.artifact.fields.publishedTo"
          />
        ) : null
      }
    </WithPermissions>
    <CheckboxGroupInput
      source="publishedLanguages"
      choices={[
        { id: 'vi', name: 'languages.vi' },
        { id: 'en', name: 'languages.en' },
        { id: 'fr', name: 'languages.fr' },
        { id: 'zh', name: 'languages.zh' },
        { id: 'ja', name: 'languages.ja' },
        { id: 'ko', name: 'languages.ko' },
        { id: 'es', name: 'languages.es' },
        { id: 'it', name: 'languages.it' },
        { id: 'de', name: 'languages.de' },
      ]}
    />
    <MultiInput
      baseLang={baseLang}
      inputs={(baseLang, lang, key) => [
        <TextInput
          key={key}
          label="labels.name"
          source={baseLang === lang ? 'name' : `i18n.${lang}.name`}
          validate={baseLang === lang ? [required()] : []}
          fullWidth
        />,
        <ReferenceInput
          key={key}
          source="category"
          reference="category"
          perPage={Infinity}
          allowEmpty
        >
          <SelectInput
            optionText={baseLang === lang ? 'name' : `i18n.${lang}.name`}
          />
        </ReferenceInput>,
        <TextInput
          key={key}
          label="Năm khởi tạo"
          source={baseLang == lang ? 'date' : `i18n.${lang}.date`}
          fullWidth
        />,
      ]}
    />
    <ReferenceArrayInput
      source="authors"
      reference="author"
      perPage={Infinity}
      allowEmpty
    >
      <AutocompleteArrayInput optionText="name" options={{ fullWidth: true }} />
    </ReferenceArrayInput>
    <NumberInput source="code" />
    {edit && <QRCodeField label="qrCode" source="qrCode.uri" title="name" />}
    <DimensionInput />
    <NumberInput source="weight" />
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['admin', 'vinmasStaff'])(permissions) ? (
          <BooleanInput
            label="labels.expert_recommended"
            source="expertRecommended"
          />
        ) : null
      }
    </WithPermissions>
    <ImageInput source="images" multiple accept="image/*">
      <ImageField source="uri" title="title" />
    </ImageInput>
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['admin', 'vinmasStaff'])(permissions) ? (
          <Fragment>
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <ThumbnailInput
                  {...rest}
                  source="thumbnail"
                  images={formData ? formData.images : []} // Sometimes when deleting a record formData will be undefined so this would throw an error without checking formData is truthy.
                />
              )}
            </FormDataConsumer>
            <DroppableImageInput
              source="highQualityImages"
              label="labels.hq_images"
              maxSize={60000000} // 60MB
              multiple
              accept="image/*"
            >
              <DraggableImageField
                source="uri"
                title="title"
                parentSource="highQualityImages"
              />
            </DroppableImageInput>
            <div style={{ padding: 5 }} />
            <MultiInput
              baseLang={baseLang}
              inputs={(baseLang, lang, key) => [
                <FormInput
                  key={key}
                  input={
                    <TextInput
                      label="labels.name"
                      source={baseLang === lang ? 'name' : `i18n.${lang}.name`}
                      validate={baseLang === lang ? [required()] : []}
                      fullWidth
                    />
                  }
                />,
                <FormInput
                  key={key}
                  input={
                    <FileInput
                      label="labels.audio"
                      source={
                        lang === baseLang ? 'audio' : `i18n.${lang}.audio`
                      }
                      accept="audio/*"
                    >
                      <AudioField source="uri" title="title" />
                    </FileInput>
                  }
                />,
                <FormInput
                  key={key}
                  input={
                    <LongTextInput
                      label="labels.transcript"
                      source={
                        lang === baseLang
                          ? 'audioTranscript'
                          : `i18n.${lang}.audioTranscript`
                      }
                    />
                  }
                />,
                <ReferenceArrayInput
                  source="apptags"
                  reference="apptag"
                  perPage={Infinity}
                  allowEmpty
                >
                  <AutocompleteArrayInput
                    optionText="name"
                    options={{ fullWidth: true }}
                  />
                </ReferenceArrayInput>,
              ]}
            />
            <TextInput
              source="youtubeLink"
              options={{ fullWidth: true }}
              validate={[validYouTubeLink]}
            />
          </Fragment>
        ) : null
      }
    </WithPermissions>
    <ReferenceArrayInput
      source="tags"
      reference="tag"
      perPage={Infinity}
      allowEmpty
    >
      <AutocompleteArrayInput optionText="name" options={{ fullWidth: true }} />
    </ReferenceArrayInput>
    <RadioButtonGroupInput
      source="status"
      choices={[
        { id: 'onDisplay', name: 'resources.artifact.statuses.on_display' },
        { id: 'inStorage', name: 'resources.artifact.statuses.in_storage' },
        { id: 'onLoan', name: 'resources.artifact.statuses.on_loan' },
      ]}
    />
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        switch (formData.status) {
          case 'onDisplay':
            return (
              <Fragment>
                <MultiInput
                  baseLang={baseLang}
                  inputs={(baseLang, lang, key) => [
                    <ReferenceInput
                      key={key}
                      {...rest}
                      label="labels.block"
                      source="location.room"
                      reference="block"
                      perPage={Infinity}
                      allowEmpty
                    >
                      <SelectInput
                        optionText={
                          baseLang === lang ? 'name' : `i18n.${lang}.name`
                        }
                      />
                    </ReferenceInput>,
                  ]}
                />
                <div style={{ height: 10 }} />
                <IndoorMapInput source="location" selectedId={formData._id} />
              </Fragment>
            )
          case 'inStorage':
            return (
              <MultiInput
                baseLang={baseLang}
                inputs={(baseLang, lang, key) => [
                  <ReferenceInput
                    key={key}
                    {...rest}
                    label="labels.block"
                    source={lang === baseLang ? 'block' : `i18n.${lang}.block`}
                    reference="block"
                    allowEmpty
                    perPage={Infinity}
                  >
                    <SelectInput
                      optionText={
                        baseLang === lang ? 'name' : `i18n.${lang}.name`
                      }
                    />
                  </ReferenceInput>,
                ]}
              />
            )
          case 'onLoan':
            return (
              <TextInput
                {...rest}
                source="borrowingOrganization"
                label="Tổ chức mượn"
              />
            )
          case 'sold':
            return <TextInput {...rest} source="salePerson" label="Người mua" />
          default:
            return null
        }
      }}
    </FormDataConsumer>
    <div style={{ height: 10 }} />
    <MultiInput
      baseLang={baseLang}
      inputs={(baseLang, lang, key) => [
        <LongTextInput
          key={key}
          label="labels.description"
          source={
            lang === baseLang ? 'description' : `i18n.${lang}.description`
          }
        />,
      ]}
    />
    <ArrayInput
      fullWidth
      label="resources.artifact.fields.referenceLinks"
      source="referenceLinks"
    >
      <SimpleFormIterator>
        <LongTextInput
          label="labels.name"
          source="name"
          validate={required()}
        />
        <LongTextInput
          label="URL"
          source="url"
          validate={[validURL, required()]}
        />
      </SimpleFormIterator>
    </ArrayInput>
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['admin', 'vinmasStaff'])(permissions) ? (
          <BooleanInput
            label="labels.isFreeAccess"
            source="isFreeAccess"
          />
        ) : null
      }
    </WithPermissions>
  </SimpleForm>
)

export const ArtifactEdit = props => (
  <Edit
    {...props}
    title={<ArtifactTitle />}
    actions={<ReviewActions allowPublish={['admin', 'director']} />}
  >
    <WithPermissions>
      {({ permissions, ...props }) =>
        requireOne(['admin', 'vinmasStaff'])(permissions) ? (
          <CommonForm {...props} toolbar={<Toolbar />} edit={true} />
        ) : (
          <TabbedForm {...props} toolbar={<Toolbar />}>
            {langs.map(lang => (
              <CommonFormTab
                label={`languages.${lang}`}
                key={lang}
                lang={lang}
                edit={true}
              />
            ))}
          </TabbedForm>
        )
      }
    </WithPermissions>
  </Edit>
)

export const ArtifactCreate = props => (
  <Create title="titles.create_artifact" {...props}>
    <WithPermissions>
      {({ permissions, ...props }) =>
        requireOne(['admin', 'vinmasStaff'])(permissions) ? (
          <CommonForm {...props} redirect="list" edit={false} />
        ) : (
          <TabbedForm {...props} redirect="list">
            {langs.map(lang => (
              <CommonFormTab
                label={`languages.${lang}`}
                key={lang}
                lang={lang}
                edit={false}
              />
            ))}
          </TabbedForm>
        )
      }
    </WithPermissions>
  </Create>
)
