import React from 'react'
import {
  BulkDeleteWithConfirmButton,
  CheckboxGroupInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  FileInput,
  Filter,
  FormTab,
  ImageField,
  ImageInput,
  List,
  LongTextInput,
  required,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  WithPermissions,
} from 'react-admin'
import {
  BirthdayInput,
  DeathdayInput,
  MultiInput,
  MuseumSelector,
  ReviewActions,
  Toolbar,
  VideoField,
} from '../../components'
import LinkToRelatedArtifacts from './LinkToRelatedArtifacts'
import placeholder from '../../images/placeholder-111x160.png'
import _ from 'lodash'
import { requireOne } from '../../util/permissions'
import StatusBadge from '../../components/StatusBadge'
import AuthorActions from '../../components/ListActions'

export { default as AuthorIcon } from '@material-ui/icons/Person'

const langs = process.env.REACT_APP_SUPPORTED_LANGUAGES.split(',')
const baseLanguage = process.env.REACT_APP_BASE_LANGUAGE

export const AuthorFilter = props => (
  <Filter {...props}>
    <TextInput label="labels.search_artifact" source="name[$search]" alwaysOn />
  </Filter>
)

const CroppedImageField = props => {
  const record = props.record
  record.croppedImage = _.get(record, 'images.0.uri')
    ? record.images[0].uri + '/111x160'
    : placeholder

  const img = <ImageField {...props} record={record} source="croppedImage" />

  return (
    <StatusBadge type="smallImage" record={record}>
      {img}
    </StatusBadge>
  )
}

export const AuthorList = props => (
  <List
    {...props}
    bulkActionButtons={<BulkDeleteWithConfirmButton />}
    filters={<AuthorFilter />}
    actions={<AuthorActions />}
  >
    <Datagrid>
      <CroppedImageField />
      <TextField label="labels.name" source="name" />
      <LinkToRelatedArtifacts />
      <EditButton basePath="/author" />
    </Datagrid>
  </List>
)

const AuthorTitle = ({ record }) => {
  return <span>{record ? `${record.name}` : ''}</span>
}

const CommonFormTab = ({ lang, ...props }) => (
  <FormTab {...props}>
    <MuseumSelector {...props} />
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['admin', 'director'])(permissions) ? (
          <CheckboxGroupInput
            source="publishedTo"
            choices={[
              { id: 'WEBSITE', name: 'labels.website' },
              { id: 'APP', name: 'labels.app', disabled: true },
            ]}
            optionText="name"
            optionValue="id"
            label="resources.artifact.fields.publishedTo"
          />
        ) : null
      }
    </WithPermissions>
    <TextInput
      label="labels.name"
      source={baseLanguage === lang ? 'name' : `i18n.${lang}.name`}
      validate={baseLanguage === lang ? [required()] : []}
    />
    <BirthdayInput />
    <DeathdayInput />
    <ImageInput source="images" multiple accept="image/*">
      <ImageField source="uri" title="title" />
    </ImageInput>
    <FileInput
      label="labels.video"
      source={baseLanguage === lang ? 'video' : `i18n.${lang}.video`}
      accept="video/*"
    >
      <VideoField source="uri" title="title" />
    </FileInput>
    <LongTextInput
      label="labels.description"
      source={
        baseLanguage === lang ? 'description' : `i18n.${lang}.description`
      }
    />
  </FormTab>
)

const CommonForm = ({ lang, ...props }) => (
  <SimpleForm {...props}>
    <MuseumSelector {...props} />
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['admin', 'director'])(permissions) ? (
          <CheckboxGroupInput
            source="publishedTo"
            choices={[
              { id: 'WEBSITE', name: 'labels.website' },
              { id: 'APP', name: 'labels.app', disabled: true },
            ]}
            optionText="name"
            optionValue="id"
            label="resources.artifact.fields.publishedTo"
          />
        ) : null
      }
    </WithPermissions>
    <MultiInput
      baseLang={baseLanguage}
      inputs={(baseLanguage, lang, key) => [
        <TextInput
          key={key}
          label="labels.name"
          source={baseLanguage === lang ? 'name' : `i18n.${lang}.name`}
          validate={baseLanguage === lang ? [required()] : []}
          fullWidth
        />,
      ]}
    />
    <BirthdayInput />
    <DeathdayInput />
    <ImageInput source="images" multiple accept="image/*">
      <ImageField source="uri" title="title" />
    </ImageInput>
    <FileInput
      label="labels.video"
      source={baseLanguage === lang ? 'video' : `i18n.${lang}.video`}
      accept="video/*"
    >
      <VideoField source="uri" title="title" />
    </FileInput>
    <LongTextInput
      label="labels.description"
      source={
        baseLanguage === lang ? 'description' : `i18n.${lang}.description`
      }
    />
  </SimpleForm>
)

export const AuthorEdit = props => (
  <Edit
    {...props}
    title={<AuthorTitle />}
    actions={<ReviewActions allowPublish={['director', 'admin']} />}
  >
    <WithPermissions>
      {({ permissions, ...props }) =>
        requireOne(['admin', 'vinmasStaff'])(permissions) ? (
          <CommonForm {...props} redirect="list" />
        ) : (
          <TabbedForm {...props} toolbar={<Toolbar />}>
            {langs.map(lang => (
              <CommonFormTab
                label={`languages.${lang}`}
                key={lang}
                lang={lang}
              />
            ))}
          </TabbedForm>
        )
      }
    </WithPermissions>
  </Edit>
)

export const AuthorCreate = props => (
  <Create title="titles.create_author" {...props}>
    <WithPermissions>
      {({ permissions, ...props }) =>
        requireOne(['admin', 'vinmasStaff'])(permissions) ? (
          <CommonForm {...props} redirect="list" />
        ) : (
          <TabbedForm {...props} redirect="list">
            {langs.map(lang => (
              <CommonFormTab
                label={`languages.${lang}`}
                key={lang}
                lang={lang}
              />
            ))}
          </TabbedForm>
        )
      }
    </WithPermissions>
  </Create>
)
