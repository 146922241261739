import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import Switch from '@material-ui/core/Switch'
import { Fields } from 'redux-form'
import moment from 'moment-timezone'
import _ from 'lodash'
import FormControllLabel from '@material-ui/core/FormControlLabel'

class YearInput extends Component {
  state = {
    century: null,
    date: null,
    accuracy: 'full',
    dateValues: {
      century: '',
      year: '',
      month: '',
      day: '',
      bc: false,
    },
  }

  static parseDate(date, accuracy, century) {
    if (!date && !century) {
      return { century: '', year: '', month: '', day: '', bc: false }
    } else if (!date && century) {
      return {
        century: Math.abs(Number.parseInt(century, 10)),
        year: '',
        month: '',
        day: '',
        bc: Number.parseInt(century, 10) < 0,
      }
    } else {
      date = moment(date)
    }

    let year = Number.parseInt(date.tz('UTC').format('YYYY'), 10)
    let month = date.tz('UTC').format('MM')
    let day = date.tz('UTC').format('DD')
    let bc = false

    if (year < 0) {
      bc = true
      year = year * -1
    }

    switch (accuracy) {
      case 'noMonth':
        return { year, month: '', day: '', bc }
      case 'noDay':
        return { year, month, day: '' }
      default:
        return { year, month, day, bc }
    }
  }

  componentDidMount() {
    const date = this.props.year.input.value
    const accuracy = this.props.yearAccuracy.input.value
    const century = this.props.century.input.value
    const dateValues = YearInput.parseDate(date, accuracy, century)

    this.setState({ date, accuracy, dateValues })
  }

  calculateDate(newValues) {
    const dateValues = { ...this.state.dateValues, ...newValues }

    // If century defined, clear year
    if (newValues.century) {
      newValues.year = ''
      dateValues.year = ''
    }

    // If year defined, clear century
    if (newValues.year) {
      newValues.century = ''
      dateValues.century = ''
    }

    this.setState({ dateValues })

    const { century, year, month, day, bc } = dateValues

    // If there is no year then the date is essentially null
    if (!year) {
      this.props.year.input.onChange(null)
      this.props.yearAccuracy.input.onChange('full')

      // Make sure century is a negative int iff bc is true
      let c = century
      if ((bc && century > 0) || (!bc && century < 0)) {
        c = century * -1
      }

      this.props.century.input.onChange(c)
      this.setState({ century: c, date: null })
      return
    }

    // Calculate the accuracy of the date based on the values
    let accuracy

    if (_.isEmpty(month) || _.isEmpty(day)) {
      accuracy = _.isEmpty(month) ? 'noMonth' : 'noDay'
    } else {
      accuracy = 'full'
    }

    let YYYYYY = _.padStart(year, 6, '0')
    let MM = month ? _.padStart(month, 2, '0') : '01'
    let DD = day ? _.padStart(day, 2, '0') : '01'

    let date = `${bc ? '-' : '+'}${YYYYYY}-${MM}-${DD}`

    this.setState({ century, date, accuracy })
    this.props.year.input.onChange(date)
    this.props.century.input.onChange(null)
    this.props.yearAccuracy.input.onChange(accuracy)
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'baseline',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
        }}
      >
        <div>
          <TextField
            style={{ width: '7em' }}
            label="Năm khởi tạo"
            type="number"
            value={this.state.dateValues.year}
            onChange={e => this.calculateDate({ year: e.target.value })}
          />
          <TextField
            style={{ width: '8em' }}
            label="Thế kỷ sáng tạo"
            type="number"
            value={this.state.dateValues.century}
            onChange={e => this.calculateDate({ century: e.target.value })}
          />
        </div>
        <div style={{ paddingRight: '1em' }}>
          <FormControllLabel
            control={
              <Switch
                labelPosition="left"
                checked={this.state.dateValues.bc}
                onChange={(_, isInputChecked) =>
                  this.calculateDate({ bc: isInputChecked })
                }
              />
            }
            label="B.C."
          />
        </div>
        <div>
          <FormControllLabel
            control={
              <Switch
                labelPosition="left"
                checked={this.props.yearCirca.input.value}
                onChange={(_, isInputChecked) =>
                  this.props.yearCirca.input.onChange(isInputChecked)
                }
              />
            }
            label="Xấp xỉ năm"
          />
        </div>
      </div>
    )
  }
}

const withFields = () => (
  <Fields
    names={['century', 'year', 'yearAccuracy', 'yearCirca']}
    component={YearInput}
  />
)

export default withFields
