import React from 'react'

import {
  Datagrid,
  DateField,
  Edit,
  EditButton,
  List,
  required,
  SimpleForm,
  TextField,
  TextInput,
  translate,
} from 'react-admin'
import MobileActions from '../../components/ListActions'

const IphoneIcon = require('react-icons/lib/md/phone-iphone')
const AndroidIcon = require('react-icons/lib/md/phone-android')

const PhoneIcon = ({ record }) =>
  record.platform === 'ios' ? <IphoneIcon /> : <AndroidIcon />

export {
  default as MobileAppIcon,
} from '@material-ui/icons/PermDeviceInformation'

export const MobileAppList = props => (
  <List {...props} actions={<MobileActions />} bulkActionButtons={false}>
    <Datagrid>
      <PhoneIcon />
      <TextField source="platform" />
      <TextField source="version" />
      <DateField source="updatedAt" showTime />
      <EditButton basePath="/mobileapp" />
    </Datagrid>
  </List>
)

const MobileAppTitle = ({ record }) => {
  return (
    <span>
      <PhoneIcon record={record} />
      {record ? `${record.platform}` : ''}
    </span>
  )
}

export const MobileAppEdit = translate(props => (
  <Edit title={<MobileAppTitle />} {...props}>
    <SimpleForm>
      <TextInput source="version" validate={required()} />
    </SimpleForm>
  </Edit>
))
