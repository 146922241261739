import React, { Component } from 'react'
import { ImageField as AORImageField } from 'react-admin'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import WarningIcon from '@material-ui/icons/Warning'
import calculateAspectRatio from '../util/nearestNormalAspectRatio'
import get from 'lodash/get'

class ImageField extends Component {
  state = {
    aspectRatio: undefined,
  }

  componentDidMount() {
    const dimensions = get(this.props, 'record.dimensions')
    const rawFile = get(this.props, 'record.rawFile')

    // Image is either a raw file or an existing image.
    if (dimensions) {
      this.setState({
        aspectRatio: calculateAspectRatio(dimensions.width, dimensions.height),
      })
    } else if (rawFile) {
      const image = new Image()
      image.src = rawFile.preview
      image.onload = ({ target: img }) =>
        this.setState({
          aspectRatio: calculateAspectRatio(img.width, img.height),
        })
    }
  }

  componentDidUpdate() {
    const dimensions = get(this.props, 'record.dimensions')
    const rawFile = get(this.props, 'record.rawFile')

    // Image is either a raw file or an existing image.
    if (dimensions) {
      const aspectRatio = calculateAspectRatio(
        dimensions.width,
        dimensions.height
      )

      if (aspectRatio !== this.state.aspectRatio) {
        this.setState({ aspectRatio })
      }
    } else if (rawFile) {
      const image = new Image()
      image.src = rawFile.preview
      image.onload = ({ target: img }) => {
        const aspectRatio = calculateAspectRatio(img.width, img.height)

        if (aspectRatio !== this.state.aspectRatio) {
          this.setState({ aspectRatio })
        }
      }
    }
  }

  render() {
    const props = this.props
    const aspectRatio = this.state.aspectRatio

    let aspectRatioWarning

    if (props.aspectRatio && aspectRatio && aspectRatio !== props.aspectRatio) {
      aspectRatioWarning = `Để hiển thị đầy đủ hình ảnh trên web, Vui lòng upload hình ảnh với tỷ lệ ${props.aspectRatio}`
    }

    return (
      <div>
        <AORImageField {...props} />
        {aspectRatioWarning && (
          <div
            style={{
              position: 'absolute',
              top: 0,
            }}
          >
            <Tooltip title={aspectRatioWarning}>
              <Button
                variant="fab"
                mini
                style={{ backgroundColor: 'white', color: 'orange' }}
              >
                <WarningIcon />
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
    )
  }
}

export default ImageField
