import React from 'react'
import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  List,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin'
import {
  MuseumSelector,
  ReviewActions,
  TextWithIndicator,
} from '../../components'
import BeaconActions from '../../components/ListActions'
import FANewspaper from 'react-icons/lib/fa/bluetooth'

export const BeaconIcon = props => <FANewspaper size={24} {...props} />

const BeaconTitle = ({ record }) => {
  return <span>{record ? `${record.name}` : ''}</span>
}

export const BeaconList = props => (
  <List {...props} actions={<BeaconActions />}>
    <Datagrid>
      <TextWithIndicator source="uuid" />
      <TextField source="major" />
      <TextField source="minor" />
      {/* TODO: show associated room */}
      <EditButton basePath="/beacon" />
      <DeleteButton basePath="/beacon" />
    </Datagrid>
  </List>
)

const CommonFormTab = ({ ...props }) => (
  <SimpleForm {...props}>
    <MuseumSelector {...props} />
    <TextInput source="uuid" fullWidth />
    <NumberInput source="major" />
    <NumberInput source="minor" />
    <ReferenceInput
      label="titles.choose_block"
      source="room"
      reference="block"
      allowEmpty
      perPage={Infinity}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <SelectInput
      source="proximity"
      choices={[
        { id: 'unknown', name: 'unknown' },
        { id: 'near', name: 'near' },
        { id: 'far', name: 'far' },
        { id: 'immediate', name: 'immediate' },
      ]}
    />
  </SimpleForm>
)

export const BeaconEdit = props => (
  <Edit
    {...props}
    title={<BeaconTitle />}
    actions={<ReviewActions allowPublish={['admin', 'director']} />}
  >
    <CommonFormTab redirect="list" />
  </Edit>
)

export const BeaconCreate = props => (
  <Create title="titles.create_beacon" {...props}>
    <CommonFormTab redirect="list" />
  </Create>
)
