import React from 'react'
import compose from 'recompose/compose'
import PlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import { addField, FieldTitle } from 'react-admin'
import get from 'lodash/get'
import { MapField } from './'

const styles = theme => ({
  container: {
    marginTop: 16,
    marginBottom: 8,
  },
  suggestionContainer: {
    position: 'absolute',
    marginBottom: theme.spacing.unit * 3,
    zIndex: 2,
  },
})

class PlaceInput extends React.Component {
  handleSelect = async (address, place_id) => {
    const places = await geocodeByPlaceId(place_id)
    const latLng = await getLatLng(places[0])

    this.props.input.onChange({ address, place_id, ...latLng })
  }

  render() {
    const {
      meta,
      input,
      label,
      source,
      classes,
      resource,
      isRequired,
    } = this.props

    const { touched, error, helperText = false } = meta

    const address = get(input, 'value.address', '')

    return (
      <PlacesAutocomplete
        value={address}
        onChange={address => input.onChange({ ...input.value, address })}
        onSelect={this.handleSelect}
        // Limit search results to Vietnam.
        searchOptions={{ componentRestrictions: { country: 'vn' } }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div className={classes.container}>
            <TextField
              label={
                <FieldTitle
                  label={label}
                  source={source}
                  resource={resource}
                  isRequired={isRequired}
                />
              }
              value={address}
              margin="normal"
              fullWidth
              error={!!(touched && error)}
              helperText={(touched && error) || helperText}
              name={input.name}
              InputProps={getInputProps()}
            />
            <Paper className={classes.suggestionContainer} square>
              {suggestions.map(suggestion => (
                <MenuItem
                  key={suggestion.id}
                  selected={suggestion.active}
                  {...getSuggestionItemProps(suggestion)}
                >
                  <div>{suggestion.description}</div>
                </MenuItem>
              ))}
            </Paper>
            {input.value.lng && input.value.lat && (
              <div style={{ display: 'flex' }}>
                <MapField
                  style={{ maxWidth: 400, maxHeight: 400 }}
                  record={{ location: input.value }}
                />
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    )
  }
}

const enhance = compose(
  addField,
  withStyles(styles)
)

export default enhance(PlaceInput)
