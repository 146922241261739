import React from 'react'
import {
  BulkDeleteWithConfirmButton,
  CheckboxGroupInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  FormTab,
  ImageField,
  ImageInput,
  List,
  LongTextInput,
  required,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  WithPermissions,
} from 'react-admin'
import {
  MultiInput,
  MuseumSelector,
  ReviewActions,
  Toolbar,
} from '../../components'
import LinkToRelatedArtifacts from './LinkToRelatedArtifacts'
import { requireOne } from '../../util/permissions'
import StatusBadge from '../../components/StatusBadge'
import CategoryActions from '../../components/ListActions'

const langs = process.env.REACT_APP_SUPPORTED_LANGUAGES.split(',')
const baseLanguage = process.env.REACT_APP_BASE_LANGUAGE

export { default as CategoryIcon } from '@material-ui/icons/Label'

export const CategoryList = props => (
  <List
    {...props}
    bulkActionButtons={<BulkDeleteWithConfirmButton />}
    actions={<CategoryActions />}
  >
    <Datagrid>
      <StatusBadge type="text">
        <TextField source="name" />
      </StatusBadge>
      <LinkToRelatedArtifacts />
      <EditButton basePath="/category" />
    </Datagrid>
  </List>
)

const CategoryTitle = ({ record }) => {
  return <span>{record ? `${record.name}` : ''}</span>
}

const CommonFormTab = ({ lang, ...props }) => (
  <FormTab {...props}>
    <MuseumSelector {...props} />
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['admin', 'director'])(permissions) ? (
          <CheckboxGroupInput
            source="publishedTo"
            choices={[
              { id: 'WEBSITE', name: 'labels.website' },
              { id: 'APP', name: 'labels.app', disabled: true },
            ]}
            optionText="name"
            optionValue="id"
            label="resources.artifact.fields.publishedTo"
          />
        ) : null
      }
    </WithPermissions>
    <TextInput
      label="labels.name"
      source={baseLanguage === lang ? 'name' : `i18n.${lang}.name`}
      validate={baseLanguage === lang ? [required()] : []}
      fullWidth
    />
    <ImageInput source="images" multiple accept="image/*">
      <ImageField source="uri" title="title" />
    </ImageInput>
    <LongTextInput
      label="labels.description"
      source={
        baseLanguage === lang ? 'description' : `i18n.${lang}.description`
      }
    />
  </FormTab>
)

const CommonForm = props => (
  <SimpleForm {...props}>
    <MuseumSelector {...props} />
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['admin', 'director'])(permissions) ? (
          <CheckboxGroupInput
            source="publishedTo"
            choices={[
              { id: 'WEBSITE', name: 'labels.website' },
              { id: 'APP', name: 'labels.app', disabled: true },
            ]}
            optionText="name"
            optionValue="id"
            label="resources.artifact.fields.publishedTo"
          />
        ) : null
      }
    </WithPermissions>
    <MultiInput
      baseLang={baseLanguage}
      inputs={(baseLang, lang, key) => [
        <TextInput
          key={key}
          label="labels.name"
          source={baseLanguage === lang ? 'name' : `i18n.${lang}.name`}
          validate={baseLanguage === lang ? [required()] : []}
          fullWidth
        />,
        <LongTextInput
          key={key}
          label="labels.description"
          source={
            baseLanguage === lang ? 'description' : `i18n.${lang}.description`
          }
        />,
      ]}
    />
    <ImageInput source="images" multiple accept="image/*">
      <ImageField source="uri" title="title" />
    </ImageInput>
  </SimpleForm>
)

export const CategoryEdit = props => (
  <Edit
    {...props}
    title={<CategoryTitle />}
    actions={<ReviewActions allowPublish={['admin', 'director']} />}
  >
    <WithPermissions>
      {({ permissions, ...props }) =>
        requireOne(['admin', 'vinmasStaff'])(permissions) ? (
          <CommonForm {...props} toolbar={<Toolbar />} />
        ) : (
          <TabbedForm {...props} toolbar={<Toolbar />}>
            {langs.map(lang => (
              <CommonFormTab
                label={`languages.${lang}`}
                key={lang}
                lang={lang}
              />
            ))}
          </TabbedForm>
        )
      }
    </WithPermissions>
  </Edit>
)

export const CategoryCreate = props => (
  <Create title="titles.create_category" {...props}>
    <WithPermissions>
      {({ permissions, ...props }) =>
        requireOne(['admin', 'vinmasStaff'])(permissions) ? (
          <CommonForm {...props} toolbar={<Toolbar />} />
        ) : (
          <TabbedForm {...props} toolbar={<Toolbar />}>
            {langs.map(lang => (
              <CommonFormTab
                label={`languages.${lang}`}
                key={lang}
                lang={lang}
              />
            ))}
          </TabbedForm>
        )
      }
    </WithPermissions>
  </Create>
)
