// Removes diacritics from a string. Useful for transforming text for search and suggestion matching cases.
export default str => {
  if (typeof str === 'string') {
    // See https://stackoverflow.com/a/37511463/9787649
    str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

    // Add special handling for Đ and đ.
    str = str.replace(/\u0110/g, 'D').replace(/\u0111/g, 'd')
  }

  return str
}
