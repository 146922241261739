import React from 'react'
import {
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  downloadCSV,
  Filter,
  FunctionField,
  List,
  maxValue,
  minValue,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  required,
  SelectField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  translate,
  WithPermissions,
} from 'react-admin'
import { unparse as convertToCSV } from 'papaparse/papaparse.min'
import { requireOne } from '../../util/permissions'
import { QRCodeDialogField } from '../../components'
import FATicket from 'react-icons/lib/fa/ticket'
import FAAndroid from 'react-icons/lib/fa/android'
import FAApple from 'react-icons/lib/fa/apple'
import FAWrench from 'react-icons/lib/fa/wrench'
import VoucherActions from '../../components/VoucherActions'

const exporter = vouchers => {
  let error = false
  const vouchersForExport = vouchers.map(voucher => {
    if (!voucher || !voucher.qrCode || !voucher.qrCode.uri) {
      console.error('Voucher does not have qrCode.uri', JSON.stringify(voucher))
      if (voucher.purchaseMethod != 'TEST') {
        error = true
      }
    } else {
      voucher.qrCodeUrl = voucher.qrCode.uri
    }
    return voucher
  })
  const csv = convertToCSV({
    data: vouchersForExport,
    fields: [
      'id',
      'used',
      'usedAt',
      'museum',
      'purchasePrice',
      'code',
      'duration',
      'purchaseMethod',
      'createdAt',
      'qrCodeUrl',
    ],
  })
  if (error) {
    return alert(
      'Some vouchers are missing qrCode.uri field. See console for details.'
    )
  }
  downloadCSV(csv, 'vouchers')
}

const PurchasePriceField = props => (
  <FunctionField
    {...props}
    render={record => {
      if (record.purchaseMethod === 'CASH' && record.purchasePrice > 0) {
        return record.purchasePrice
      } else {
        return ''
      }
    }}
  />
)

export const VoucherIcon = props => <FATicket size={24} {...props} />

export const VoucherFilter = props => (
  <Filter {...props}>
    <TextInput
      label="resources.voucher.search"
      source="code[$search]"
      alwaysOn
    />
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['admin'])(permissions) ? (
          <ReferenceInput
            source="museum"
            reference="museum"
            perPage={Infinity}
            alwaysOn
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        ) : null
      }
    </WithPermissions>
    <BooleanInput source="used" />
  </Filter>
)

export const VoucherList = ({ permissions, ...props }) => (
  <List
    {...props}
    filters={<VoucherFilter />}
    actions={<VoucherActions />}
    bulkActionButtons={false}
    exporter={exporter}
  >
    {requireOne(['admin'])(permissions) ? (
      <Datagrid>
        <QRCodeDialogField label="Xem QR" />
        <TextField
          label="Mã"
          source="code"
          style={{ fontFamily: 'monospace' }}
        />
        <TextField source="duration" />
        <DateField locales="vi-VN" source="createdAt" showTime />
        <ReferenceField
          source="museum"
          reference="museum"
          perPage={Infinity}
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField locales="vi-VN" source="usedAt" showTime />
        <PurchasePriceField label="Giá mua" />
        <SelectField
          source="purchaseMethod"
          choices={[
            {
              id: 'CASH',
              name: (
                <div>
                  <FATicket color="#a50021" size={16} /> Voucher
                </div>
              ),
            },
            {
              id: 'IAP_ANDROID',
              name: (
                <div>
                  <FAAndroid color="#3ddc84" size={16} /> Android IAP
                </div>
              ),
            },
            {
              id: 'IAP_APPLE',
              name: (
                <div>
                  <FAApple color="#198cf0" size={16} /> Apple IAP
                </div>
              ),
            },
            {
              id: 'TEST',
              name: (
                <div>
                  <FAWrench /> Bảo trì
                </div>
              ),
            },
          ]}
        />
      </Datagrid>
    ) : requireOne(['staff', 'director', 'vinmasStaff'])(permissions) ? (
      <Datagrid>
        <QRCodeDialogField label="Xem QR" />
        <TextField
          label="Mã"
          source="code"
          style={{ fontFamily: 'monospace' }}
        />
        <TextField source="duration" />
        <DateField locales="vi-VN" source="createdAt" showTime />
        <DateField locales="vi-VN" source="usedAt" showTime />
        <PurchasePriceField label="Giá mua" />
        <SelectField
          source="purchaseMethod"
          choices={[
            {
              id: 'CASH',
              name: (
                <div>
                  <FATicket /> Voucher
                </div>
              ),
            },
            {
              id: 'IAP_ANDROID',
              name: (
                <div>
                  <FAAndroid /> Android IAP
                </div>
              ),
            },
            {
              id: 'IAP_APPLE',
              name: (
                <div>
                  <FAApple /> Apple IAP
                </div>
              ),
            },
            {
              id: 'TEST',
              name: (
                <div>
                  <FAWrench /> Bảo trì
                </div>
              ),
            },
          ]}
        />
      </Datagrid>
    ) : null}
  </List>
)

export const VoucherCreate = translate(props => (
  <Create title="titles.create_vouchers" {...props}>
    <SimpleForm redirect="list">
      <NumberInput
        source="count"
        step={1}
        validate={[required(), minValue(0), maxValue(50)]}
      />
      <NumberInput source="purchasePrice" label="Giá mua" />
      <SelectInput
        source="duration"
        choices={[
          { id: '1h', name: `1 ${props.translate('labels.hour')}` },
          { id: '2h', name: `2 ${props.translate('labels.hour')}` },
          { id: '3h', name: `3 ${props.translate('labels.hour')}` },
          { id: '8h', name: `8 ${props.translate('labels.hour')}` },
          { id: '24h', name: `24 ${props.translate('labels.hour')}` },
          { id: '72h', name: `72 ${props.translate('labels.hour')}` },
        ]}
        validate={[required()]}
      />
      <BooleanInput source="forTesting" label="Để bảo trì" />
      <ReferenceInput
        source="museum"
        reference="museum"
        perPage={Infinity}
        allowEmpty
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
))
