import React from 'react'

import Downshift from 'downshift'
import _ from 'lodash'
import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import RootRef from '@material-ui/core/RootRef'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import removeDiacritics from '../util/removeDiacritics'

const styles = theme => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    '&::after': {
      left: 0,
      right: 0,
      bottom: 0,
      content: '',
      position: 'absolute',
      transform: 'scaleX(0)',
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      borderBottom: '2px solid rgb(238, 123, 76) !important',
      pointerEvents: 'none',
    },
  },
  paper: {
    boxShadow: 'none',
    border: 'none',
    marginTop: 20,
  },
  chip: {
    margin: '0px 8px 8px 0',
  },
  menuList: {
    position: 'absolute',
    width: '100%',
    zIndex: 99999,
  },
  input: {
    marginTop: '0px !important',
  },
})

class AutocompleteArrayInput extends React.Component {
  focus = () => {
    this.textInput.focus()
  }

  handleSelect = selection => {
    this.props.input.onChange(
      _.concat(
        this.props.input.value ? this.props.input.value : [],
        selection.id
      )
    )

    this.textInput.value = ''
    this.textInput.blur()
  }

  setActualInputRef = ref => {
    this.textInput = ref
  }

  handleDelete = data => {
    this.props.input.onChange(
      this.props.input.value.filter(item => !(item === data.id))
    )
  }

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  onDragEnd = ({ destination, source, draggableId }) => {
    if (!destination || destination.index === source.index) return

    const newInput = Array.from(this.props.input.value)
    newInput.splice(source.index, 1)
    newInput.splice(destination.index, 0, draggableId)

    this.props.input.onChange(newInput)
  }

  render() {
    const { classes, label, input, choices } = this.props

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Downshift
          onSelect={this.handleSelect}
          itemToString={item => (item ? item.value : '')}
        >
          {({
            getInputProps,
            getItemProps,
            isOpen,
            inputValue,
            openMenu,
            reset,
          }) => (
            <div className={classes.root}>
              <FormControl margin="none">
                <InputLabel
                  htmlFor="autocomplete-input"
                  shrink={
                    (this.textInput && this.textInput.value) ||
                    input.value.length > 0
                  }
                >
                  {label}
                </InputLabel>
                <Droppable droppableId="chips" direction="horizontal">
                  {provided => (
                    <RootRef rootRef={provided.innerRef}>
                      <Paper
                        className={classes.paper}
                        onClick={this.focus}
                        {...provided.droppableProps}
                      >
                        {(input.value ? input.value : []).map((id, index) => {
                          const data = _.find(choices, o => {
                            return o.id === id
                          })

                          return (
                            data && (
                              <Draggable
                                draggableId={data.id}
                                index={index}
                                key={id}
                              >
                                {provided => (
                                  <RootRef rootRef={provided.innerRef}>
                                    <Chip
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      key={data.id}
                                      label={data.name}
                                      onDelete={() => this.handleDelete(data)}
                                      className={classes.chip}
                                    />
                                  </RootRef>
                                )}
                              </Draggable>
                            )
                          )
                        })}
                        {provided.placeholder}
                        <Input
                          classes={{ input: classes.input }}
                          id="autocomplete-input"
                          disableUnderline={true}
                          inputRef={this.setActualInputRef}
                          onFocus={openMenu}
                          inputProps={getInputProps({
                            onBlur: () => {
                              this.textInput.value = ''
                              reset()
                            },
                          })}
                          onKeyPress={this.handleKeyPress}
                        />
                      </Paper>
                    </RootRef>
                  )}
                </Droppable>
              </FormControl>
              {isOpen && (
                <Paper className={classes.menuList}>
                  {choices
                    .filter(
                      item =>
                        (!inputValue ||
                          removeDiacritics(item.name.toLowerCase()).includes(
                            removeDiacritics(inputValue).toLowerCase()
                          )) &&
                        !input.value.includes(item.id)
                    )
                    .map((item, index) => (
                      <MenuItem
                        key={index}
                        {...getItemProps({
                          key: item.value,
                          index,
                          item,
                        })}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                </Paper>
              )}
            </div>
          )}
        </Downshift>
      </DragDropContext>
    )
  }
}

export default withStyles(styles)(AutocompleteArrayInput)
