import React, { Fragment } from 'react'
import ReactMapboxGl, { ZoomControl } from 'react-mapbox-gl'
import { addField } from 'react-admin'
import center from '@turf/center'
import { FloorLayer, WallLayer } from '../components/MapLayers'
import FileReaderInput from 'react-file-reader-input'
import Button from '@material-ui/core/Button'

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  minZoom: 17.5,
  maxZoom: 21,
})

const IndoorFloorMapInput = ({ input }) => {
  return (
    <Fragment>
      {input.value && (
        <Map
          style="mapbox://styles/lihop/cjm75gm470dc32rmpfw2bd8x2" // eslint-disable-line
          containerStyle={{
            height: 500,
            width: 500,
            maxWidth: '100%',
          }}
          center={center(input.value).geometry.coordinates}
          zoom={[17.5]}
          bearing={[14.5]}
        >
          <ZoomControl />
          <FloorLayer data={input.value} />
          <WallLayer data={input.value} />
        </Map>
      )}
      <FileReaderInput
        as="binary"
        id="geojson-file-input"
        onChange={(_, [[e]]) => {
          try {
            const value = JSON.parse(e.target.result.toString('utf8'))

            // Check value is geojson
            center(value)

            input.onChange(value)
          } catch (e) {
            input.onChange(null)
          }
        }}
      >
        <Button variant="outlined">Upload GeoJSON</Button>
      </FileReaderInput>
    </Fragment>
  )
}

export default addField(IndoorFloorMapInput)
