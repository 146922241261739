import React from 'react'
import {
  BulkDeleteWithConfirmButton,
  CheckboxGroupInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  FormTab,
  ImageField,
  ImageInput,
  List,
  LongTextInput,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  WithPermissions,
} from 'react-admin'
import _ from 'lodash'
import placeholder from '../../images/placeholder.png'
import { MultiInput, PreviewToolbar, ReviewActions } from '../../components'
import { requireOne } from '../../util/permissions'
import StatusBadge from '../../components/StatusBadge'
import AutocompleteArrayInput from '../../components/AutocompleteArrayInput'
import DisplayActions from '../../components/ListActions'

export { default as DisplayIcon } from '@material-ui/icons/Collections'

const langs = process.env.REACT_APP_SUPPORTED_LANGUAGES.split(',')
const baseLang = process.env.REACT_APP_BASE_LANGUAGE

const CroppedImageField = props => {
  const record = props.record
  record.croppedImage = _.get(record, 'images.0.uri')
    ? record.images[0].uri
    : placeholder

  const img = <ImageField {...props} record={record} source="croppedImage" />

  return (
    <StatusBadge type="smallImage" record={record}>
      {img}
    </StatusBadge>
  )
}

export const DisplayList = props => (
  <List
    {...props}
    bulkActionButtons={<BulkDeleteWithConfirmButton />}
    actions={<DisplayActions />}
  >
    <Datagrid>
      <CroppedImageField />
      <TextField source="name" />
      <EditButton basePath="/display" />
    </Datagrid>
  </List>
)

const DisplayTitle = ({ record }) => {
  return <span>{_.get(record, 'name', '')}</span>
}

const CommonFormTab = ({ lang, ...props }) => (
  <FormTab {...props}>
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['admin'])(permissions) ? (
          <ReferenceInput
            {...props}
            label="resources.museum.name"
            source="museum"
            reference="museum"
            validate={[required()]}
            perPage={Infinity}
            allowEmpty
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        ) : null
      }
    </WithPermissions>
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['admin', 'director'])(permissions) ? (
          <CheckboxGroupInput
            source="publishedTo"
            choices={[
              { id: 'WEBSITE', name: 'labels.website' },
              { id: 'APP', name: 'labels.app' },
            ]}
            optionText="name"
            optionValue="id"
            label="resources.artifact.fields.publishedTo"
          />
        ) : null
      }
    </WithPermissions>
    <CheckboxGroupInput
      source="publishedLanguages"
      choices={[
        { id: 'vi', name: 'languages.vi' },
        { id: 'en', name: 'languages.en' },
        { id: 'fr', name: 'languages.fr' },
      ]}
    />
    <NumberInput source="ranking" />
    <TextInput
      label="labels.name"
      source={lang === baseLang ? 'name' : `i18n.${lang}.name`}
      validate={lang === baseLang ? [required()] : []}
      fullWidth
    />
    <ImageInput source="images" multiple accept="image/*">
      <ImageField source="uri" title="title" />
    </ImageInput>
    <ReferenceArrayInput
      source="artifacts"
      reference="artifact"
      perPage={Infinity}
      allowEmpty
    >
      <AutocompleteArrayInput optionText="name" options={{ fullWidth: true }} />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      source="blocks"
      reference="block"
      perPage={Infinity}
      allowEmpty
    >
      <AutocompleteArrayInput optionText="name" options={{ fullWidth: true }} />
    </ReferenceArrayInput>
    <LongTextInput
      label="labels.description"
      source={lang === baseLang ? 'description' : `i18n.${lang}.description`}
    />
  </FormTab>
)

export const DisplayCreate = props => (
  <Create title="titles.create_display" {...props}>
    <TabbedForm redirect="list">
      {langs.map(lang => (
        <CommonFormTab label={`languages.${lang}`} key={lang} lang={lang} />
      ))}
    </TabbedForm>
  </Create>
)

export const DisplayEdit = props => (
  <Edit
    {...props}
    title={<DisplayTitle />}
    actions={<ReviewActions allowPublish={['admin', 'director']} />}
    undoable={false} // Don't allow undo so that change takes effect for immediate preview.
  >
    <TabbedForm toolbar={<PreviewToolbar />}>
      {langs.map(lang => (
        <CommonFormTab label={`languages.${lang}`} key={lang} lang={lang} />
      ))}
    </TabbedForm>
  </Edit>
)
