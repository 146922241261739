import React from 'react'
import styled from 'styled-components'
import { addField } from 'react-admin'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/FormLabel'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Crop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { imgSrcToBlob } from 'blob-util'
import _ from 'lodash'

const ThumbnailImage = styled.div`
  width: 250px;
  height: 250px;
  margin: 0.5rem;
  background-size:contain;
  background-position:center;
  background-repeat:no-repeat;
  background-image: url('${props => props.src}');
`

class ThumbnailInput extends React.Component {
  state = {
    dialogOpen: false,
    thumbnailUrl: _.get(this, 'props.input.value.url'),
    crop: this.props.input.value || { aspect: 1 / 1, x: 10, y: 10, width: 50 },
  }

  async componentDidMount() {
    await this.updateThumbnailPreview()
  }

  async componentDidUpdate(prevProps) {
    const newImage =
      _.get(this.props, 'images.0.uri') ||
      _.get(this.props, 'images.0.rawFile.uri')
    const oldImage =
      _.get(prevProps, 'images.0.uri') ||
      _.get(prevProps, 'images.0.rawFile.uri')

    if (!_.isEqual(newImage, oldImage)) {
      await this.updateThumbnailPreview()
    }
  }

  updateThumbnailPreview = async () => {
    const savedFile = _.get(this.props, 'images.0.uri')
    const rawFile = _.get(this.props, 'images.0.rawFile.uri')

    const imageUrl = savedFile || rawFile

    // Only show the thumbnail if loading a saved file.
    if (!savedFile) {
      this.setState({ thumbnailUrl: null })
    }

    this.setState({ imageUrl })

    const blob = await imgSrcToBlob(imageUrl, '', '*')
    this.setState({ blob: window.URL.createObjectURL(blob) })
  }

  getCroppedImg(image, pixelCrop, fileName) {
    const canvas = document.createElement('canvas')
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    )

    return new Promise(resolve => {
      canvas.toBlob(blob => {
        blob.name = fileName
        window.URL.revokeObjectURL(this.fileUrl)
        this.fileUrl = window.URL.createObjectURL(blob)
        resolve(this.fileUrl)
      }, 'image/jpeg')
    })
  }

  updateThumbnail = async (crop, pixelCrop) => {
    const { input } = this.props

    this.setState({ crop })

    const url = `${this.state.imageUrl}/cx${pixelCrop.x},cy${pixelCrop.y},cw${pixelCrop.width},ch${pixelCrop.height}`

    input.onChange({ ...input.value, ...crop, ...{ aspect: 1, url } })

    if (crop.width && crop.height) {
      const thumbnailUrl = await this.getCroppedImg(
        this.imageRef,
        pixelCrop,
        'newThumbnail.jpeg'
      )
      this.setState({ thumbnailUrl })
    }
  }

  render() {
    const { input, images } = this.props
    const { thumbnailUrl, dialogOpen, blob } = this.state

    // If we don't have any images, we can't select a thumbnail
    if (_.isEmpty(images)) {
      input.onChange(null)
      return null
    }

    return (
      <div>
        <InputLabel>Hình nhỏ</InputLabel>
        {thumbnailUrl && <ThumbnailImage src={thumbnailUrl} />}
        <Button
          style={{ display: 'block', opacity: 1, position: 'relative' }}
          variant="outlined"
          onClick={() => this.setState({ dialogOpen: true })}
        >
          chỉnh sửa hình thu nhỏ
        </Button>
        <Dialog
          maxWidth="md"
          open={dialogOpen}
          onClose={() => this.setState({ dialogOpen: false })}
        >
          <Crop
            src={blob ? blob : ''}
            crop={{ ...this.state.crop, ...{ aspect: 1 / 1 } }}
            onImageLoaded={image => (this.imageRef = image)}
            onChange={crop => this.setState({ crop })}
            onComplete={this.updateThumbnail}
          />
          <DialogActions>
            <Button
              onClick={() => this.setState({ dialogOpen: false })}
              color="primary"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default addField(ThumbnailInput)
