import { UPDATE } from 'react-admin'

export const PUBLISH_RECORD = 'PUBLISH_RECORD'
export const PUBLISH_RECORD_LOADING = 'PUBLISH_RECORD_LOADING'
export const PUBLISH_RECORD_FAILURE = 'PUBLISH_RECORD_FAILURE'
export const PUBLISH_RECORD_SUCCESS = 'PUBLISH_RECORD_SUCCESS'

const getPublishedData = data => {
  if (!data.publishedLanguages) return

  const newData = {}
  const langs = ['vi', 'en', 'fr', 'zh', 'ja', 'ko', 'es', 'it', 'de']

  langs.forEach(lang => {
    newData[`${lang}Published`] = data.publishedLanguages.includes(lang)
  })

  return newData
}

export const publishRecord = (id, data, resource, basePath) => {
  return {
    type: PUBLISH_RECORD,
    payload: {
      resource,
      id,
      data: {
        ...data,
        published: true,
        publishedVersion: data.__v,
        ...getPublishedData(data),
      },
      previousData: data,
      basePath,
    },
    meta: { resource, fetch: UPDATE, cancelPrevious: false },
  }
}

export const UNPUBLISH_RECORD = 'UNPUBLISH_RECORD'
export const UNPUBLISH_RECORD_LOADING = 'UNPUBLISH_RECORD_LOADING'
export const UNPUBLISH_RECORD_FAILURE = 'UNPUBLISH_RECORD_FAILURE'
export const UNPUBLISH_RECORD_SUCCESS = 'UNPUBLISH_RECORD_SUCCESS'

export const unpublishRecord = (id, data, resource, basePath) => {
  return {
    type: UNPUBLISH_RECORD,
    payload: {
      resource,
      id,
      data: { ...data, published: false, ...getPublishedData(data) },
      previousData: data,
      basePath,
    },
    meta: { resource, fetch: UPDATE, cancelPrevious: false },
  }
}
