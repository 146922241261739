import setMuseumId from '../../hooks/setMuseumId'

export default {
  before: {
    all: [],
    find: [],
    get: [],
    create: [setMuseumId()],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
}
