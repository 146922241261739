import React, { Component } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash.get'
import Button from '@material-ui/core/Button'
import PrintIcon from '@material-ui/icons/Print'
import { withStyles } from '@material-ui/core/styles'
import FileDownloadIcon from '@material-ui/icons/FileDownload'
import axios from 'axios'

const styles = {
  list: {
    display: 'flex',
    listStyleType: 'none',
  },
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
  iconLeft: {
    fontSize: 17,
    marginRight: 9,
  },
}

const printImage = (url, title) => {
  const win = window.open('')
  win.document.write('<title>' + title + '</title>')
  win.document.write(
    '<img src="' + url + '" onload="window.print();window.close()" />'
  )
  win.focus()
}

const PrintButton = withStyles(styles)(({ classes, href, title }) => (
  <Button onClick={() => printImage(href, title)}>
    <PrintIcon className={classes.iconLeft} />
    Print
  </Button>
))

const SaveButton = withStyles(styles)(
  class QRCodeField extends Component {
    state = {}

    async getBlobUrl(href) {
      const response = await axios.get(href, { responseType: 'blob' })

      return await URL.createObjectURL(response.data)
    }

    async componentDidMount() {
      const blobUrl = await this.getBlobUrl(this.props.href)

      this.setState({ blobUrl })
    }

    render() {
      const { classes } = this.props

      return (
        <Button download={this.props.title} href={this.state.blobUrl}>
          <FileDownloadIcon className={classes.iconLeft} />
          SAVE
        </Button>
      )
    }
  }
)

export const QRCodeField = ({ elStyle = {}, record, source, title = '' }) => {
  const sourceValue = get(record, source)
  if (!sourceValue) {
    return <div />
  }

  const titleValue =
    (get(record, title) || title).toLowerCase().replace(/ /g, '_') +
    '_qrcode.png'

  return (
    <div
      style={{
        ...elStyle,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <img
        crossOrigin="anonymous"
        title={titleValue}
        alt={titleValue}
        src={sourceValue}
        style={styles.image}
      />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <PrintButton href={sourceValue} title={titleValue} />
        <SaveButton href={sourceValue} title={titleValue} />
      </div>
    </div>
  )
}

QRCodeField.propTypes = {
  elStyle: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  title: PropTypes.string,
}

export default QRCodeField
