import React, { useState } from 'react'
import {
  GET_LIST,
  ArrayField,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  RichTextField,
  SelectInput,
  Show,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin'
import { Card, CardHeader, CardContent, Chip, Grid } from '@material-ui/core'
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'
import { Key, renderActiveShape } from '../../components/ReportPage'
import _ from 'lodash'
import { useEffect } from 'react'
import { restClient } from '../../feathersClient'
import XLSX from 'xlsx'

const TAG_MAP = {
  PRICE: { id: 'PRICE', name: 'price', color: '#5abaf2' },
  APP_EXPERIENCE: {
    id: 'APP_EXPERIENCE',
    name: 'app experience',
    color: '#4b9406',
  },
  AUDIO_GUIDE_CONTENT: {
    id: 'AUDIO_GUIDE_CONTENT',
    name: 'audio guide content',
    color: '#f2f542',
  },
  MAP: { id: 'MAP', name: 'map', color: '#f2816b' },
  LIKE: { id: 'LIKE', name: 'like', color: '#00ff00' },
  OTHER: { id: 'OTHER', name: 'other', color: '#cccfca' },
}

export { default as FeedbackIcon } from '@material-ui/icons/RateReview'

const TagsField = ({ data }) => {
  const tags = Object.values(data)

  return tags
    .filter(tag => tag !== 'LIKE')
    .map(tag => {
      const { name: label, color } = TAG_MAP[tag]

      return <Chip key={tag} style={{ background: color }} label={label} />
    })
}

const FeedbackFilter = props => (
  <Filter {...props}>
    <TextInput label="Search comments" source="comment[$search]" alwaysOn />
    <SelectInput source="categories" choices={Object.values(TAG_MAP)} />
  </Filter>
)

const FeedbackPie = () => {
  const COLORS = _.map(TAG_MAP, tag => tag.color)

  const [pieData, setPieData] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)

  async function getPieData() {
    const { data } = await restClient(
      GET_LIST,
      `feedback?$limit=null&$select=categories`,
      {}
    )

    const result = []

    _.map(TAG_MAP, tag => {
      const sliceData = { name: tag.name, value: 0, color: tag.color }

      data.forEach(item => {
        if (item.categories && item.categories.includes(tag.id)) {
          sliceData.value++
        }
      })

      result.push(sliceData)
    })

    setPieData(result)
  }

  useEffect(() => {
    getPieData()
  }, [])

  return (
    <Card style={{ marginTop: '16px' }}>
      <CardHeader title="Categories" />
      <CardContent>
        <ResponsiveContainer width="100%" height={260}>
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              innerRadius={50}
              outerRadius={80}
              data={pieData}
              onMouseEnter={(_data, index) => setActiveIndex(index)}
            >
              {pieData.map((_entry, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {pieData.map((entry, index) => (
            <Key
              key={index}
              value={entry.name}
              color={COLORS[index % COLORS.length]}
            />
          ))}
        </div>
      </CardContent>
    </Card>
  )
}

const exporter = async data => {
  try {
    const TAGS = _.map(TAG_MAP, tag => ({
      ...tag,
      name: tag.name.charAt(0).toUpperCase() + tag.name.slice(1),
    }))

    const dataForExport = await Promise.all(
      data.map(async (item, index) => {
        const result = [
          index + 1,
          item._id,
          _.get(item, 'deviceInfo.getDeviceCountry', 'Unknown'),
          _.get(item, 'deviceInfo.getSystemName', 'Unknown'),
          Date(item.createdAt),
          item.comment,
          item.categories.includes('LIKE'),
          item.categories.includes('PRICE'),
          item.categories.includes('APP_EXPERIENCE'),
          item.categories.includes('AUDIO_GUIDE_CONTENT'),
          item.categories.includes('MAP'),
          item.categories.includes('OTHER'),
        ]

        return result
      })
    )

    const wb = XLSX.utils.book_new()
    wb.Props = {
      Title: 'FEEDBACK REPORT',
      CreatedDate: new Date(),
    }
    wb.SheetNames.push('Sheet1')

    const sheetData = [
      ['FEEDBACK REPORT', '', '', '', '', '', '', '', '', '', '', ''],
      [
        'No',
        'ID',
        'Country',
        'System',
        'Time',
        'Comment',
        'Like',
        'Need Improved',
        '',
        '',
        '',
        '',
      ],
      [
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        'Price',
        'App experience',
        'Audio guide content',
        'Map',
        'Other',
      ],
    ].concat(dataForExport)
    const ws = XLSX.utils.aoa_to_sheet(sheetData, { cellDates: true })

    ws['!merges'] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 11 } },
      { s: { r: 1, c: 7 }, e: { r: 1, c: 11 } },
    ].concat(_.range(7).map(i => ({ s: { r: 1, c: i }, e: { r: 2, c: i } })))

    wb.Sheets['Sheet1'] = ws

    XLSX.writeFile(wb, 'feedback_report.xlsx', {
      cellDates: true,
    })
  } catch (e) {
    console.error(e)
  }
}

export const FeedbackList = props => (
  <Grid>
    <Grid item xs={12}>
      <List
        {...props}
        sort={{ field: 'createdAt', order: 'DESC' }}
        filters={<FeedbackFilter />}
        exporter={exporter}
      >
        <Datagrid rowClick="show">
          <TextField
            label="Country"
            source="deviceInfo.getDeviceCountry"
            sortable={false}
          />
          <TextField
            label="System"
            source="deviceInfo.getSystemName"
            sortable={false}
          />
          <DateField label="Time" source="createdAt" />
          <TextField source="comment" sortable={false} />
          <FunctionField
            label="Liked (× = No, ✓ = Yes)"
            render={record =>
              record.categories.includes('LIKE') ? <h3>✓</h3> : <h3>×</h3>
            }
          />
          <ArrayField label="Improved/Categories" source="categories">
            <TagsField />
          </ArrayField>
        </Datagrid>
      </List>
    </Grid>
    <Grid item xs={12} sm={9} md={7} lg={5}>
      <FeedbackPie />
    </Grid>
  </Grid>
)

export const FeedbackShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <DateField source="createdAt" />
      <RichTextField source="comment" />
      <TextField label="System" source="deviceInfo.getSystemName" />
      <TextField label="Country" source="deviceInfo.getDeviceCountry" />
      <ArrayField source="categories">
        <TagsField />
      </ArrayField>
    </SimpleShowLayout>
  </Show>
)
