import React from 'react'
import {
  CheckboxGroupInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  FormTab,
  ImageField,
  ImageInput,
  List,
  TabbedForm,
  TextInput,
  WithPermissions,
  required,
  LongTextInput,
  DateInput,
  BooleanInput,
  BooleanField,
} from 'react-admin'
import {
  ReviewActions,
  TextWithIndicator,
  MuseumSelector,
  PreviewToolbar,
} from '../../components/index'
import { requireOne } from '../../util/permissions'
import AnnouncementActions from '../../components/ListActions'

const langs = process.env.REACT_APP_SUPPORTED_LANGUAGES.split(',')
const baseLanguage = process.env.REACT_APP_BASE_LANGUAGE

export { default as AnnouncementIcon } from '@material-ui/icons/Announcement'

export const AnnouncementList = props => (
  <List {...props} actions={<AnnouncementActions />}>
    <Datagrid>
      <TextWithIndicator label="labels.name" source="name" />
      <DateField locales="vi-VN" source="startDate" />
      <DateField locales="vi-VN" source="endDate" />
      <BooleanField source="active" />
      <EditButton basePath="/announcement" />
    </Datagrid>
  </List>
)

const AnnouncementTitle = ({ record }) => {
  return <span>{record ? `${record.name}` : ''}</span>
}

const CommonFormTab = ({ lang, ...props }) => (
  <FormTab {...props}>
    <MuseumSelector {...props} />
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['admin', 'director'])(permissions) ? (
          <CheckboxGroupInput
            source="publishedTo"
            choices={[
              { id: 'WEBSITE', name: 'labels.website' },
              { id: 'APP', name: 'labels.app', disabled: true },
            ]}
            optionText="name"
            optionValue="id"
            label="resources.artifact.fields.publishedTo"
          />
        ) : null
      }
    </WithPermissions>
    <CheckboxGroupInput
      source="publishedLanguages"
      choices={[
        { id: 'vi', name: 'languages.vi' },
        { id: 'en', name: 'languages.en' },
        { id: 'fr', name: 'languages.fr' },
      ]}
    />
    <BooleanInput source="active" />
    <TextInput source="name" validate={[required()]} fullWidth />
    <DateInput source="startDate" validate={[required()]} />
    <DateInput source="endDate" validate={[required()]} />
    <ImageInput source="image" accept="image/*" validate={[required()]}>
      <ImageField source="uri" title="title" />
    </ImageInput>
    <LongTextInput
      label="labels.content"
      source={
        baseLanguage === lang ? 'description' : `i18n.${lang}.description`
      }
      validate={baseLanguage === lang ? [required()] : []}
    />
  </FormTab>
)

export const AnnouncementEdit = props => (
  <Edit
    {...props}
    title={<AnnouncementTitle />}
    actions={<ReviewActions allowPublish={['admin', 'director']} />}
    undoable={false} // Don't allow undo so that change takes effect for immediate preview.
  >
    <TabbedForm toolbar={<PreviewToolbar />}>
      {langs.map(lang => (
        <CommonFormTab label={`languages.${lang}`} key={lang} lang={lang} />
      ))}
    </TabbedForm>
  </Edit>
)

export const AnnouncementCreate = props => (
  <Create title="titles.create_announcement" {...props}>
    <TabbedForm redirect="list">
      {langs.map(lang => (
        <CommonFormTab label={`languages.${lang}`} key={lang} lang={lang} />
      ))}
    </TabbedForm>
  </Create>
)
