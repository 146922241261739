import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import FormInput from 'ra-ui-materialui/lib/form/FormInput'
import { LongTextInput, required, TextInput } from 'react-admin'
import { FileInput } from 'react-admin'
import { AudioField } from './index'

class MultiInput extends Component {
  state = {
    selectedKey: 0,
  }

  selectKey = i => this.setState({ selectedKey: i })

  render() {
    const { baseLang, inputs, keys = supportedLangs } = this.props
    const { selectedKey } = this.state
    const lang = keys[selectedKey].code

    return (
      <Paper>
        <div style={{ padding: 10, backgroundColor: '#EFEFEF' }}>
          {keys.map((key, i) => (
            <div key={i} style={{ display: 'inline' }}>
              <Button
                style={{ backgroundColor: selectedKey === i ? '#DEDEDE' : '' }}
                primary={`${selectedKey === i}`}
                onClick={this.selectKey.bind(this, i)}
              >
                {key.name}
              </Button>
            </div>
          ))}
        </div>
        <div style={{ padding: 10 }}>
          {inputs(baseLang, lang).map(input => React.cloneElement(input))}
        </div>
      </Paper>
    )
  }
}

const supportedLangs = [
  {
    name: 'Tiếng Việt',
    code: 'vi',
  },
  {
    name: 'Tiếng Anh',
    code: 'en',
  },
  {
    name: 'Tiếng Pháp',
    code: 'fr',
  },
  {
    name: 'Tiếng Trung',
    code: 'zh',
  },
  {
    name: 'Tiếng Nhật',
    code: 'ja',
  },
  {
    name: 'Tiếng Hàn',
    code: 'ko',
  },
  {
    name: 'Tiếng Tây Ban Nha',
    code: 'es',
  },
  {
    name: 'Tiếng Ý',
    code: 'it',
  },
  {
    name: 'Tiếng Đức',
    code: 'de',
  },
  // {
  //   name: 'Tiếng Thái',
  //   code: 'th',
  // },
  // {
  //   name: 'Tiếng Mã Lai',
  //   code: 'ms',
  // },
  // {
  //   name: 'Tiếng Indonesia',
  //   code: 'id',
  // },
  // {
  //   name: 'Tiếng Khmer',
  //   code: 'km',
  // },
  // {
  //   name: 'Tiếng Philippines',
  //   code: 'tl',
  // },
  // {
  //   name: 'Tiếng Bồ Đào Nha',
  //   code: 'pt',
  // },
  // {
  //   name: 'Tiếng Nga',
  //   code: 'ru',
  // },
]

export default MultiInput
