/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react'
import { Admin, Resource } from 'react-admin'
import theme from './theme/default'
import jwtDecode from 'jwt-decode'
import vietnameseMessages from './translation/vi'
import { authClient, restClient } from './feathersClient'
import { BlockCreate, BlockEdit, BlockIcon, BlockList } from './resource/block'
import { FloorCreate, FloorEdit, FloorIcon, FloorList } from './resource/floor'
import { TagCreate, TagEdit, TagIcon, TagList } from './resource/tag'
import {
  ApptagCreate,
  ApptagEdit,
  ApptagIcon,
  ApptagList,
} from './resource/apptag'
import { UserCreate, UserEdit, UserIcon, UserList } from './resource/user'
import {
  MuseumCreate,
  MuseumEdit,
  MuseumIcon,
  MuseumList,
} from './resource/museum/index'
import {
  CategoryCreate,
  CategoryEdit,
  CategoryIcon,
  CategoryList,
} from './resource/category'
import {
  ArtifactCreate,
  ArtifactEdit,
  ArtifactIcon,
  ArtifactList,
} from './resource/artifact/index'
import {
  AuthorCreate,
  AuthorEdit,
  AuthorIcon,
  AuthorList,
} from './resource/author/index'
import {
  ExhibitionCreate,
  ExhibitionEdit,
  ExhibitionIcon,
  ExhibitionList,
} from './resource/exhibition'
import {
  CollectionCreate,
  CollectionEdit,
  CollectionIcon,
  CollectionList,
} from './resource/collection'
import { NewsCreate, NewsEdit, NewsIcon, NewsList } from './resource/news'
import { VoucherCreate, VoucherIcon, VoucherList } from './resource/voucher'
import {
  MobileAppEdit,
  MobileAppIcon,
  MobileAppList,
} from './resource/mobileapp'
import {
  DisplayCreate,
  DisplayEdit,
  DisplayIcon,
  DisplayList,
} from './resource/display'
import {
  BeaconCreate,
  BeaconEdit,
  BeaconIcon,
  BeaconList,
} from './resource/beacon'
import {
  RentaldeviceCreate,
  RentaldeviceEdit,
  RentaldeviceIcon,
  RentaldeviceList,
} from './resource/rentaldevice'
import {
  AnnouncementCreate,
  AnnouncementEdit,
  AnnouncementIcon,
  AnnouncementList,
} from './resource/announcement'
import { TourCreate, TourEdit, TourIcon, TourList } from './resource/tour'
import { FeedbackIcon, FeedbackList, FeedbackShow } from './resource/feedback'
import Dashboard from './dashboard'
import Login from './authentication/Login'
import Menu from './Menu'
import logo from './images/logo_icon.png'
import sagas from './sagas'
import customRoutes from './customRoutes'
import { DeviceMonitor } from './components'
import axios from 'axios'
import _ from 'lodash'
import globalData from './global/global-data'

const messages = {
  vi: vietnameseMessages,
}

const Title = ({ museumName = null }) => {
  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <img src={logo} alt="logo" style={{ height: 50, paddingRight: '1em' }} />
      {museumName ? museumName : 'iMuseum Quản trị'}
    </span>
  )
}

const i18nProvider = locale => messages[locale]

const App = () => {
  useEffect(() => {
    const token = window.localStorage.getItem('feathers-jwt')
    if (!token) return

    // Check if token has expired and redirect to login page if it has.
    const currentTime = Math.round(Date.now() / 1000) // Token expiry time does not contain milliseconds so remove them.
    const decoded = jwtDecode(token)

    if (decoded.exp <= currentTime) {
      // Delete the token and redirect to login page.
      window.localStorage.removeItem('feathers-jwt')
      window.location = '/#/login'
    }
  })

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_HOST ||
          window.env
            .API_URL}/graphql?query=%7B%0A%20%20museum%20%7B%0A%20%20%20%20floors(sortField%3A%20"number"%2C%20sortOrder%3A%20DSC)%20%7B%0A%20%20%20%20%20%20data%20%7B%0A%20%20%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%20%20name%0A%20%20%20%20%20%20%20%20number%0A%20%20%20%20%20%20%20%20map%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D%0A`,
        {
          headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY },
        }
      )
      .then(res => {
        const floors = _.get(res, 'data.data.museum.floors.data', [])
        localStorage.setItem('allFloors', JSON.stringify(floors))
      })

    const localFloors = localStorage.getItem('allFloors')
    if (localFloors) {
      globalData.data.floors = JSON.parse(localFloors)
    }
  }, [])

  return (
    <div>
      <Admin
        title={<Title />}
        customRoutes={customRoutes}
        locale="vi"
        i18nProvider={i18nProvider}
        authProvider={authClient}
        dataProvider={restClient}
        loginPage={Login}
        dashboard={Dashboard}
        customSagas={sagas}
        menu={Menu}
        theme={theme}
      >
        {permissions => {
          return [
            permissions.includes('admin') ? (
              <Resource
                name="museum"
                list={MuseumList}
                edit={MuseumEdit}
                create={MuseumCreate}
                icon={MuseumIcon}
              />
            ) : (
              <Resource name="museum" edit={MuseumEdit} icon={MuseumIcon} />
            ),
            permissions.includes('vinmasStaff') ? null : (
              <Resource
                name="category"
                list={CategoryList}
                edit={CategoryEdit}
                create={CategoryCreate}
                icon={CategoryIcon}
              />
            ),
            permissions.includes('vinmasStaff') ? null : (
              <Resource
                name="artifact"
                list={ArtifactList}
                edit={ArtifactEdit}
                create={ArtifactCreate}
                icon={ArtifactIcon}
              />
            ),
            permissions.includes('vinmasStaff') ? null : (
              <Resource
                name="display"
                list={DisplayList}
                create={DisplayCreate}
                edit={DisplayEdit}
                icon={DisplayIcon}
              />
            ),
            permissions.includes('vinmasStaff') ? null : (
              <Resource
                name="collection"
                list={CollectionList}
                create={CollectionCreate}
                edit={CollectionEdit}
                icon={CollectionIcon}
              />
            ),
            permissions.includes('vinmasStaff') ? null : (
              <Resource
                name="author"
                list={AuthorList}
                edit={AuthorEdit}
                create={AuthorCreate}
                icon={AuthorIcon}
              />
            ),
            permissions.includes('vinmasStaff') ? null : (
              <Resource
                name="exhibition"
                list={ExhibitionList}
                edit={ExhibitionEdit}
                create={ExhibitionCreate}
                icon={ExhibitionIcon}
              />
            ),
            permissions.includes('vinmasStaff') ? null : (
              <Resource
                name="tour"
                list={TourList}
                edit={TourEdit}
                create={TourCreate}
                icon={TourIcon}
              />
            ),
            permissions.includes('admin') ? (
              <Resource
                name="user"
                list={UserList}
                edit={UserEdit}
                create={UserCreate}
                icon={UserIcon}
              />
            ) : (
              <Resource name="user" />
            ),
            permissions.includes('vinmasStaff') ? null : (
              <Resource
                name="floor"
                list={FloorList}
                edit={FloorEdit}
                create={FloorCreate}
                icon={FloorIcon}
              />
            ),
            permissions.includes('vinmasStaff') ? null : (
              <Resource
                name="block"
                list={BlockList}
                edit={BlockEdit}
                create={BlockCreate}
                icon={BlockIcon}
              />
            ),
            permissions.includes('vinmasStaff') ? null : (
              <Resource
                name="tag"
                list={TagList}
                create={TagCreate}
                edit={TagEdit}
                icon={TagIcon}
                delete={false}
              />
            ),
            permissions.includes('admin') ? (
              <Resource
                name="apptag"
                list={ApptagList}
                create={ApptagCreate}
                edit={ApptagEdit}
                icon={ApptagIcon}
                delete={false}
              />
            ) : null,
            permissions.includes('vinmasStaff') ? null : (
              <Resource
                name="news"
                list={NewsList}
                create={NewsCreate}
                edit={NewsEdit}
                icon={NewsIcon}
              />
            ),
            permissions.includes('vinmasStaff') ? null : (
              <Resource
                name="announcement"
                list={AnnouncementList}
                edit={AnnouncementEdit}
                create={AnnouncementCreate}
                icon={AnnouncementIcon}
              />
            ),
            <Resource
              name="voucher"
              list={VoucherList}
              create={
                permissions.includes('admin')
                  ? VoucherCreate
                  : null /* Only admin can create vouchers */
              }
              icon={VoucherIcon}
            />,
            permissions.includes('admin') ? (
              <Resource
                name="beacon"
                list={BeaconList}
                create={BeaconCreate}
                edit={BeaconEdit}
                icon={BeaconIcon}
              />
            ) : null,
            permissions.includes('admin') ? (
              <Resource
                name="rentaldevice"
                list={RentaldeviceList}
                create={RentaldeviceCreate}
                edit={RentaldeviceEdit}
                icon={RentaldeviceIcon}
              />
            ) : null,
            permissions.includes('vinmasStaff') ? (
              <Resource
                name="rentaldevice"
                list={RentaldeviceList}
                edit={RentaldeviceEdit}
                icon={RentaldeviceIcon}
              />
            ) : null,
            permissions.includes('admin') ||
            permissions.includes('vinmasStaff') ? (
              <Resource
                name="feedback"
                show={FeedbackShow}
                list={FeedbackList}
                icon={FeedbackIcon}
              />
            ) : null,
          ]
        }}
      </Admin>
      <DeviceMonitor />
    </div>
  )
}

export default App
