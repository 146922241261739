import React, { Fragment } from 'react'
import {
  BulkDeleteWithConfirmButton,
  CheckboxGroupInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  FileInput,
  FormTab,
  ImageField,
  FormInput,
  ImageInput,
  List,
  LongTextInput,
  NumberInput,
  required,
  SelectInput,
  Show,
  SimpleShowLayout,
  TabbedForm,
  TextField,
  TextInput,
  WithPermissions,
} from 'react-admin'
import moment from 'moment'
import _ from 'lodash'
import {
  ImagesField,
  MapField,
  MultiInput,
  PlaceInput,
  ReviewActions,
  Toolbar,
  AudioField,
} from '../../components'
import { requireOne } from '../../util/permissions'
import MuseumActions from '../../components/ListActions'

export { default as MuseumIcon } from '@material-ui/icons/AccountBalance'

export const MuseumList = props => (
  <List
    {...props}
    bulkActionButtons={<BulkDeleteWithConfirmButton />}
    actions={<MuseumActions />}
  >
    <Datagrid>
      <TextField label="labels.name" source="name" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <EditButton basePath="/museum" />
    </Datagrid>
  </List>
)

const MuseumTitle = ({ record }) => {
  return <span>{record ? `${record.name}` : ''}</span>
}

// List all times in half hour increments between 00:00 am and 24:00 pm
const listTimes = () => {
  const times = []
  _.times(24, hour => {
    ;['00', '30'].forEach(mins => {
      const time = `${hour}:${mins}`
      times.push({ id: time, name: time })
    })
  })
  return times
}

const validateMuseumForm = (values, { translate }) => {
  const errors = {}
  if (
    moment(values.openTime, 'HH:mm a').diff(
      moment(values.closeTime, 'HH:mm a')
    ) >= 0
  ) {
    errors.closeTime = [translate('errors.closeTimeBeforeOpen')]
  }
  return errors
}

const langs = process.env.REACT_APP_SUPPORTED_LANGUAGES.split(',')
const baseLang = process.env.REACT_APP_BASE_LANGUAGE

const CommonFormTab = ({ lang, ...props }) => (
  <FormTab {...props}>
    <TextInput
      label="labels.name"
      source={lang === baseLang ? 'name' : `i18n.${lang}.name`}
      validate={baseLang === lang ? [required()] : []}
    />
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['admin'])(permissions) ? (
          <Fragment>
            <CheckboxGroupInput
              source="publishedTo"
              choices={[
                { id: 'WEBSITE', name: 'labels.website' },
                { id: 'APP', name: 'labels.app', disabled: true },
              ]}
              optionText="name"
              optionValue="id"
              label="resources.artifact.fields.publishedTo"
            />
            <NumberInput source="ranking" />
            <TextInput source="geoFence" fullWidth={true} multiline={true} />
          </Fragment>
        ) : null
      }
    </WithPermissions>
    <SelectInput source="openTime" choices={listTimes()} />
    <SelectInput source="closeTime" choices={listTimes()} />
    <TextInput
      label="labels.workingDay"
      source={lang === baseLang ? 'workingDay' : `i18n.${lang}.workingDay`}
    />
    <TextInput source="phoneNumber" />
    <PlaceInput
      label="labels.address"
      source={lang === baseLang ? 'location' : `i18n.${lang}.location`}
      validate={baseLang === lang ? [required()] : []}
    />
    <ImageInput source="images" multiple accept="image/*">
      <ImageField source="uri" title="title" />
    </ImageInput>
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['admin', 'vinmasStaff'])(permissions) ? (
          <MultiInput
            baseLang={baseLang}
            inputs={(baseLang, lang, key) => [
              <FormInput
                key={key}
                input={
                  <FileInput
                    label="labels.audio"
                    source={lang === baseLang ? 'audio' : `i18n.${lang}.audio`}
                    accept="audio/*"
                  >
                    <AudioField source="uri" title="title" />
                  </FileInput>
                }
              />,
              <FormInput
                key={key}
                input={
                  <LongTextInput
                    label="labels.transcript"
                    source={
                      lang === baseLang
                        ? 'audioTranscript'
                        : `i18n.${lang}.audioTranscript`
                    }
                  />
                }
              />,
            ]}
          />
        ) : null
      }
    </WithPermissions>
    <LongTextInput
      label="labels.description"
      source={lang === baseLang ? 'description' : `i18n.${lang}.description`}
    />
  </FormTab>
)

export const MuseumEdit = props => (
  <Edit
    {...props}
    title={<MuseumTitle />}
    actions={
      <ReviewActions
        allowPublish={['admin', 'director']}
        allowUnpublish={['admin']}
        redirect={'edit'}
      />
    }
  >
    <TabbedForm
      toolbar={<Toolbar />}
      validate={validateMuseumForm}
      redirect={false}
    >
      {langs.map(lang => (
        <CommonFormTab label={`languages.${lang}`} key={lang} lang={lang} />
      ))}
    </TabbedForm>
  </Edit>
)

export const MuseumCreate = props => (
  <Create title="titles.create_museum" {...props}>
    <TabbedForm
      toolbar={<Toolbar />}
      redirect="list"
      validate={validateMuseumForm}
    >
      {langs.map(lang => (
        <CommonFormTab label={`languages.${lang}`} key={lang} lang={lang} />
      ))}
    </TabbedForm>
  </Create>
)

export const MuseumShow = props => (
  <Show title={<MuseumTitle />} {...props}>
    <SimpleShowLayout>
      <ImagesField source="images" />
      <MapField source="location" />
    </SimpleShowLayout>
  </Show>
)
