/** Code copied from https://stackoverflow.com/a/36490174/9787649
 * Returns all paths in an object as a string.
 *
 * For example:
 * object: {a:"1", b:{ foo:"2", bar:3 }, c:[0,1] }}
 * returns: ["a","b.foo","b.bar", "c[0]","c[1]"]
 */
import _ from 'lodash'

function paths(obj, parentKey) {
  var result
  if (_.isArray(obj)) {
    var idx = 0
    result = _.flatMap(obj, function(obj) {
      return paths(obj, (parentKey || '') + '[' + idx++ + ']')
    })
  } else if (_.isPlainObject(obj)) {
    result = _.flatMap(_.keys(obj), function(key) {
      return _.map(paths(obj[key], key), function(subkey) {
        return (parentKey ? parentKey + '.' : '') + subkey
      })
    })
  } else {
    result = []
  }
  return _.concat(result, parentKey || [])
}

export default paths
