import { put, takeEvery } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import { showNotification } from 'react-admin'
import {
  PUBLISH_RECORD_SUCCESS,
  PUBLISH_RECORD_FAILURE,
  UNPUBLISH_RECORD_SUCCESS,
  UNPUBLISH_RECORD_FAILURE,
} from '../actions/publishActions'

export default function* publishSaga() {
  yield [
    takeEvery(PUBLISH_RECORD_SUCCESS, function*(data) {
      yield put(
        showNotification(
          `resources.${data.requestPayload.resource}.notification.publish_success`
        )
      )
      yield put(push(data.requestPayload.basePath))
    }),
    takeEvery(PUBLISH_RECORD_FAILURE, function*({ requestPayload, error }) {
      yield put(
        showNotification(
          `resources.${requestPayload.resource}.notification.publish_failure`,
          'warning'
        )
      )
      console.error(error) // eslint-disable-line no-console
    }),
    takeEvery(UNPUBLISH_RECORD_SUCCESS, function*(data) {
      yield put(
        showNotification(
          `resources.${data.requestPayload.resource}.notification.unpublish_success`
        )
      )
      yield put(push(data.requestPayload.basePath))
    }),
    takeEvery(UNPUBLISH_RECORD_FAILURE, function*({ requestPayload, error }) {
      yield put(
        showNotification(
          `resources.${requestPayload.resource}.notification.unpublish_failure`,
          'warning'
        )
      )
      console.error(error) // eslint-disable-line no-console
    }),
  ]
}
