import React, { useState, useEffect } from 'react'
import jwtDecode from 'jwt-decode'
import Modal from '@material-ui/core/Modal'
import Card from '@material-ui/core/Card'
import { CardContent, CardHeader } from '@material-ui/core'
import { useInterval } from 'react-use'
import { app } from '../feathersClient'
import useSound from 'use-sound'
import alert from '../resource/rentaldevice/alert.wav'
import _ from 'lodash'

const getAlerted = async () => {
  const res = await app
    .service('rentaldevice')
    .find({ query: { alerted: true } })
  return res.data
}

const DeviceMonitor = () => {
  const [alerted, setAlerted] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [shouldAlert, setShouldAlert] = useState(undefined)
  const [playAlert] = useSound(alert)

  useInterval(() => {
    return // TODO: implement me
    if (shouldAlert || shouldAlert == undefined) {
      try {
        const token = localStorage.getItem('feathers-jwt')
        const decoded = jwtDecode(token)
        if (
          decoded.roles.includes('admin') ||
          decoded.roles.includes('vinmasAdmin') ||
          decoded.roles.includes('vinmasStaff')
        ) {
          setShouldAlert(true)
        } else {
          setShouldAlert(false)
        }
      } catch (e) {
        return
      }
    }

    if (shouldAlert) {
      ;(async () => {
        const newAlerts = await getAlerted()
        let hasNewAlert = false
        newAlerts.forEach(alert => {
          if (!_.find(alerted, { _id: alert._id })) {
            hasNewAlert = true
          }
        })
        setAlerted(newAlerts)

        if (hasNewAlert) {
          setModalOpen(true)
          playAlert()
        }
      })()
    } else {
      return
    }
  }, 5000)

  if (shouldAlert) {
    return (
      <Modal open={modalOpen}>
        <div
          style={{
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Card>
            <CardHeader title="Chú ý!" />
            <CardContent>
              <p>Vui lòng kiểm tra trạng thái của các thiết bị này:</p>
              <ul>
                {alerted.map(device => {
                  return <li key={device._id}>{device.name}</li>
                })}
              </ul>
            </CardContent>
          </Card>
        </div>
      </Modal>
    )
  } else {
    return null
  }
}

export default DeviceMonitor
