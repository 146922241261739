import React from 'react'
import ReactDOM from 'react-dom'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from 'react-apollo'
import './index.css'
import App from './App'
import registerServiceWorker from './registerServiceWorker'
import JssProvider from 'react-jss/lib/JssProvider'
import { createGenerateClassName } from '@material-ui/core/styles'

// Override the class name generator for material-ui so that the css is deterministic and we can target and remove
// the underline style.
const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
})

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_API_HOST}/graphql`,
})

ReactDOM.render(
  <JssProvider generateClassName={generateClassName}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </JssProvider>,
  document.getElementById('root')
)
registerServiceWorker()
