import feathers from '@feathersjs/client/dist/feathers'
import rest from '@feathersjs/client/dist/rest'
import auth from '@feathersjs/client/dist/authentication'
import axios from 'axios'
import {
  authClient as _authClient,
  restClient as _restClient,
} from 'ra-data-feathers'
import queryClient from './queryClient'
import appHooks from './app.hooks'
import apptagHooks from './services/apptag/apptag.hooks'
import artifactHooks from './services/artifact/artifact.hooks'
import authorHooks from './services/author/author.hooks'
import blockHooks from './services/block/block.hooks'
import categoryHooks from './services/category/category.hooks'
import collectionHooks from './services/collection/collection.hooks'
import displayHooks from './services/display/display.hooks'
import tourHooks from './services/tour/tour.hooks'
import exhibitionHooks from './services/exhibition/exhibition.hooks'
import floorHooks from './services/floor/floor.hooks'
import museumHooks from './services/museum/museum.hooks'
import newsHooks from './services/news/news.hooks'
import userHooks from './services/user/user.hooks'
import announcementHooks from './services/announcement/announcement.hooks'
import feedbackHooks from './services/feedback/feedback.hooks'

const host = process.env.REACT_APP_API_HOST || window.env.API_URL

const authClientOptions = {
  storageKey: 'feathers-jwt',
  authenticate: { strategy: 'local' },
}

const options = { id: '_id', usePatch: true }

export const app = feathers()
const client = rest(host)

app.configure(
  client.axios(
    axios.create({
      baseURL: host,
      timeout: 300000, // 5 minutes. Need a high timeout when uploading very large images.
    })
  )
)
app.configure(auth({ storage: window.localStorage }))

app.service('apptag').hooks(apptagHooks)
app.service('artifact').hooks(artifactHooks)
app.service('author').hooks(authorHooks)
app.service('block').hooks(blockHooks)
app.service('category').hooks(categoryHooks)
app.service('collection').hooks(collectionHooks)
app.service('display').hooks(displayHooks)
app.service('tour').hooks(tourHooks)
app.service('exhibition').hooks(exhibitionHooks)
app.service('floor').hooks(floorHooks)
app.service('museum').hooks(museumHooks)
app.service('news').hooks(newsHooks)
app.service('user').hooks(userHooks)
app.service('announcement').hooks(announcementHooks)
app.service('feedback').hooks(feedbackHooks)

app.hooks(appHooks)

export const restClient = queryClient(_restClient(app, options))

export const authClient = _authClient(app, authClientOptions)
