import React from 'react'
import { GeoJSONLayer } from 'react-mapbox-gl'

const FLOOR_COLOR = '#e9e9e7'
const WALL_COLOR = '#444444'
const ROOM_COLOR = '#5cffac'

export const FloorLayer = ({ data }) => (
  <GeoJSONLayer
    data={data}
    fillPaint={{
      'fill-color': FLOOR_COLOR,
      'fill-opacity': 1,
    }}
    layerOptions={{
      filter: ['all', ['==', 'type', 'floor']],
    }}
  />
)

export const WallLayer = ({ data }) => (
  <GeoJSONLayer
    data={data}
    linePaint={{
      'line-color': WALL_COLOR,
      'line-opacity': 1,
    }}
    layerOptions={{
      filter: ['all', ['==', 'type', 'walls']],
    }}
  />
)

export const RoomLayer = ({ data, extraFilters = [] }) => (
  <GeoJSONLayer
    data={data}
    fillPaint={{
      'fill-color': ROOM_COLOR,
      'fill-opacity': 1,
    }}
    layerOptions={{
      filter: ['all', ['==', 'type', 'room'], ...extraFilters],
    }}
  />
)

export const ArtifactLayer = ({ data, selected, hide }) =>
  selected ? (
    <GeoJSONLayer
      data={data}
      fillPaint={{
        'fill-color': '#df8057',
        'fill-opacity': 1,
      }}
    />
  ) : (
    <GeoJSONLayer
      data={data}
      fillPaint={{
        'fill-color': '#BBB',
        'fill-opacity': 1,
      }}
      layerOptions={{
        filter: ['all', ['==', 'type', 'artifact'], ['!=', '_id', hide + '']],
      }}
    />
  )
