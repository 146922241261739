// We need to wait until items are saved before redirecting users to the preview page.
// This saga listens for those save events and will then redirect the user as required.
import { put, call, take, takeEvery } from 'redux-saga/effects'
import { showNotification } from 'react-admin'
import { PREVIEW_RECORD } from '../actions/previewActions'

export default function* previewSaga() {
  yield [
    takeEvery(PREVIEW_RECORD, function*({ payload }) {
      yield put(
        showNotification('actions.redirecting', 'info', {
          messageArgs: { url: payload.url },
        })
      )
      yield take('RA/CRUD_UPDATE_SUCCESS') // Wait for record to save before redirecting user to preview.
      yield call(payload.redirect)
    }),
  ]
}
