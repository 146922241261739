/* eslint-disable no-console */
import { app } from '../feathersClient'
import jwtDecode from 'jwt-decode'
import _ from 'lodash'
import hash from 'object-hash'

// Copied from: https://medium.com/@seladir/how-to-implement-web-push-notifications-in-your-node-react-app-9bed79b53f34
const convertedVapidKey = urlBase64ToUint8Array(
  process.env.REACT_APP_PUBLIC_VAPID_KEY
)

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export async function addSubscription() {
  const subscription = await getSubscription()

  const { userId } = jwtDecode(localStorage.getItem('feathers-jwt'))
  const { subscriptions = [], ...user } = await app.service('user').get(userId)

  if (
    _.find(
      subscriptions,
      s => hash(JSON.stringify(s)) === hash(JSON.stringify(subscription))
    )
  ) {
    console.log('subscription already in db')
    console.log('current: ', subscriptions)
    return
  } else {
    console.log('adding subscription to db')
    const newSubscriptions = subscriptions.concat(subscription)
    console.log('new: ', newSubscriptions)
    await app
      .service('user')
      .patch(userId, { subscriptions: newSubscriptions, ...user })
  }
}

export async function removeSubscription() {
  const subscription = await getSubscription()

  const { userId } = jwtDecode(localStorage.getItem('feathers-jwt'))
  const { subscriptions = [], ...user } = await app.service('user').get(userId)

  if (!_.find(subscriptions, subscription)) {
    return
  } else {
    const i = _.findIndex(subscriptions, subscription)
    subscriptions.splice(i, 1)
    await app.service('user').patch(userId, { subscriptions, ...user })
  }
}

export async function hasSubscription() {
  const subscription = await getSubscription()

  if (subscription === null) {
    return false
  }

  const { userId } = jwtDecode(localStorage.getItem('feathers-jwt'))
  const { subscriptions } = await app.service('user').get(userId)
  return (
    !_.isEmpty(subscriptions) &&
    _.find(
      subscriptions,
      s => hash(JSON.stringify(s)) === hash(JSON.stringify(subscription))
    )
  )
}

async function getSubscription() {
  if (!('serviceWorker' in navigator)) {
    return null
  }

  const registration = await navigator.serviceWorker.ready

  if (!registration.pushManager) {
    return null
  }

  try {
    const existedSubscription = await registration.pushManager.getSubscription()

    if (existedSubscription !== null) {
      return existedSubscription
    }

    console.log('No subscription detected, make a request')
    try {
      const newSubscription = await registration.pushManager.subscribe({
        applicationServerKey: convertedVapidKey,
        userVisibleOnly: true,
      })
      return newSubscription
    } catch (e) {
      if (Notification.permission !== 'granted') {
        console.log('Permission was not granted')
      } else {
        console.error('An error occured during the subscription process.', e)
      }
    }
  } catch (e) {
    console.error('An error ocurred during Service Worker registration.', e)
    return null
  }
}
