import React, { Fragment } from 'react'

import {
  BooleanInput,
  BulkDeleteWithConfirmButton,
  CheckboxGroupInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  FormTab,
  ImageField,
  ImageInput,
  List,
  LongTextInput,
  NumberInput,
  ReferenceArrayInput,
  required,
  TabbedForm,
  TextField,
  TextInput,
  WithPermissions,
} from 'react-admin'
import _ from 'lodash'
import placeholder from '../../images/placeholder.png'
import { MuseumSelector, PreviewToolbar, ReviewActions } from '../../components'
import { requireOne } from '../../util/permissions'
import StatusBadge from '../../components/StatusBadge'
import AutocompleteArrayInput from '../../components/AutocompleteArrayInput'
import CollectionActions from '../../components/ListActions'

export { default as CollectionIcon } from '@material-ui/icons/ViewModule'

const langs = process.env.REACT_APP_SUPPORTED_LANGUAGES.split(',')
const baseLang = process.env.REACT_APP_BASE_LANGUAGE

const CroppedImageField = props => {
  const record = props.record
  record.croppedImage = _.get(record, 'images.0.uri')
    ? record.images[0].uri
    : placeholder

  const img = <ImageField {...props} record={record} source="croppedImage" />

  return (
    <StatusBadge type="smallImage" record={record}>
      {img}
    </StatusBadge>
  )
}

export const CollectionList = props => (
  <List
    {...props}
    bulkActionButtons={<BulkDeleteWithConfirmButton />}
    actions={<CollectionActions />}
  >
    <Datagrid>
      <CroppedImageField />
      <TextField label="labels.name" source="name" />
      <EditButton basePath="/collection" />
    </Datagrid>
  </List>
)

const CollectionTitle = ({ record }) => {
  return <span>{record ? `${record.name}` : ''}</span>
}

const CommonFormTab = ({ lang, ...props }) => (
  <FormTab {...props}>
    <MuseumSelector {...props} />
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['admin', 'director'])(permissions) ? (
          <CheckboxGroupInput
            source="publishedTo"
            choices={[
              { id: 'WEBSITE', name: 'labels.website' },
              { id: 'APP', name: 'labels.app', disabled: true },
            ]}
            optionText="name"
            optionValue="id"
            label="resources.artifact.fields.publishedTo"
          />
        ) : null
      }
    </WithPermissions>
    <CheckboxGroupInput
      source="publishedLanguages"
      choices={[
        { id: 'vi', name: 'languages.vi' },
        { id: 'en', name: 'languages.en' },
        { id: 'fr', name: 'languages.fr' },
      ]}
    />
    <NumberInput source="ranking" />
    <TextInput
      label="labels.name"
      source={lang === baseLang ? 'name' : `i18n.${lang}.name`}
      validate={lang === baseLang ? [required()] : []}
      fullWidth
    />
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['admin'])(permissions) ? (
          <Fragment>
            <BooleanInput source="recommended" />
          </Fragment>
        ) : null
      }
    </WithPermissions>
    <ReferenceArrayInput
      source="artifacts"
      reference="artifact"
      perPage={Infinity}
      allowEmpty
    >
      <AutocompleteArrayInput optionText="name" options={{ fullWidth: true }} />
    </ReferenceArrayInput>
    <ImageInput source="images" multiple accept="image/*">
      <ImageField source="uri" title="title" />
    </ImageInput>
    <LongTextInput
      label="labels.description"
      source={lang === baseLang ? 'description' : `i18n.${lang}.description`}
    />
  </FormTab>
)

export const CollectionCreate = props => (
  <Create title="titles.create_collection" {...props}>
    <TabbedForm redirect="list">
      {langs.map(lang => (
        <CommonFormTab label={`languages.${lang}`} key={lang} lang={lang} />
      ))}
    </TabbedForm>
  </Create>
)

export const CollectionEdit = props => (
  <Edit
    {...props}
    title={<CollectionTitle />}
    actions={<ReviewActions allowPublish={['admin', 'director']} />}
    undoable={false} // Don't allow undo so that change takes effect for immediate preview.
  >
    <TabbedForm toolbar={<PreviewToolbar />}>
      {langs.map(lang => (
        <CommonFormTab label={`languages.${lang}`} key={lang} lang={lang} />
      ))}
    </TabbedForm>
  </Edit>
)
