import setMuseumId from '../../hooks/setMuseumId'
import processFiles from '../../hooks/processFiles'
import splitQuery from '../../hooks/splitQuery'

export default {
  before: {
    all: [],
    find: [splitQuery()],
    get: [],
    create: [setMuseumId(), processFiles()],
    update: [processFiles()],
    patch: [processFiles()],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
}
