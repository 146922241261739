import React from 'react'
import { connect } from 'react-redux'
import { getResources } from 'ra-core/lib/reducer'
import find from 'lodash/find'
import {
  DeleteWithConfirmButton,
  ListButton,
  RefreshButton,
  WithPermissions,
} from 'react-admin'
import CardActions from '@material-ui/core/CardActions'
import { PublishButton } from './'
import { requireOne } from '../util/permissions'

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
}

const ReviewActions = ({
  basePath,
  data,
  refresh,
  resource,
  allowPublish,
  allowUnpublish,
  disableDelete = false,
  resources,
  redirect = true,
}) => {
  const r = find(resources, { name: resource })

  if (!allowUnpublish) {
    allowUnpublish = ['admin', 'director', 'staff']
  }

  return (
    <CardActions style={cardActionStyle}>
      <WithPermissions>
        {({ permissions }) =>
          requireOne(allowPublish)(permissions) ? (
            <PublishButton
              record={data}
              resource={resource}
              basePath={basePath}
              allowUnpublish={allowUnpublish}
              redirect={redirect}
            />
          ) : null
        }
      </WithPermissions>
      {r.hasList && <ListButton basePath={basePath} />}
      {r.hasList && data && !disableDelete && (
        <DeleteWithConfirmButton
          basePath={basePath}
          record={data}
          resource={resource}
        />
      )}
      <RefreshButton refresh={refresh} />
    </CardActions>
  )
}

const mapStateToProps = state => ({
  resources: getResources(state),
})

export default connect(mapStateToProps)(ReviewActions)
