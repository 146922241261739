import React from 'react'
import {
  ReferenceInput,
  SelectInput,
  WithPermissions,
  required,
} from 'react-admin'

import { requireOne } from '../util/permissions'

const MuseumSelector = props => (
  <WithPermissions>
    {({ permissions }) =>
      requireOne(['admin'])(permissions) ? (
        <ReferenceInput
          {...props}
          label="labels.museum"
          source="museum"
          reference="museum"
          validate={props.optional ? [] : [required()]}
          perPage={Infinity}
          allowEmpty
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      ) : null
    }
  </WithPermissions>
)

export default MuseumSelector
