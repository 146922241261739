import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { GET_LIST } from 'react-admin'
import { restClient } from './../components/../feathersClient'
import ReactMapboxGl, { ZoomControl } from 'react-mapbox-gl'
import _ from 'lodash'
import { SizeMe } from 'react-sizeme'
import { levelWithRoom } from '../components/IndoorMapInput'
import axios from 'axios'
import LevelControl from '../components/LevelControl'
import { FloorLayer, WallLayer, RoomLayer } from '../components/MapLayers'
import DeviceMarker from '../components/DeviceMarker'
import qs from 'query-string'
import { app } from '../feathersClient'
import globalData from "../global/global-data";

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  minZoom: 4.5,
  maxZoom: 21,
})

class RentalDeviceMap extends React.Component {
  state = {
    data: [],
    currentLevel: 0,
    floors:globalData.data.floors || [],
    selected: null,
    prevSelected: null,
    firstLoad: true,
    blockId: null,
    zoomLevel: 17.5,
  }

  setSelected = async id => {
    if (!id && this.state.firstLoad) {
      id = qs.parse(this.props.location.search).id
      this.setState({ selected: id, prevSelected: id, firstLoad: false })
    }

    if (id) {
      this.setState({ prevSelected: id })

      const blockNumber = _.get(
        _.find(this.state.data, { id }),
        'location.block'
      )

      const blocks = await app
        .service('block')
        .find({ query: { number: blockNumber } })

      const blockId = _.get(blocks, 'data.0._id')
      const floorId = _.get(blocks, 'data.0.floor')
      const level = _.findIndex(this.state.floors, { _id: floorId })

      this.setState({ blockId, currentLevel: level ? level : 0 })
    } else {
      this.setState({ blockId: null })
    }

    this.setState({ selected: id })
  }

  async componentDidMount() {
    const { data } = await restClient(GET_LIST, 'rentaldevice', {})
    const filtered = data.filter(device => {
      return device.location && device.location.lng && device.location.lat
    })
    this.setState({ data: filtered })
    this.setSelected()
  }

  componentDidUpdate(prevProps) {
    if (
      _.get(prevProps, 'location.search') !==
      _.get(this, 'props.location.search')
    ) {
      this.setSelected()
    }
    if (
      _.get(prevProps, 'input.value.room') !==
      _.get(this, 'props.input.value.room')
    ) {
      const [currentLevel] = levelWithRoom(
        _.get(this, 'props.input.value.room'),
        this.state.floors
      )
      this.setState({ currentLevel })
    }
  }

  render() {
    const geojson = _.get(
      this,
      `state.floors.[${this.state.currentLevel}].map`,
      { type: 'FeatureCollection', features: [] }
    )

    let location, center
    if (!_.isEmpty(this.state.data)) {
      if (this.state.selected || this.state.prevSelected) {
        if (this.state.selected) {
          location = _.find(this.state.data, { id: this.state.selected })
            .location
        } else {
          location = _.find(this.state.data, { id: this.state.prevSelected })
            .location
        }
      } else {
        location = this.state.data[0].location
      }
    } else {
      location = {
        lng: 105.8369,
        lat: 21.0302,
      }
    }
    center = [location.lng, location.lat]

    return (
      <Card>
        <CardContent>
          <SizeMe
            monitorWidth
            render={({ size }) => (
              <div>
                <Map
                  style="mapbox://styles/lihop/cjm75gm470dc32rmpfw2bd8x2" // eslint-disable-line
                  containerStyle={{
                    height: 768,
                    width: size.width,
                  }}
                  center={center}
                  zoom={[this.state.zoomLevel]}
                  bearing={[14.5]}
                >
                  <ZoomControl />
                  <LevelControl
                    levels={this.state.floors}
                    currentLevel={this.state.currentLevel}
                    onClick={async level => {
                      const levelIndex = this.state.floors.indexOf(level)
                      await this.setState({ currentLevel: levelIndex })
                    }}
                  />
                  <FloorLayer data={geojson} />
                  {this.state.blockId && (
                    <RoomLayer
                      data={geojson}
                      extraFilters={[['==', '_id', this.state.blockId]]}
                    />
                  )}
                  <WallLayer data={geojson} />
                  {this.state.data.map(device => (
                    <DeviceMarker
                      key={device.id}
                      device={device}
                      popoverOpen={this.state.selected == device.id}
                      onSelected={this.setSelected}
                    />
                  ))}
                </Map>
              </div>
            )}
          />
        </CardContent>
      </Card>
    )
  }
}

export default RentalDeviceMap
