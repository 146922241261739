import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

const styles = {
  list: {
    display: 'flex',
    listStyleType: 'none',
  },
  video: {
    margin: '0.5rem',
    maxWidth: 750,
  },
}

export const AudioField = ({ elStyle = {}, record, source, src, title }) => {
  const sourceValue = get(record, source)
  if (!sourceValue) {
    return <div />
  }

  if (Array.isArray(sourceValue)) {
    const style = {
      ...styles.list,
      ...elStyle,
    }
    return (
      <ul style={style}>
        {sourceValue.map((file, index) => {
          const titleValue = get(file, title) || title
          const srcValue = get(file, src) || title

          return (
            <li key={index}>
              <audio
                controls
                alt={titleValue}
                title={titleValue}
                src={srcValue}
                style={styles.video}
              />
            </li>
          )
        })}
      </ul>
    )
  }

  const titleValue = get(record, title) || title

  return (
    <div style={elStyle}>
      <audio
        controls
        alt={titleValue}
        title={titleValue}
        src={sourceValue}
        style={styles.video}
      />
    </div>
  )
}

AudioField.propTypes = {
  elStyle: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  title: PropTypes.string,
}

export default AudioField
