import React from 'react'
import CardContent from '@material-ui/core/CardContent'
import { Marker } from 'react-mapbox-gl'
import PhoneIcon from '@material-ui/icons/PhoneAndroid'
import Popover from '@material-ui/core/Popover'
import { Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import compose from 'recompose/compose'
import { DateField, translate } from 'react-admin'

class DeviceMarker extends React.Component {
  state = {
    anchorEl: null,
  }

  render() {
    const { coordinates, device, translate } = this.props

    if (!device || !device.location) {
      return null
    }

    return (
      <Marker
        coordinates={coordinates || [device.location.lng, device.location.lat]}
      >
        <div
          ref={node => {
            if (!this.state.anchorEl) {
              this.setState({ anchorEl: node })
            }
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
          onClick={() => {
            if (this.props.onSelected) {
              this.props.onSelected(device.id)
            }
          }}
        >
          <p
            style={{
              position: 'absolute',
              left: '0',
              right: '0',
              textAlign: 'center',
              zIndex: -1,
            }}
          >
            {device.name}
          </p>
          <PhoneIcon style={{ width: 32, height: 32 }} />
        </div>
        <Popover
          open={this.props.popoverOpen}
          anchorEl={this.state.anchorEl}
          onClose={() => this.props.onSelected(null)}
          transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <CardContent>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: '50%' }}>
                <Typography>
                  {translate('resources.rentaldevice.fields.name')}:
                </Typography>
                <Typography>
                  {translate('resources.rentaldevice.fields.status')}:
                </Typography>
                <Typography>
                  {translate('resources.rentaldevice.fields.battery')}:
                </Typography>
                <Typography>
                  {translate('resources.rentaldevice.fields.location.block')}:
                </Typography>
                <Typography>
                  {translate('resources.rentaldevice.fields.lastStatusUpdate')}:
                </Typography>
                <Typography>
                  <Link to={`/rentaldevice/${device.id}`}>Xem</Link>
                </Typography>
              </div>
              <div style={{ flex: '50%' }}>
                <Typography>{device.name}</Typography>
                <Typography>{device.status}</Typography>
                <Typography>{device.battery}</Typography>
                <Typography>{device.location.block || '-'}</Typography>
                <DateField
                  locales="vi-VN"
                  record={device}
                  source="lastStatusUpdate"
                  showTime
                />
              </div>
            </div>
          </CardContent>
        </Popover>
      </Marker>
    )
  }
}

const enhance = compose(translate)

export default enhance(DeviceMarker)
