import React from 'react'
import { Route } from 'react-router-dom'
import ReportPage from './components/ReportPage'
import RentalDeviceMap from './pages/RentalDeviceMap'

export default [
  <Route
    key={0}
    exact
    path="/turnover/:id"
    component={props => <ReportPage {...props} />}
  />,
  <Route
    key={1}
    exact
    path="/turnover"
    component={props => <ReportPage {...props} />}
  />,
  <Route
    key={2}
    exact
    path="/rentaldevicemap"
    component={props => <RentalDeviceMap {...props} />}
  />,
]
