import React from 'react'
import {
  BulkDeleteWithConfirmButton,
  CheckboxGroupInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  ImageField,
  ImageInput,
  List,
  NumberField,
  NumberInput,
  required,
  SimpleForm,
  TextInput,
  translate,
  WithPermissions,
} from 'react-admin'
import { ReviewActions, TextWithIndicator, Toolbar } from '../../components'
import { requireOne } from '../../util/permissions'
import FATags from 'react-icons/lib/fa/tags'
import ApptagActions from '../../components/ListActions'

export const ApptagIcon = props => <FATags size={24} {...props} />

export const ApptagFilter = props => (
  <Filter {...props}>
    <TextInput
      label="resources.apptag.search"
      source="name[$search]"
      alwaysOn
    />
  </Filter>
)

export const ApptagList = props => (
  <List
    {...props}
    filters={<ApptagFilter />}
    bulkActionButtons={<BulkDeleteWithConfirmButton />}
    actions={<ApptagActions />}
    sort={{ field: 'ranking', order: 'ASC' }}
  >
    <Datagrid>
      <TextWithIndicator label="labels.name" source="name" />
      <NumberField source="ranking" />
      <EditButton basePath="/apptag" />
    </Datagrid>
  </List>
)

const langs = ['vi', 'en', 'fr', 'zh', 'ja', 'ko', 'es', 'it', 'de']
const baseLang = process.env.REACT_APP_BASE_LANGUAGE

export const ApptagCreate = translate(props => (
  <Create title="titles.create_apptag" {...props}>
    <SimpleForm redirect="list">
      <WithPermissions>
        {({ permissions }) =>
          requireOne(['admin', 'director'])(permissions) ? (
            <CheckboxGroupInput
              source="publishedTo"
              choices={[{ id: 'APP', name: 'labels.app', disabled: true }]}
              optionText="name"
              optionValue="id"
              label="resources.artifact.fields.publishedTo"
            />
          ) : null
        }
      </WithPermissions>
      <NumberInput source="ranking" />
      <ImageInput source="images" multiple accept="image/*">
        <ImageField source="uri" title="title" />
      </ImageInput>
      {langs.map(lang => (
        <TextInput
          key={lang}
          label={
            lang === baseLang
              ? 'labels.name'
              : `${props.translate('labels.name')} ${props.translate(
                  `languages.${lang}`
                )}`
          }
          source={lang === baseLang ? 'name' : `i18n.${lang}.name`}
          validate={lang === baseLang ? [required()] : []}
          fullWidth
        />
      ))}
    </SimpleForm>
  </Create>
))

export const ApptagEdit = translate(props => (
  <Edit
    title="titles.create_apptag"
    {...props}
    actions={<ReviewActions allowPublish={['admin']} />}
  >
    <SimpleForm toolbar={<Toolbar />}>
      <WithPermissions>
        {({ permissions }) =>
          requireOne(['admin'])(permissions) ? (
            <CheckboxGroupInput
              source="publishedTo"
              choices={[{ id: 'APP', name: 'labels.app', disabled: true }]}
              optionText="name"
              optionValue="id"
              label="resources.artifact.fields.publishedTo"
            />
          ) : null
        }
      </WithPermissions>
      <NumberInput source="ranking" />
      <ImageInput source="images" multiple accept="image/*">
        <ImageField source="uri" title="title" />
      </ImageInput>
      {langs.map(lang => (
        <TextInput
          key={lang}
          label={
            lang === baseLang
              ? 'labels.name'
              : `${props.translate('labels.name')} ${props.translate(
                  `languages.${lang}`
                )}`
          }
          source={lang === baseLang ? 'name' : `i18n.${lang}.name`}
          validate={lang === baseLang ? [required()] : []}
          fullWidth
        />
      ))}
    </SimpleForm>
  </Edit>
))
