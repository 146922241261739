import React from 'react'
import Badge from '@material-ui/core/Badge'
import { withStyles } from '@material-ui/core/styles'
import { canPublishOrUpdate } from '../resource/util'
import NewReleases from '@material-ui/icons/NewReleases'

const styles = {
  imageBadge: {
    top: '5px',
    right: '5px',
  },
  imageRoot: {
    width: '100%',
  },
  textRoot: {
    paddingRight: '20px',
  },
}

const StatusBadge = ({ classes, children, type, ...props }) => {
  const child = React.Children.only(children)
  switch (type) {
    case 'image':
      classes = { root: classes.imageRoot, badge: classes.imageBadge }
      break
    case 'smallImage':
      classes = { badge: classes.imageBadge }
      break
    case 'text':
    default:
      classes = { root: classes.textRoot }
  }

  return canPublishOrUpdate(props.record) ? (
    <Badge badgeContent={<NewReleases color="error" />} classes={classes}>
      {React.cloneElement(child, props)}
    </Badge>
  ) : (
    React.cloneElement(child, props)
  )
}

export default withStyles(styles)(StatusBadge)
