import React, { useState, useEffect } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import { GET_LIST, ExportButton, downloadCSV } from 'react-admin'
import { restClient } from './../feathersClient'
import _ from 'lodash'
import moment from 'moment-timezone'
import { CardHeader, TextField, Button } from '@material-ui/core'
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Area,
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Label,
} from 'recharts'
import ArrowForward from '@material-ui/icons/ArrowForward'
import { unparse as convertToCSV } from 'papaparse'
import DatePicker, { setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import vi from 'date-fns/locale/vi'

setDefaultLocale(vi)

export const renderActiveShape = props => {
  const RADIAN = Math.PI / 180
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      <text x={cx} y={cy - 120} dy={8} textAnchor="middle" fill="#000">
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  )
}

export const Key = ({ color, value }) => (
  <div>
    <div
      style={{
        display: 'inline-block',
        width: 10,
        height: 10,
        backgroundColor: color,
      }}
    />{' '}
    {value}&nbsp;&nbsp;&nbsp;
  </div>
)

const PurchasePie = props => {
  const COLORS = ['#A50021', '#3DDC84', '#198CF0', '#ccc']

  const [pieData, setPieData] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    if (!_.isEmpty(props.data)) {
      const counts = _.countBy(props.data, item =>
        item.purchaseMethod ? item.purchaseMethod : 'UNKNOWN'
      )
      setPieData([
        { name: 'voucher', value: counts['CASH'] },
        { name: 'android', value: counts['IAP_ANDROID'] },
        { name: 'apple', value: counts['IAP_APPLE'] },
      ])
    } else {
      setPieData([
        { name: 'voucher', value: 0 },
        { name: 'android', value: 0 },
        { name: 'apple', value: 0 },
      ])
    }
  }, [props.data])

  const onPieEnter = (_data, index) => {
    setActiveIndex(index)
  }

  return (
    <Card>
      <CardHeader title="Mua theo phương thức" style={{ marginBottom: -50 }} />
      <CardContent>
        <ResponsiveContainer width="100%" height={275}>
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              innerRadius={50}
              outerRadius={80}
              data={pieData}
              onMouseEnter={onPieEnter}
            >
              {pieData.map((_entry, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
              ))}
              <Label
                value={`tổng: ${_.sumBy(pieData, 'value')}`}
                position="center"
              />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {pieData.map((entry, index) => (
            <Key
              key={index}
              value={entry.name}
              color={COLORS[index % COLORS.length]}
            />
          ))}
        </div>
      </CardContent>
    </Card>
  )
}

const PurchaseChart = props => {
  const SCALES = ['Ngày', 'Tuần', 'Tháng', 'Năm']

  const DEFAULT_START_DATE = moment()
    .tz(process.env.REACT_APP_TIMEZONE)
    .subtract(1, 'month')
    .format('YYYY-MM-DD')
  const DEFAULT_END_DATE = moment()
    .tz(process.env.REACT_APP_TIMEZONE)
    .format('YYYY-MM-DD')

  const [scale, setScale] = useState('Ngày')
  const [startDate, setStartDate] = useState(DEFAULT_START_DATE)
  const [endDate, setEndDate] = useState(DEFAULT_END_DATE)
  const [chartData, setChartData] = useState([])

  useEffect(() => {
    if (!startDate || !endDate) {
      setChartData([])
      props.setData([])
      return
    }

    ;(async () => {
      const MONGO_DATE_FORMAT_END = 'YYYY-MM-DD[T23:59:59.999Z]'
      const MONGO_DATE_FORMAT_START = 'YYYY-MM-DD[T00:00:00.000Z]'
      const mongoStartDate = moment(startDate, 'YYYY-MM-DD')
        .tz(process.env.REACT_APP_TIMEZONE)
        .format(MONGO_DATE_FORMAT_START)
      const mongoEndDate = moment(endDate, 'YYYY-MM-DD')
        .tz(process.env.REACT_APP_TIMEZONE)
        .format(MONGO_DATE_FORMAT_END)

      const { data } = await restClient(
        GET_LIST,
        `turnover?startDate=${mongoStartDate}&endDate=${mongoEndDate}`,
        {}
      )

      props.setData(data)

      const formatDate = date => {
        switch (scale) {
          case 'Năm':
            return date.format('YYYY')
          case 'Tháng':
            return date.format('M/YY')
          case 'Tuần':
            return date.format('W/YY')
          case 'Ngày':
            return date.format('D/M/YY')
        }
      }

      // Create an object containing all possible dates between the date ranges
      // at all possible scales.
      const slots = {}

      var date = moment(startDate, 'YYYY-MM-DD').tz(
        process.env.REACT_APP_TIMEZONE
      )
      var end = moment(endDate, 'YYYY-MM-DD').tz(process.env.REACT_APP_TIMEZONE)

      if (
        date.isSameOrBefore(
          moment('2000-01-01').tz(process.env.REACT_APP_TIMEZONE)
        )
      ) {
        return
      }

      while (date <= end) {
        _.set(slots, formatDate(date), [])
        date = date.add(1, 'day')
      }

      // Now sort each item into the correct slot.
      data.forEach(item => {
        if (item.purchaseMethod == 'TEST') {
          return
        }
        const date = moment(item.usedAt, MONGO_DATE_FORMAT_START).tz(
          process.env.REACT_APP_TIMEZONE
        )
        _.get(slots, formatDate(date)).push(item)
      })

      const result = []

      Object.keys(slots).forEach(key => {
        const data = slots[key]

        if (_.isEmpty(data)) {
          result.push({
            name: key,
            total_sold: 0,
            in_app: 0,
            in_person: 0,
            in_app_revenue: 0,
            in_person_revenue: 0,
            total_revenue: 0,
          })
          return
        }

        const slot = { name: key }

        // Get the total number of vouchers sold.
        slot.total_sold = data.length

        // Get the total number of vouchers sold in app.
        slot.in_app = data.filter(
          item => item.purchaseMethod && item.purchaseMethod.startsWith('IAP_')
        ).length

        // Get the total number of vouchers sold in person.
        slot.in_person = slot.total_sold - slot.in_app

        // Calculate the revenue of in-app sales
        slot.in_app_revenue = slot.in_app * 45000

        // Calculate the revenue of in-person sales
        slot.in_person_revenue = slot.in_person * 40000

        // Calculate the total revenue
        slot.total_revenue = slot.in_app_revenue * 0.7 + slot.in_person_revenue

        result.push(slot)
      })

      setChartData(result)
    })()
  }, [startDate, endDate, scale])

  const handleDateChange = (setDateFunc, newDate) => {
    const date = moment(newDate).tz(process.env.REACT_APP_TIMEZONE)

    if (date.isValid()) {
      localStorage.setItem('chart-date-start', startDate)
      localStorage.setItem('chart-date-end', endDate)
      setDateFunc(date)
    } else {
      setDateFunc(
        setDateFunc == setStartDate ? DEFAULT_START_DATE : DEFAULT_END_DATE
      )
    }
  }

  const handleExport = () => {
    const dataForExport = chartData.map((item, index) => {
      return {
        STT: index,
        'Thời gian': item.name,
        'Số lượng': item.total_sold,
        'Doanh thu Voucher': item.in_person_revenue,
        'Doanh thu Buy in app': item.in_app_revenue,
        'Tổng doanh thu': item.total_revenue,
      }
    })

    const csv = convertToCSV({
      data: dataForExport,
      fields: [
        'STT',
        'Thời gian',
        'Số lượng',
        'Doanh thu Voucher',
        'Doanh thu Buy in app',
        'Tổng doanh thu',
      ],
    })

    downloadCSV(csv, `doanh_thu_imuseum_${startDate}_${endDate}`)
  }

  return (
    <Card>
      <CardHeader
        component={() => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <CardHeader title="Doanh thu" />
            <div
              style={{
                margin: 16,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <DatePicker
                selected={moment(startDate, 'YYYY-MM-DD').toDate()}
                onChange={date => handleDateChange(setStartDate, date)}
                dateFormat="dd/MM/yyyy"
              />
              <ArrowForward />
              <DatePicker
                selected={moment(endDate, 'YYYY-MM-DD').toDate()}
                onChange={date => handleDateChange(setEndDate, date)}
                dateFormat="dd/MM/yyyy"
              />
              <ExportButton
                disabled={_.isEmpty(chartData)}
                onClick={handleExport}
              />
            </div>
          </div>
        )}
      />
      <CardContent>
        <ResponsiveContainer width="99%" height={400}>
          <AreaChart
            data={chartData}
            height={400}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend />
            <Area
              dot
              type="monotone"
              dataKey="total_sold"
              name="Số lượng "
              stroke="#404FA2"
              fill="#9ABED0"
              activeDot={{ r: 8 }}
            />
          </AreaChart>
        </ResponsiveContainer>
        {SCALES.map(id => (
          <Button
            key={id}
            variant={scale === id ? 'contained' : 'outlined'}
            color={scale === id ? 'primary' : 'default'}
            onClick={() => setScale(id)}
          >
            {id}
          </Button>
        ))}
      </CardContent>
    </Card>
  )
}

const ReportPage = () => {
  const [data, setData] = useState([])

  return (
    <div style={{ marginTop: 15 }}>
      <Grid container spacing={24}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <PurchaseChart setData={setData} />
        </Grid>
        <Grid item xs={12} sm={10} md={8} lg={6}>
          <PurchasePie data={data} />
        </Grid>
      </Grid>
    </div>
  )
}

export default ReportPage
