import React from 'react'
import { connect } from 'react-redux'
import { FormDataConsumer, Toolbar, SaveButton } from 'react-admin'
import { previewRecord as previewRecordAction } from '../actions/previewActions'

const SaveAndPreviewButton = ({ record, type, previewRecord, ...props }) => {
  return (
    <span
      onClick={async () => {
        props.handleSubmitWithRedirect() // Save the record

        const paths = {
          EXHIBITION: 'triển-lãm',
          EVENT: 'sự-kiện',
          NEWS: 'tin-tức-hoạt-động',
          BLOG: 'góc-chuyên-môn',
          MEDIA: 'media',
          EDUCATION_CREATIVE: 'giáo-dục-góc-sáng-tạo',
          EDUCATION_PRESENTATION: 'giáo-dục-thuyết-minh',
          collection: 'sưu-tập',
          display: 'trưng-bày',
        }

        let url

        if (type === 'announcement') {
          // Announcement preview needs to be handle separately as it does not have a specific route, but is rather
          // a general popup that shows over the website regardless of path.
          url = `${process.env.REACT_APP_WEB_PREVIEW_URL}?previewAnnouncementId=${record.id}`
        } else {
          url = `${process.env.REACT_APP_WEB_PREVIEW_URL}/${paths[type]}/${record._id}`
        }

        previewRecord(url, () => {
          const newTab = window.open(url, '_blank')
          newTab.focus()
        })
      }}
    >
      <SaveButton
        {...props}
        label="actions.save_and_preview"
        variant="flat"
        submitOnEnter={false}
        redirect={false}
      />
    </span>
  )
}

const PreviewToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
    <FormDataConsumer>
      {({ formData }) => {
        // We need to redirect the user to the page for the updated not current record.
        // For example, if the current articleType is NEWS, but the users changes it to BLOG, they need to be redirected
        // to the blog url rather than news url. In order to do this we need to get the data from the current form which
        // the FormDataConsumer component allows us to do.
        const type =
          formData._type ||
          formData.articleType ||
          props.record._type ||
          props.record.articleType ||
          props.resource

        return (
          <SaveAndPreviewButton record={props.record} type={type} {...props} />
        )
      }}
    </FormDataConsumer>
  </Toolbar>
)

export default connect(
  null,
  { previewRecord: previewRecordAction }
)(PreviewToolbar)
