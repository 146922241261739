import React from 'react'
import Card from '@material-ui/core/Card'
import CardTitle from '@material-ui/core/CardHeader'
import { translate } from 'react-admin'
import CountUp from 'react-countup'
import ScanIcon from 'react-icons/lib/fa/qrcode'
import { ArtifactIcon } from '../resource/artifact'
import { CategoryIcon } from '../resource/category'

const styles = {
  card: { borderLeft: 'solid 4px #ff9800', flex: 1, marginLeft: '1em' },
  icon: {
    float: 'right',
    width: 64,
    height: 64,
    padding: 16,
    color: '#ff9800',
  },
}

export const ArtifactCount = translate(({ value, translate }) => (
  <Card style={styles.card}>
    <ArtifactIcon style={styles.icon} />
    <CardTitle
      title={value ? value : <CountUp start={0} end={38} duration={3} />}
      subtitle={translate('dashboard.artifact_count')}
    />
  </Card>
))

export const CategoryCount = translate(({ value, translate }) => (
  <Card style={styles.card}>
    <CategoryIcon style={styles.icon} />
    <CardTitle
      title={value ? value : <CountUp start={0} end={4} duration={3} />}
      subtitle={translate('dashboard.category_count')}
    />
  </Card>
))

export const ScanCount = translate(({ value, translate }) => (
  <Card style={styles.card}>
    <ScanIcon style={styles.icon} />
    <CardTitle
      title={value ? value : <CountUp start={0} end={74} duration={3} />}
      subtitle={translate('dashboard.scan_count')}
    />
  </Card>
))
