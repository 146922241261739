import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import Active from '@material-ui/icons/NotificationsActive'
import Inactive from '@material-ui/icons/NotificationsOff'
import MapIcon from '@material-ui/icons/Map'
import { translate } from 'react-admin'
import compose from 'recompose/compose'
import {
  publishRecord as publishRecordAction,
  unpublishRecord as unpublishRecordAction,
} from '../actions/publishActions'
import {
  hasSubscription,
  addSubscription,
  removeSubscription,
} from '../util/subscription'
import { withRouter } from 'react-router-dom'

class PublishButton extends Component {
  state = {
    subscribed: false,
  }

  async componentDidMount() {
    const subscribed = await hasSubscription()
    this.setState({ subscribed })
  }

  handleSubscribe = async () => {
    await addSubscription()
    const subscribed = await hasSubscription()
    this.setState({ subscribed })
  }

  handleUnsubscribe = async () => {
    await removeSubscription()
    const subscribed = await hasSubscription()
    this.setState({ subscribed })
  }

  render() {
    const { history, translate } = this.props

    return (
      <div>
        <Button
          color="primary"
          onClick={() => history.push('/rentaldevicemap')}
        >
          <MapIcon />
          {translate('labels.map')}
        </Button>
        {!this.state.subscribed ? (
          <Button color="primary" onClick={this.handleSubscribe}>
            <Inactive />
            {translate('actions.enable_alerts')}
          </Button>
        ) : (
          <Button color="primary" onClick={this.handleUnsubscribe}>
            <Active />
            {translate('actions.alerts_enabled')}
          </Button>
        )}
      </div>
    )
  }
}

PublishButton.propTypes = {
  record: PropTypes.object,
  publishRecord: PropTypes.func,
  translate: PropTypes.func,
  resource: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
}

const enhance = compose(
  withRouter,
  translate,
  connect(
    null,
    {
      publishRecord: publishRecordAction,
      unpublishRecord: unpublishRecordAction,
    }
  )
)

export default enhance(PublishButton)
