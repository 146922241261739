import React from 'react'
import compose from 'recompose/compose'
import { translate, addField, FieldTitle } from 'react-admin'
import { DateTimePicker } from 'material-ui-pickers'
import MomentUtils from 'material-ui-pickers/utils/moment-utils'
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider'
import moment from 'moment'
import 'moment/locale/vi'

moment.locale('vi')

class DateTimeInput extends React.Component {
  onChange(date) {
    this.props.input.onChange(date)
    this.props.input.onBlur()
  }

  render() {
    const {
      input,
      translate,
      options,
      label,
      source,
      resource,
      isRequired,
      className,
      meta,
    } = this.props

    const { touched, error } = meta

    return (
      <div className="picker">
        <MuiPickersUtilsProvider
          utils={MomentUtils}
          moment={moment}
          locale="vi"
        >
          <DateTimePicker
            {...options}
            label={
              <FieldTitle
                label={label}
                source={source}
                resource={resource}
                isRequired={isRequired}
              />
            }
            format="LLL"
            ampm={true}
            clearable={true}
            clearLabel={translate('ra.action.delete')}
            cancelLabel={translate('ra.action.cancel')}
            okLabel={translate('ra.action.save')}
            margin="normal"
            error={!!(touched && error)}
            helperText={touched && error}
            ref={node => {
              this.picker = node
            }}
            className={className}
            value={input.value ? input.value : null}
            onChange={date => this.onChange(date)}
          />
        </MuiPickersUtilsProvider>
      </div>
    )
  }
}

const enhance = compose(
  translate,
  addField
)

export default enhance(DateTimeInput)
