import React, { Fragment } from 'react'
import {
  CardActions,
  CreateButton,
  RefreshButton,
  WithPermissions,
  ExportButton,
} from 'react-admin'

import { requireOne } from '../util/permissions'

const ListAcions = ({
  basePath,
  displayedFilters,
  filters,
  filterValues,
  resource,
  showFilter,
  currentSort,
  exporter,
}) => (
  <CardActions>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['admin'])(permissions) ? (
          <Fragment>
            <CreateButton basePath={basePath} />
            <ExportButton
              maxResults={2000}
              resource={resource}
              sort={currentSort}
              filter={filterValues}
              exporter={exporter}
            />
          </Fragment>
        ) : null
      }
    </WithPermissions>
    <RefreshButton />
  </CardActions>
)

export default ListAcions
