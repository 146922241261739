import jwtDecode from 'jwt-decode'
import set from 'lodash/set'

export default () => context => {
  const token = localStorage.getItem('feathers-jwt')
  const decoded = jwtDecode(token)
  const museumId = decoded.museum

  if (museumId) {
    set(context.data, 'museum', museumId)
  }
}
