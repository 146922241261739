import React from 'react'
import { ImageField } from 'react-admin'
import { Draggable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import _ from 'lodash'
import PropTypes from 'prop-types'

const DraggableImageField = props => {
  let draggableId, index

  if (props.record._id) {
    draggableId = props.record._id
    index = _.findIndex(props.images, { _id: draggableId })
  } else {
    draggableId = props.record.uri
    index = _.findIndex(props.images, { uri: draggableId })
  }

  return (
    <Draggable draggableId={draggableId} index={index}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
        >
          <ImageField {...props} />
        </div>
      )}
    </Draggable>
  )
}

const mapStateToProps = (state, props) => ({
  images: state.form['record-form'].values[props.parentSource],
})

DraggableImageField.propTypes = {
  parentSource: PropTypes.string.isRequired, // Must be the same as the "source" prop of the parent DroppableImageInput component.
}

export default connect(mapStateToProps)(DraggableImageField)
