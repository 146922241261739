import React from 'react'
import MuiGridList from '@material-ui/core/GridList'
import { ReferenceField, EditButton } from 'react-admin'
import get from 'lodash.get'
import { withStyles } from '@material-ui/core/styles'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'

import placeholder from '../../images/placeholder.png'
import StatusBadge from '../../components/StatusBadge'

const styles = {
  root: {
    margin: '-2px',
  },
  gridList: {
    width: '100%',
    margin: 0,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)',
  },
  badgeImage: {
    width: '100%',
    objectFit: 'contain',
  },
}

const AuthorField = ({ record }) => {
  return <span style={{ color: 'white' }}>{get(record, 'name', null)}</span>
}

const GridList = ({ classes, ids, data, basePath }) => (
  <div className={classes.root}>
    <MuiGridList cellHeight={180} cols={4} className={classes.gridList}>
      {ids.map(id => (
        <GridListTile key={id}>
          <StatusBadge type="image" record={data[id]}>
            <img
              className={classes.badgeImage}
              src={
                get(data[id], 'images[0].uri')
                  ? data[id].images[0].uri + '/300'
                  : placeholder
              }
              alt=""
            />
          </StatusBadge>
          <GridListTileBar
            className={classes.titleBar}
            title={data[id].name}
            subtitle={
              <ReferenceField
                basePath="/author"
                record={data[id]}
                source="authors[0]"
                reference="author"
                allowEmpty
              >
                <AuthorField />
              </ReferenceField>
            }
            actionIcon={
              <EditButton basePath={basePath} record={data[id]} label="" />
            }
          />
        </GridListTile>
      ))}
    </MuiGridList>
  </div>
)

export default withStyles(styles)(GridList)
