import React from 'react'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { translate } from 'react-admin'
import { stringify } from 'query-string'

import { ArtifactIcon } from '../artifact'

const LinkToRelatedArtifacts = ({ record, translate }) => (
  <Button
    color="primary"
    component={Link}
    to={{
      pathname: '/artifact',
      search: stringify({
        page: 1,
        perPage: 12,
        filter: JSON.stringify({ author: record.id }),
      }),
    }}
  >
    <ArtifactIcon />
    {translate('resources.author.fields.artifacts')}
  </Button>
)

export default translate(LinkToRelatedArtifacts)
