import React, { Fragment, useState, useEffect } from 'react'
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  List,
  LongTextInput,
  SelectField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  WithPermissions,
  BulkDeleteWithConfirmButton,
} from 'react-admin'
import Grid from '@material-ui/core/Grid'
import {
  MuseumSelector,
  ReviewActions,
  PushButton,
  TextWithIndicator,
} from '../../components'
import RentalDeviceListMap from '../../components/RentalDeviceListMap'
import { requireOne } from '../../util/permissions'
import RentaldeviceActions from '../../components/ListActions'
import Button from '@material-ui/core/Button'
import MapIcon from '@material-ui/icons/Map'
import { withRouter } from 'react-router-dom'
import { useInterval } from 'react-use'
import { app } from '../../feathersClient'
import useSound from 'use-sound'
import alert from './alert.wav'

export {
  default as RentaldeviceIcon,
} from '@material-ui/icons/PermDeviceInformation'

const RentaldeviceTitle = ({ record }) => {
  return <span>{record ? `${record.name}` : ''}</span>
}

const getAlerted = async () => {
  const res = await app
    .service('rentaldevice')
    .find({ query: { alerted: true } })
  return res.data.map(device => device._id)
}

export const RentaldeviceList = props => {
  const [alerted, setAlerted] = useState([])
  const [playAlert] = useSound(alert)

  useEffect(() => {
    async function setAlreadyAlerted() {
      const alerted = await getAlerted()
      setAlerted(alerted)
    }
    setAlreadyAlerted()
  }, [])

  useInterval(
    () =>
      (async () => {
        const newAlerts = await getAlerted()
        let hasNewAlert = false
        newAlerts.forEach(alert => {
          if (!alerted.includes(alert)) {
            hasNewAlert = true
          }
        })

        if (hasNewAlert) {
          playAlert()
          setTimeout(() => {
            window.alert('Cảnh báo! Thiết bị!')
            window.location.reload()
          }, 2000)
        }
      })(),
    10000
  )

  return (
    <WithPermissions>
      {({ permissions }) => (
        <Fragment>
          <List
            {...props}
            actions={
              <RentaldeviceActions
                {...props}
                disableCreate={!requireOne(['admin'])(permissions)}
                extraActions={<PushButton />}
              />
            }
            bulkActionButtons={
              requireOne(['admin'])(permissions) ? (
                <BulkDeleteWithConfirmButton />
              ) : (
                false
              )
            }
          >
            <Datagrid>
              <TextWithIndicator source="name" type="alert" />
              <SelectField
                source="status"
                choices={[
                  { id: 'HOME', name: 'resources.rentaldevice.statuses.home' },
                  {
                    id: 'RENTED',
                    name: 'resources.rentaldevice.statuses.rented',
                  },
                  {
                    id: 'REPAIR',
                    name: 'resources.rentaldevice.statuses.repair',
                  },
                  { id: 'LOST', name: 'resources.rentaldevice.statuses.lost' },
                  {
                    id: 'OTHER',
                    name: 'resources.rentaldevice.statuses.other',
                  },
                ]}
              />
              <TextField source="installedVersion" />
              <TextField source="battery" />
              <TextField source="location.block" />
              <DateField locales="vi-VN" source="lastStatusUpdate" showTime />
              {/* TODO: show associated room */}
              <EditButton basePath="/rentaldevice" />
            </Datagrid>
          </List>
          <div style={{ marginTop: '16px' }} />
          <Grid container>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <RentalDeviceListMap level={2} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <RentalDeviceListMap level={1} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <RentalDeviceListMap level={0} />
            </Grid>
          </Grid>
        </Fragment>
      )}
    </WithPermissions>
  )
}

const CommonFormTab = withRouter(({ ...props }) => (
  <WithPermissions>
    {({ permissions }) => (
      <SimpleForm {...props}>
        <MuseumSelector {...props} />
        <TextInput source="name" />
        {requireOne(['admin'])(permissions) ? (
          <TextInput source="uuid" fullWidth />
        ) : null}
        <TextInput source="cloudData" fullWidth={true} multiline={true} />
        <SelectInput
          source="status"
          choices={[
            { id: 'HOME', name: 'resources.rentaldevice.statuses.home' },
            { id: 'RENTED', name: 'resources.rentaldevice.statuses.rented' },
            { id: 'REPAIR', name: 'resources.rentaldevice.statuses.repair' },
            { id: 'LOST', name: 'resources.rentaldevice.statuses.lost' },
            { id: 'OTHER', name: 'resources.rentaldevice.statuses.other' },
          ]}
        />
        <DateField source="lastStatusUpdate" showTime />
        <TextField source="installedVersion" />
        <TextField source="battery" />
        <TextField source="location.block" />
        <Button
          color="primary"
          onClick={() =>
            props.history.push(`/rentaldevicemap?id=${props.record.id}`)
          }
        >
          <MapIcon />
          &nbsp;Xem trên bản đồ
        </Button>
        <LongTextInput source="notes" />
      </SimpleForm>
    )}
  </WithPermissions>
))

export const RentaldeviceEdit = props => (
  <WithPermissions>
    {({ permissions }) => (
      <Edit
        {...props}
        title={<RentaldeviceTitle />}
        actions={
          <ReviewActions
            disableDelete={!requireOne(['admin'])(permissions)}
            allowPublish={[]}
          />
        }
      >
        <CommonFormTab redirect="list" />
      </Edit>
    )}
  </WithPermissions>
)

export const RentaldeviceCreate = props => (
  <Create title="titles.create_rentaldevice" {...props}>
    <CommonFormTab redirect="list" />
  </Create>
)
