import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import { Fields } from 'redux-form'

class DimensionInput extends Component {
  state = {
    dimensions: {
      width: null,
      height: null,
      depth: null,
    },
    error: null,
  }

  componentDidMount() {
    const w = this.props.dimensions.width.input.value
    const h = this.props.dimensions.height.input.value
    const d = this.props.dimensions.depth.input.value
    const dimensions = { w, h, d }

    this.setState(dimensions)
  }

  handleChange = field => event => {
    event.target.value = event.target.value * 10 // Convert 'cm' to 'mm'.
    field.input.onChange(event)
  }

  displayValue = field => field.input.value / 10 // Convert 'mm' to 'cm'.

  render() {
    const { height, width, depth } = this.props.dimensions

    return (
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
          }}
        >
          <TextField
            style={{ width: '7em' }}
            label="Chiều cao"
            type="number"
            onChange={this.handleChange(height)}
            value={this.displayValue(this.props.dimensions.height)}
          />
          <p style={{ color: 'rgba(135, 136, 140, 0.3)' }}> x </p>
          <TextField
            style={{ width: '7em', paddingLeft: '1em' }}
            label="Chiều rộng"
            type="number"
            onChange={this.handleChange(width)}
            value={this.displayValue(this.props.dimensions.width)}
          />
          <p style={{ color: 'rgba(135, 136, 140, 0.3)' }}> x </p>
          <TextField
            style={{ width: '7em', paddingLeft: '1em' }}
            label="Chiều sâu"
            type="number"
            onChange={this.handleChange(depth)}
            value={this.displayValue(this.props.dimensions.depth)}
          />
          <p style={{ color: 'rgba(135, 136, 140, 0.3)' }}> cm</p>
        </div>
      </div>
    )
  }
}

const withFields = () => (
  <Fields
    names={['dimensions.width', 'dimensions.height', 'dimensions.depth']}
    component={DimensionInput}
  />
)

export default withFields
