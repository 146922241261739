import React, { Fragment, Component } from 'react'
import _ from 'lodash'

import { withStyles } from '@material-ui/core/styles'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import QRCodeIcon from 'react-icons/lib/fa/qrcode'

const styles = {
  qrCodeIcon: {
    fontSize: '25px',
  },
  qrCodeImg: {
    width: '200px',
    margin: '0 auto',
  },
  dialogTitle: {
    textAlign: 'center',
    width: '400px',
  },
}

class QRCodeField extends Component {
  state = {
    dialogOpen: false,
  }

  openDialog = () => this.setState({ dialogOpen: true })

  closeDialog = () => this.setState({ dialogOpen: false })

  render() {
    const qrCodeUri = _.get(this.props, 'record.qrCode.uri')
    const { classes } = this.props

    return (
      <Fragment>
        <Button onClick={this.openDialog}>
          <QRCodeIcon className={classes.qrCodeIcon} />
        </Button>
        <Dialog open={this.state.dialogOpen} onClose={this.closeDialog}>
          <DialogTitle className={classes.dialogTitle}>
            {this.props.record.code}
          </DialogTitle>
          <img alt="QR Code" src={qrCodeUri} className={classes.qrCodeImg} />
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

export default withStyles(styles)(QRCodeField)
