import vietnameseMessages from 'ra-language-vietnamese'

export default {
  ...vietnameseMessages,
  ra: {
    ...vietnameseMessages.ra,
    input: {
      ...vietnameseMessages.ra.input,
      embedded_array: {
        add: 'Thêm vào',
        remove: 'Xóa bỏ',
      },
    },
    message: {
      ...vietnameseMessages.ra.message,
      delete_title: 'Xóa %{name}',
    },
  },
  resources: {
    feedback: {
      name: 'Feedback',
    },
    beacon: {
      name: 'Beacon',
      createdAt: 'Tạo',
      updatedAt: 'Đã cập nhật',
      publish_success: 'Beacon xuất bản',
      publish_failure: 'Lỗi khi xuất bản beacon',
      unpublish_success: 'Beacon không xuất bản',
      unpublish_failure: 'Lỗi khi không xuất bản beacon',
    },
    user: {
      name: 'Người dùng',
      fields: {
        email: 'E-mail',
        name: 'Tên',
        password: 'Mật khẩu',
        museum: 'Viện bảo tàng',
        role: 'Vai trò',
        createdAt: 'Tạo',
        updatedAt: 'Đã cập nhật',
      },
    },
    museum: {
      name: 'Viện bảo tàng',
      fields: {
        name: 'Tên',
        ranking: 'Xếp hạng',
        description: 'Miêu tả',
        openTime: 'Giờ mở cửa',
        closeTime: 'Giờ đóng cửa',
        workingDay: 'Ngày mở cửa',
        phoneNumber: 'Số điện thoại',
        createdAt: 'Tạo',
        updatedAt: 'Đã cập nhật',
        images: 'Hình ảnh',
        video: 'Video',
        location: 'Địa chỉ',
      },
      notification: {
        publish_success: 'Viện bảo tàng xuất bản',
        publish_failure: 'Lỗi khi xuất bản viện bảo tàng',
        unpublish_success: 'Viện bảo tàng không xuất bản',
        unpublish_failure: 'Lỗi khi không xuất bản viện bảo tàng',
      },
    },
    category: {
      name: 'Chất liệu', // Translation customised for VN Fine Arts Museum
      fields: {
        museum: 'Viện bảo tàng',
        name: 'Tên',
        description: 'Miêu tả',
        createdAt: 'Tạo',
        updatedAt: 'Đã cập nhật',
        images: 'Hình ảnh',
        video: 'Video',
        artifacts: 'Hiện vật',
        diagram: 'Bản đồ vị trí',
      },
      notification: {
        // Translation of resource name customized for VN Fine Arts Museum
        publish_success: 'chất liệu xuất bản',
        publish_failure: 'Lỗi khi xuất bản chất liệu',
        unpublish_success: 'Chất liệu không xuất bản',
        unpublish_failure: 'Lỗi khi không xuất bản chất liệu',
      },
    },
    author: {
      name: 'Tác giả',
      fields: {
        name: 'Tên',
        birthday: 'Ngày sinh',
        deathday: 'Ngày mất',
        description: 'Miêu tả',
        createdAt: 'Tạo',
        updatedAt: 'Đã cập nhật',
        images: 'Hình ảnh',
        video: 'Video',
        artifacts: 'Hiện vật',
      },
      notification: {
        publish_success: 'Tác giả xuất bản',
        publish_failure: 'Lỗi khi xuất bản tác giả',
        unpublish_success: 'Tác giả không xuất bản',
        unpublish_failure: 'Lỗi khi không xuất bản tác giả',
      },
    },
    tag: {
      search: 'Tìm kiếm thẻ',
      name: 'Thẻ',
      fields: {
        name: 'Tên',
      },
      notification: {
        publish_success: 'Thẻ xuất bản',
        publish_failure: 'Lỗi khi xuất bản thẻ',
        unpublish_success: 'Thẻ không xuất bản',
        unpublish_failure: 'Lỗi khi không xuất bản thẻ',
      },
    },
    apptag: {
      search: 'Tìm kiếm thẻ app',
      name: 'Thẻ app',
      fields: {
        name: 'Tên',
        ranking: 'Xếp hạng',
      },
      notification: {
        publish_success: 'Thẻ app xuất bản',
        publish_failure: 'Lỗi khi xuất bản thẻ app',
        unpublish_success: 'Thẻ app không xuất bản',
        unpublish_failure: 'Lỗi khi không xuất bản thẻ app',
      },
    },
    undefined: {
      fields: {
        apptags: 'Thẻ App',
      },
    },
    artifact: {
      search: 'Tìm kiếm hiện vật',
      name: 'Hiện vật',
      fields: {
        category: 'Chất liệu',
        authors: 'Tác giả',
        name: 'Tên',
        description: 'Miêu tả',
        createdAt: 'Tạo',
        updatedAt: 'Đã cập nhật',
        images: 'Hình ảnh',
        audio: 'Âm thanh',
        video: 'Video',
        status: 'Tình trạng',
        storageLocation: 'Khu vực lưu trữ',
        tags: 'Thẻ',
        apptags: 'Thẻ App',
        dimension: 'Kích thước',
        year: 'Năm khởi tạo',
        weight: 'Trọng lượng (g)',
        publishedTo: 'Xuất bản để',
        referenceLinks: 'Nguồn Liên Kết',
        publishedLanguages: 'Ngôn ngữ xuất bản',
      },
      statuses: {
        on_display: 'Trưng bày',
        in_storage: 'Trong lưu trữ',
        on_loan: 'Cho mượn',
        sold: 'Đã bán',
      },
      notification: {
        publish_success: 'Hiện vật xuất bản',
        publish_failure: 'Lỗi khi xuất bản hiện vật',
        unpublish_success: 'Hiện vật không xuất bản',
        unpublish_failure: 'Lỗi khi không xuất bản hiện vật',
      },
    },
    exhibition: {
      search: 'Triển lãm tìm kiếm',
      name: 'Triển lãm',
      fields: {
        museum: 'Viện bảo tàng',
        name: 'Tên',
        organizer: 'Nhà tổ chức',
        description: 'Miêu tả',
        images: 'Hình ảnh',
        startDate: 'Ngày bắt đầu',
        endDate: 'Ngày cuối',
        location: 'Vị trí',
        artifacts: 'Hiện vật',
        _collection: 'Bộ sưu tập',
        _type: 'Loại triển lãm',
        publishedLanguages: 'Ngôn ngữ xuất bản',
      },
      enums: {
        _type: {
          EVENT: 'Sự kiện',
          EXHIBITION: 'Triển lãm',
        },
      },
      notification: {
        publish_success: 'Triển lãm xuất bản',
        publish_failure: 'Lỗi khi xuất bản triển lãm',
        unpublish_success: 'Triển lãm không xuất bản',
        unpublish_failure: 'Lỗi khi không xuất bản triển lãm',
      },
    },
    collection: {
      name: 'Bộ sưu tập',
      fields: {
        museum: 'Viện bảo tàng',
        ranking: 'Xếp hạng',
        name: 'Tên',
        description: 'Miêu tả',
        images: 'Hình ảnh',
        artifacts: 'Hiện vật',
        recommended: 'Đề nghị',
        purchasePrice: 'Giá mua',
        publishedLanguages: 'Ngôn ngữ xuất bản',
      },
      notification: {
        publish_success: 'Bộ sưu tập xuất bản',
        publish_failure: 'Lỗi khi xuất bản bộ sưu tập',
        unpublish_success: 'Bộ sưu tập không xuất bản',
        unpublish_failure: 'Lỗi khi không xuất bản bộ sưu tập',
      },
    },
    display: {
      name: 'Trưng bày',
      fields: {
        museum: 'Viện bảo tàng',
        ranking: 'Xếp hạng',
        name: 'Tên',
        description: 'Miêu tả',
        images: 'Hình ảnh',
        artifacts: 'Hiện vật',
        blocks: 'Khu',
        audio: 'Hướng dẫn âm thanh',
        publishedLanguages: 'Ngôn ngữ xuất bản',
      },
      notification: {
        publish_success: 'Bộ sưu tập xuất bản',
        publish_failure: 'Lỗi khi xuất bản bộ sưu tập',
        unpublish_success: 'Bộ sưu tập không xuất bản',
        unpublish_failure: 'Lỗi khi không xuất bản bộ sưu tập',
      },
    },
    tour: {
      name: 'Tour',
      fields: {
        museum: 'Viện bảo tàng',
        ranking: 'Xếp hạng',
        name: 'Tên',
        images: 'Hình ảnh',
        artifacts: 'Hiện vật',
        audio: 'Hướng dẫn âm thanh',
        publishedLanguages: 'Ngôn ngữ xuất bản',
      },
      notification: {
        publish_success: 'Tour xuất bản',
        publish_failure: 'Lỗi khi xuất bản tour',
        unpublish_success: 'Tour không xuất bản',
        unpublish_failure: 'Lỗi khi không xuất bản tour',
      },
    },
    'storage-location': {
      name: 'Khu vực lưu trữ',
      fields: {
        name: 'Tên',
        description: 'Miêu tả',
      },
    },
    floor: {
      name: 'Tầng',
      fields: {
        name: 'Tên',
        number: 'Con số',
        diagram: 'Bản đồ',
        legend: 'Chi tiết',
      },
      notification: {
        publish_success: 'Tầng xuất bản',
        publish_failure: 'Lỗi khi xuất bản tầng',
        unpublish_success: 'Tầng không xuất bản',
        unpublish_failure: 'Lỗi khi không xuất bản tầng',
      },
    },
    block: {
      name: 'Khu',
      fields: {
        name: 'Tên',
        description: 'Mô tả',
        diagram: 'Bản đồ',
        floor: 'Tầng',
        images: 'Hình ảnh',
        audio: 'Âm thanh',
        transcript: 'Transcript',
      },
      notification: {
        publish_success: 'Khu xuất bản',
        publish_failure: 'Lỗi khi xuất bản khu',
        unpublish_success: 'Khu không xuất bản',
        unpublish_failure: 'Lỗi khi không xuất bản khu',
      },
    },
    news: {
      search: 'Tìm kiếm news',
      name: 'Tin tức',
      fields: {
        name: 'Tên',
        images: 'Hình ảnh',
        createdAt: 'Thời gian tạo',
        updatedAt: 'Thời gian cập nhật',
        articleType: 'Loại tin tức',
        youtubeLink: 'URL YouTube',
        referenceLinks: 'Nguồn Liên Kết',
        attachments: 'Đính kèm',
        publishedLanguages: 'Ngôn ngữ xuất bản',
      },
      enums: {
        articleType: {
          NEWS: 'Tin tức',
          BLOG: 'Góc chuyên môn',
          MEDIA: 'Media',
          EDUCATION_CREATIVE: 'Giáo Dục: Góc sáng tạo',
          EDUCATION_PRESENTATION: 'Giáo Dục: Thuyết minh',
        },
      },
      notification: {
        publish_success: 'Tác giả xuất bản',
        publish_failure: 'Lỗi khi xuất bản tin tức',
        unpublish_success: 'Tác giả không xuất bản',
        unpublish_failure: 'Lỗi khi không xuất bản tin tức',
      },
    },
    announcement: {
      search: 'Triển lãm thông báo',
      name: 'Thông báo',
      fields: {
        museum: 'Viện bảo tàng',
        active: 'Bật',
        name: 'Tên thông báo',
        image: 'Hình ảnh đại diện',
        startDate: 'Ngày bắt đầu để hiển thị thông báo',
        endDate: 'Ngày kết thúc để tắt thông báo',
        publishedLanguages: 'Ngôn ngữ xuất bản',
      },
      notification: {
        publish_success: 'Triển lãm xuất bản',
        publish_failure: 'Lỗi khi xuất bản triển lãm',
        unpublish_success: 'Triển lãm không xuất bản',
        unpublish_failure: 'Lỗi khi không xuất bản triển lãm',
      },
    },
    voucher: {
      search: 'Tìm kiếm voucher',
      name: 'Voucher',
      fields: {
        count: 'Số lượng',
        duration: 'Thời gian',
        used: 'Đã sử dụng',
        createdAt: 'Thời gian tạo',
        usedAt: 'Thời gian sử dụng',
        museum: 'Viện bảo tàng',
        purchaseMethod: 'Loại mua',
      },
    },
    rentaldevice: {
      name: 'Cho thuê thiết bị',
      fields: {
        name: 'Tên',
        status: 'Trạng thái',
        notes: 'Ghi chú',
        battery: 'Pin',
        'location.block': 'Khu đã biết gần đây nhất',
        lastStatusUpdate: 'Cập nhật trạng thái gần đây nhất',
      },
      statuses: {
        home: 'Ở trụ sở chính',
        rented: 'Đã thuê',
        repair: 'Sửa chữa',
        lost: 'Còn thiếu',
        other: 'Khác',
      },
      map: 'Xem trên bản đồ',
    },
    turnover: {
      report: 'Báo cáo',
    },
  },
  titles: {
    create_tag: 'Tạo thẻ',
    create_apptag: 'Tạo thẻ app',
    create_user: 'Tạo người dùng',
    edit_user: '',
    create_museum: 'Tạo bảo tàng',
    edit_museum: '',
    create_category: 'Tạo danh mục',
    create_artifact: 'Tạo hiện vật',
    create_author: 'Tạo tác giả',
    create_exhibition: 'Tạo ra triển lãm',
    create_storageLocation: 'Tạo khu vực lưu trữ',
    create_floor: 'Tạo tầng mới',
    create_block: 'Tạo khu mới',
    choose_block: 'Chọn khu',
    choose_floor: 'Chọn tầng',
    create_collection: 'Tạo bộ sưu tập',
    create_display: 'Tạo trưng bày',
    create_news: 'Tạo tin tức',
    create_vouchers: 'Tạo voucher',
    create_beacons: 'Tạo beacon',
    create_announcement: 'Tạo thông báo',
  },
  labels: {
    location: 'Vị trí',
    address: 'Địa chỉ',
    name: 'Tên',
    description: 'Miêu tả',
    diagram: 'Bản đồ',
    map: 'Bản đồ',
    legend: 'Chi tiết',
    reset_password: 'Đặt lại mật khẩu',
    video: 'Video',
    expert_recommended: 'Chuyên gia khuyến nghị',
    isFreeAccess: 'Mở khóa miễn phí hiện vật này',
    organizer: 'Tổ chức',
    search_artifact: 'Tìm kiếm hiện vật',
    organize_borow: 'Tổ chức mượn',
    buyer: 'Người mua',
    birth_year: 'Năm sinh',
    birth_month: 'Tháng sinh',
    birth_day: 'Ngày sinh',
    content: 'Nội dung thông báo',
    death_year: 'Năm mất',
    death_month: 'Tháng mất',
    death_day: 'Ngày mất',
    museum: 'Bảo tàng',
    public: 'Công khai',
    block: 'Khu',
    beacon: 'Beacon',
    time: 'Thời gian (giờ, phút)',
    workingDay: 'Ngày mở cửa',
    audio: 'Âm thanh',
    title: 'Tiêu đề',
    subTitle: 'Phụ đề',
    hour: 'Giờ',
    width: 'Chiều rộng(cm)',
    height: 'Chiều cao(cm)',
    depth: 'Chiều sâu(cm)',
    EVENT: 'Sự kiện',
    website: 'Trang mạng',
    app: 'iMuseum App',
    transcript: 'Transcript',
    hq_images: 'Hình ảnh chất lượng cao (kích thước tối đa 60MB)',
    code: 'Code',
  },
  roles: {
    user: 'Người dùng',
    admin: 'Quản trị viên',
    director: 'Giám đốc',
    staff: 'Nhân viên bảo tàng',
    vinmasStaff: 'Nhân viên Vinmas',
  },
  errors: {
    closeTimeBeforeOpen: 'Giờ đóng cửa phải sau giờ mở cửa',
  },
  dashboard: {
    artifact_count: 'Số lượng hiện vật',
    category_count: 'Số hạng mục',
    scan_count: 'Tổng số lần quét',
  },
  vietnamese: 'Tiếng Việt',
  english: 'Tiếng Anh',
  french: 'Tiếng Pháp',
  chinese: 'Tiếng Trung',
  japanese: 'Tiếng Nhật',
  korean: 'Tiếng Hàn',
  spanish: 'Tiếng Tây Ban Nha',
  italian: 'Tiếng Ý',
  german: 'Tiếng Đức',
  general: 'Chung',
  languages: {
    vi: 'Tiếng Việt',
    en: 'Tiếng Anh',
    fr: 'Tiếng Pháp',
    zh: 'Tiếng Trung',
    ja: 'Tiếng Nhật',
    ko: 'Tiếng Hàn',
    es: 'Tiếng Tây Ban Nha',
    it: 'Tiếng Ý',
    de: 'Tiếng Đức',
  },
  actions: {
    publish: 'Xuất bản',
    unpublish: 'Không xuất bản',
    update: 'Cập nhật',
    save_and_preview: 'Lưu và xem trước',
    redirecting: 'Bạn đang chuyển hướng đến %{url}…',
    enable_alerts: 'Bật thông báo',
    alerts_enabled: 'Thông báo được bật',
    make_free: 'Bật dùng thử',
    un_make_free: 'Hủy bỏ dùng thử'
  },
}
