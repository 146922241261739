import React from 'react'
import GoogleMapReact from 'google-map-react'
import MuseumIcon from 'react-icons/lib/md/place'
import logo from '../images/logo.png'

const MapField = ({
  style,
  record = { location: { lng: 27.0278, lat: 105.8342 } },
}) => (
  <div style={{ flex: 1, width: '100%', height: 500, ...style }}>
    <GoogleMapReact
      center={[record.location.lat, record.location.lng]}
      bootstrapURLKeys={{
        key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        language: 'vi',
      }}
      defaultZoom={16}
      options={{ disableDefaultUI: true }}
    >
      <MuseumIcon
        src={logo}
        style={{ width: 30, height: 30, color: 'red' }}
        lat={record.location.lat}
        lng={record.location.lng}
        text={record.name}
      />
    </GoogleMapReact>
  </div>
)

export default MapField
