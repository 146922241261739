// Get the users museum id
import jwtDecode from 'jwt-decode'
import _ from 'lodash'

export default () => {
  const token = localStorage.getItem('feathers-jwt')

  if (!token) {
    return undefined
  }

  try {
    const decoded = jwtDecode(token)
    return _.get(decoded, 'museum')
  } catch (e) {
    // Could not decode token
    return undefined
  }
}
