import React from 'react'
import {
  BulkDeleteWithConfirmButton,
  CheckboxGroupInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  List,
  required,
  SimpleForm,
  TextInput,
  translate,
  WithPermissions,
} from 'react-admin'
import { ReviewActions, TextWithIndicator, Toolbar } from '../../components'
import { requireOne } from '../../util/permissions'
import FATags from 'react-icons/lib/fa/tags'
import TagActions from '../../components/ListActions'

export const TagIcon = props => <FATags size={24} {...props} />

export const TagFilter = props => (
  <Filter {...props}>
    <TextInput label="resources.tag.search" source="name[$search]" alwaysOn />
  </Filter>
)

export const TagList = props => (
  <List
    {...props}
    filters={<TagFilter />}
    bulkActionButtons={<BulkDeleteWithConfirmButton />}
    actions={<TagActions />}
  >
    <Datagrid>
      <TextWithIndicator label="labels.name" source="name" />
      <EditButton basePath="/tag" />
    </Datagrid>
  </List>
)

const langs = process.env.REACT_APP_SUPPORTED_LANGUAGES.split(',')
const baseLang = process.env.REACT_APP_BASE_LANGUAGE

export const TagCreate = translate(props => (
  <Create title="titles.create_tag" {...props}>
    <SimpleForm redirect="list">
      <WithPermissions>
        {({ permissions }) =>
          requireOne(['admin', 'director'])(permissions) ? (
            <CheckboxGroupInput
              source="publishedTo"
              choices={[{ id: 'WEBSITE', name: 'labels.website' }]}
              optionText="name"
              optionValue="id"
              label="resources.artifact.fields.publishedTo"
            />
          ) : null
        }
      </WithPermissions>
      {langs.map(lang => (
        <TextInput
          key={lang}
          label={
            lang === baseLang
              ? 'labels.name'
              : `${props.translate('labels.name')} ${props.translate(
                  `languages.${lang}`
                )}`
          }
          source={lang === baseLang ? 'name' : `i18n.${lang}.name`}
          validate={lang === baseLang ? [required()] : []}
          fullWidth
        />
      ))}
    </SimpleForm>
  </Create>
))

export const TagEdit = translate(props => (
  <Edit
    title="titles.create_tag"
    {...props}
    actions={<ReviewActions allowPublish={['admin', 'director']} />}
  >
    <SimpleForm toolbar={<Toolbar />}>
      <WithPermissions>
        {({ permissions }) =>
          requireOne(['admin', 'director'])(permissions) ? (
            <CheckboxGroupInput
              source="publishedTo"
              choices={[{ id: 'WEBSITE', name: 'labels.website' }]}
              optionText="name"
              optionValue="id"
              label="resources.artifact.fields.publishedTo"
            />
          ) : null
        }
      </WithPermissions>
      {langs.map(lang => (
        <TextInput
          key={lang}
          label={
            lang === baseLang
              ? 'labels.name'
              : `${props.translate('labels.name')} ${props.translate(
                  `languages.${lang}`
                )}`
          }
          source={lang === baseLang ? 'name' : `i18n.${lang}.name`}
          validate={lang === baseLang ? [required()] : []}
          fullWidth
        />
      ))}
    </SimpleForm>
  </Edit>
))
