import React from 'react'
import {
  BulkDeleteWithConfirmButton,
  CheckboxGroupInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  ImageField,
  ImageInput,
  List,
  LongTextInput,
  ReferenceArrayInput,
  required,
  FormInput,
  FileInput,
  SimpleForm,
  NumberInput,
  TextField,
  TextInput,
} from 'react-admin'
import _ from 'lodash'
import placeholder from '../../images/placeholder.png'
import {
  AutocompleteArrayInput,
  AudioField,
  MultiInput,
  MuseumSelector,
  Toolbar,
  ReviewActions,
} from '../../components'
import StatusBadge from '../../components/StatusBadge'
import TourActions from '../../components/ListActions'
import { MdTour } from 'react-icons-4/md'

export const TourIcon = props => <MdTour size={24} {...props} />

const baseLang = process.env.REACT_APP_BASE_LANGUAGE

const CroppedImageField = props => {
  const record = props.record
  record.croppedImage = _.get(record, 'images.0.uri')
    ? record.images[0].uri
    : placeholder

  const img = <ImageField {...props} record={record} source="croppedImage" />

  return (
    <StatusBadge type="smallImage" record={record}>
      {img}
    </StatusBadge>
  )
}

export const TourList = props => (
  <List
    {...props}
    bulkActionButtons={<BulkDeleteWithConfirmButton />}
    actions={<TourActions />}
  >
    <Datagrid>
      <CroppedImageField />
      <TextField source="name" />
      <EditButton basePath="/tour" />
    </Datagrid>
  </List>
)

const TourTitle = ({ record }) => {
  return <span>{_.get(record, 'name', '')}</span>
}

const CommonForm = props => (
  <SimpleForm {...props}>
    <MuseumSelector {...props} />
    <CheckboxGroupInput
      source="publishedTo"
      choices={[{ id: 'APP', name: 'labels.app' }]}
      optionText="name"
      optionValue="id"
      label="resources.artifact.fields.publishedTo"
    />
    <CheckboxGroupInput
      source="publishedLanguages"
      choices={[
        { id: 'vi', name: 'languages.vi' },
        { id: 'en', name: 'languages.en' },
        { id: 'fr', name: 'languages.fr' },
        { id: 'zh', name: 'languages.zh' },
        { id: 'ja', name: 'languages.ja' },
        { id: 'ko', name: 'languages.ko' },
        { id: 'es', name: 'languages.es' },
        { id: 'it', name: 'languages.it' },
        { id: 'de', name: 'languages.de' },
      ]}
    />
    <NumberInput source="ranking" />
    <ReferenceArrayInput
      source="artifacts"
      reference="artifact"
      perPage={Infinity}
      allowEmpty
    >
      <AutocompleteArrayInput optionText="name" options={{ fullWidth: true }} />
    </ReferenceArrayInput>
    <ImageInput source="images" multiple accept="image/*">
      <ImageField source="uri" title="title" />
    </ImageInput>
    <MultiInput
      baseLang={baseLang}
      inputs={(baseLang, lang, key) => [
        <FormInput
          key={key}
          input={
            <TextInput
              label="labels.name"
              source={baseLang === lang ? 'name' : `i18n.${lang}.name`}
              validate={baseLang === lang ? [required()] : []}
              fullWidth
            />
          }
        />,
        <FormInput
          key={key}
          input={
            <FileInput
              label="labels.audio"
              source={lang === baseLang ? 'audio' : `i18n.${lang}.audio`}
              accept="audio/*"
            >
              <AudioField source="uri" title="title" />
            </FileInput>
          }
        />,
        <FormInput
          key={key}
          input={
            <LongTextInput
              label="labels.transcript"
              source={
                lang === baseLang
                  ? 'audioTranscript'
                  : `i18n.${lang}.audioTranscript`
              }
            />
          }
        />,
      ]}
    />
  </SimpleForm>
)

export const TourCreate = props => (
  <Create title="titles.create_tour" {...props}>
    <CommonForm {...props} redirect="list" />
  </Create>
)

export const TourEdit = props => (
  <Edit
    {...props}
    title={<TourTitle />}
    actions={<ReviewActions allowPublish={['admin', 'director']} />}
  >
    <CommonForm {...props} toolbar={<Toolbar />} />
  </Edit>
)
