import React from 'react'
import {
  BulkDeleteWithConfirmButton,
  CheckboxGroupInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Filter,
  FormTab,
  ImageField,
  ImageInput,
  List,
  ReferenceInput,
  SelectField,
  SelectInput,
  TabbedForm,
  TextInput,
  WithPermissions,
  required,
} from 'react-admin'
import {
  DateTimeInput,
  PlaceInput,
  ReviewActions,
  TextWithIndicator,
  MuseumSelector,
} from '../../components/index'
import { requireOne } from '../../util/permissions'
import ExhibitionActions from '../../components/ListActions'
import PreviewToolbar from '../../components/PreviewToolbar'
import RichTextInput from '../../components/RichTextInput'

const langs = process.env.REACT_APP_SUPPORTED_LANGUAGES.split(',')
const baseLanguage = process.env.REACT_APP_BASE_LANGUAGE

export { default as ExhibitionIcon } from '@material-ui/icons/ViewCarousel'

export const ExhibitionFilter = props => (
  <Filter {...props}>
    <SelectInput
      source="_type"
      choices={[
        {
          id: 'EXHIBITION',
          name: 'resources.exhibition.enums._type.EXHIBITION',
        },
        { id: 'EVENT', name: 'resources.exhibition.enums._type.EVENT' },
      ]}
    />
  </Filter>
)

export const ExhibitionList = props => (
  <List
    {...props}
    filters={<ExhibitionFilter />}
    bulkActionButtons={<BulkDeleteWithConfirmButton />}
    actions={<ExhibitionActions />}
  >
    <Datagrid>
      <TextWithIndicator label="labels.name" source="name" />
      <SelectField
        source="_type"
        choices={[
          {
            id: 'EXHIBITION',
            name: 'resources.exhibition.enums._type.EXHIBITION',
          },
          { id: 'EVENT', name: 'resources.exhibition.enums._type.EVENT' },
        ]}
      />
      <DateField source="startDate" />
      <DateField source="endDate" />
      <EditButton basePath="/exhibition" />
    </Datagrid>
  </List>
)

const ExhibitionTitle = ({ record }) => {
  return <span>{record ? `${record.name}` : ''}</span>
}

const CommonFormTab = ({ lang, ...props }) => (
  <FormTab {...props}>
    <MuseumSelector {...props} />
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['admin', 'director'])(permissions) ? (
          <CheckboxGroupInput
            source="publishedTo"
            choices={[
              { id: 'WEBSITE', name: 'labels.website' },
              { id: 'APP', name: 'labels.app', disabled: true },
            ]}
            optionText="name"
            optionValue="id"
            label="resources.artifact.fields.publishedTo"
          />
        ) : null
      }
    </WithPermissions>
    <CheckboxGroupInput
      source="publishedLanguages"
      choices={[
        { id: 'vi', name: 'languages.vi' },
        { id: 'en', name: 'languages.en' },
        { id: 'fr', name: 'languages.fr' },
      ]}
    />
    <SelectInput
      source="_type"
      choices={[
        { id: 'EVENT', name: 'resources.exhibition.enums._type.EVENT' },
        {
          id: 'EXHIBITION',
          name: 'resources.exhibition.enums._type.EXHIBITION',
        },
      ]}
      validate={[required()]}
    />
    <TextInput
      label="labels.name"
      source={baseLanguage === lang ? 'name' : `i18n.${lang}.name`}
      validate={baseLanguage === lang ? [required()] : []}
      fullWidth
    />
    <TextInput
      label="labels.organizer"
      source={baseLanguage === lang ? 'organizer' : `i18n.${lang}.organizer`}
      fullWidth
    />
    <DateTimeInput source="startDate" labelTime="labels.time" />
    <DateTimeInput source="endDate" labelTime="labels.time" />

    <ReferenceInput
      source="_collection"
      reference="collection"
      fullWidth
      perPage={Infinity}
      allowEmpty
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ImageInput
      source="images"
      accept="image/*"
      multiple
      validate={[required()]}
    >
      <ImageField source="uri" title="title" />
    </ImageInput>
    <PlaceInput
      label="labels.location"
      source={lang === baseLanguage ? 'location' : `i18n.${lang}.location`}
    />
    <RichTextInput
      label="labels.description"
      source={
        baseLanguage === lang ? 'description' : `i18n.${lang}.description`
      }
      toolbar={[['bold', 'italic', 'underline'], [{ align: [] }], ['image']]}
    />
  </FormTab>
)

export const ExhibitionEdit = props => (
  <Edit
    {...props}
    title={<ExhibitionTitle />}
    actions={<ReviewActions allowPublish={['admin', 'director']} />}
    undoable={false} // Don't allow undo so that change takes effect for immediate preview.
  >
    <TabbedForm toolbar={<PreviewToolbar />}>
      {langs.map(lang => (
        <CommonFormTab label={`languages.${lang}`} key={lang} lang={lang} />
      ))}
    </TabbedForm>
  </Edit>
)

export const ExhibitionCreate = props => (
  <Create title="titles.create_exhibition" {...props}>
    <TabbedForm redirect="list">
      {langs.map(lang => (
        <CommonFormTab label={`languages.${lang}`} key={lang} lang={lang} />
      ))}
    </TabbedForm>
  </Create>
)
