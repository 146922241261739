import React, { Fragment } from 'react'
import {
  ArrayInput,
  BulkDeleteWithConfirmButton,
  CheckboxGroupInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  FileField,
  FileInput,
  Filter,
  FormDataConsumer,
  FormTab,
  ImageInput,
  List,
  LongTextInput,
  required,
  SelectField,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  translate,
  WithPermissions,
} from 'react-admin'
import getYouTubeID from 'get-youtube-id'
import {
  ImageField,
  MuseumSelector,
  ReviewActions,
  TextWithIndicator,
} from '../../components'
import { requireOne } from '../../util/permissions'
import FANewspaper from 'react-icons/lib/fa/newspaper-o'
import RichTextInput from '../../components/RichTextInput'
import NewsActions from '../../components/ListActions'
import PreviewToolbar from '../../components/PreviewToolbar'
import validURL from '../../util/validURL'

export const NewsIcon = props => <FANewspaper size={24} {...props} />

export const NewsFilter = props => (
  <Filter {...props}>
    <TextInput label="resources.news.search" source="title[$search]" alwaysOn />
  </Filter>
)

export const NewsList = props => (
  <List
    {...props}
    filters={<NewsFilter />}
    bulkActionButtons={<BulkDeleteWithConfirmButton />}
    actions={<NewsActions />}
  >
    <Datagrid>
      <TextWithIndicator label="labels.title" source="title" />
      <SelectField
        source="articleType"
        choices={[
          { id: 'NEWS', name: 'resources.news.enums.articleType.NEWS' },
          { id: 'BLOG', name: 'resources.news.enums.articleType.BLOG' },
          { id: 'MEDIA', name: 'resources.news.enums.articleType.MEDIA' },
          {
            id: 'EDUCATION_CREATIVE',
            name: 'resources.news.enums.articleType.EDUCATION_CREATIVE',
          },
          {
            id: 'EDUCATION_PRESENTATION',
            name: 'resources.news.enums.articleType.EDUCATION_PRESENTATION',
          },
        ]}
        validate={[required()]}
      />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <EditButton basePath="/news" />
    </Datagrid>
  </List>
)

const langs = process.env.REACT_APP_SUPPORTED_LANGUAGES.split(',')
const baseLang = process.env.REACT_APP_BASE_LANGUAGE

const validYouTubeLink = value =>
  getYouTubeID(value) ? undefined : 'Địa chỉ URL YouTube không hợp lệ.'

const NewsTitle = ({ record }) => {
  return <span>{record ? `${record.title}` : ''}</span>
}

const CommonFormTab = ({ lang, ...props }) => (
  <FormTab {...props}>
    <MuseumSelector {...props} optional="true" />
    <WithPermissions>
      {({ permissions }) =>
        requireOne(['admin', 'director'])(permissions) ? (
          <CheckboxGroupInput
            source="publishedTo"
            choices={[
              { id: 'WEBSITE', name: 'labels.website' },
              { id: 'APP', name: 'labels.app', disabled: true },
            ]}
            optionText="name"
            optionValue="id"
            label="resources.artifact.fields.publishedTo"
          />
        ) : null
      }
    </WithPermissions>
    <CheckboxGroupInput
      source="publishedLanguages"
      choices={[
        { id: 'vi', name: 'languages.vi' },
        { id: 'en', name: 'languages.en' },
        { id: 'fr', name: 'languages.fr' },
      ]}
    />
    <SelectInput
      source="articleType"
      choices={[
        { id: 'NEWS', name: 'resources.news.enums.articleType.NEWS' },
        { id: 'BLOG', name: 'resources.news.enums.articleType.BLOG' },
        { id: 'MEDIA', name: 'resources.news.enums.articleType.MEDIA' },
        {
          id: 'EDUCATION_CREATIVE',
          name: 'resources.news.enums.articleType.EDUCATION_CREATIVE',
        },
        {
          id: 'EDUCATION_PRESENTATION',
          name: 'resources.news.enums.articleType.EDUCATION_PRESENTATION',
        },
      ]}
      validate={[required()]}
    />
    <TextInput
      options={{ fullWidth: true }}
      label="labels.title"
      source={baseLang === lang ? 'title' : `i18n.${lang}.title`}
      validate={baseLang === lang ? [required()] : ''}
      fullWidth
    />
    <TextInput
      options={{ fullWidth: true }}
      label="labels.subTitle"
      source={baseLang === lang ? 'subTitle' : `i18n.${lang}.subTitle`}
      fullWidth
    />
    <RichTextInput
      label="labels.description"
      source={baseLang === lang ? 'description' : `i18n.${lang}.description`}
      toolbar={[['bold', 'italic', 'underline'], [{ align: [] }], ['image']]}
    />
    <FormDataConsumer>
      {({ formData, ...rest }) =>
        formData.articleType === 'MEDIA' ? (
          <Fragment>
            <TextInput
              {...rest}
              source="youtubeLink"
              options={{ fullWidth: true }}
              validate={[required(), validYouTubeLink]}
            />
            <ImageField {...rest} source="images" src="uri" title="title" />
          </Fragment>
        ) : (
          <ImageInput {...rest} source="images" multiple accept="image/*">
            <ImageField source="uri" title="title" aspectRatio="3:2" />
          </ImageInput>
        )
      }
    </FormDataConsumer>
    <ArrayInput
      fullWidth
      label="resources.artifact.fields.referenceLinks"
      source="referenceLinks"
    >
      <SimpleFormIterator>
        <LongTextInput
          label="labels.name"
          source="name"
          validate={required()}
        />
        <LongTextInput
          label="URL"
          source="url"
          validate={[validURL, required()]}
        />
      </SimpleFormIterator>
    </ArrayInput>
    <FileInput source="attachments" accept="application/pdf" multiple>
      <FileField source="url" src="url" title="title" target="_blank" />
    </FileInput>
  </FormTab>
)

export const NewsCreate = translate(props => (
  <Create title="titles.create_news" {...props}>
    <TabbedForm redirect="list">
      {langs.map(lang => (
        <CommonFormTab label={`languages.${lang}`} key={lang} lang={lang} />
      ))}
    </TabbedForm>
  </Create>
))

export const NewsEdit = translate(props => (
  <Edit
    {...props}
    title={<NewsTitle />}
    actions={<ReviewActions allowPublish={['admin', 'director']} />}
    undoable={false} // Don't allow undo so that change takes effect for immediate preview.
  >
    <TabbedForm toolbar={<PreviewToolbar />}>
      {langs.map(lang => (
        <CommonFormTab label={`languages.${lang}`} key={lang} lang={lang} />
      ))}
    </TabbedForm>
  </Edit>
))
