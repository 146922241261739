// A draggable ArtifactFeature for setting the location of an artifact on the map
import React from 'react'
import * as turf from '@turf/helpers'
import buffer from '@turf/buffer'
import bbox from '@turf/bbox'
import bboxPolygon from '@turf/bbox-polygon'
import rotate from '@turf/transform-rotate'
import center from '@turf/center'
import { Layer } from 'react-mapbox-gl'
import Feature from 'react-mapbox-gl/lib/feature'
import pin from '../images/pin.png'

const image = new Image()
image.src = pin

const createArtifactMarker = coordinates => {
  const point = turf.point(coordinates)
  const buff = buffer(point, 0.5, { units: 'meters' })
  const box = bbox(buff)
  const poly = bboxPolygon(box)
  return rotate(poly, 14.5, { mutate: false })
}

class ArtifactFeature extends React.Component {
  render() {
    return (
      <Layer
        type="symbol"
        layout={{
          'icon-image': 'pin',
          'icon-size': 0.1,
          'icon-anchor': 'bottom',
        }}
        images={['pin', image]}
      >
        <Feature
          coordinates={center(this.props.data).geometry.coordinates}
          draggable={true}
          onDragEnd={({ lngLat: { lng, lat } }) => {
            const artifactMarker = createArtifactMarker([lng, lat])
            this.props.onDragEnd(artifactMarker)
          }}
        />
      </Layer>
    )
  }
}

export default ArtifactFeature
