// Initially copied from https://github.com/marmelab/react-admin/blob/master/packages/ra-input-rich-text/src/index.js
import debounce from 'lodash/debounce'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Quill from 'quill'
import { ImageUpload } from 'quill-image-upload'
import BlotFormatter from 'quill-blot-formatter/dist/BlotFormatter'
import { addField } from 'react-admin'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import axios from 'axios'

Quill.register('modules/blotFormatter', BlotFormatter)
Quill.register('modules/imageUpload', ImageUpload)

// BEGIN allow image alignment styles
// Workaround for this issue: https://github.com/Fandom-OSS/quill-blot-formatter/issues/5
// as described here: https://github.com/kensnyder/quill-image-resize-module/issues/43#issuecomment-390728983
const ImageFormatAttributesList = ['alt', 'height', 'width', 'style']

var BaseImageFormat = Quill.import('formats/image')
class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute)
      }
      return formats
    }, {})
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value)
      } else {
        this.domNode.removeAttribute(name)
      }
    } else {
      super.format(name, value)
    }
  }
}

Quill.register(ImageFormat, true)
// END allow image alignment styles

export class RichTextInput extends Component {
  static propTypes = {
    addLabel: PropTypes.bool.isRequired,
    classes: PropTypes.object,
    input: PropTypes.object,
    label: PropTypes.string,
    meta: PropTypes.object,
    options: PropTypes.object,
    source: PropTypes.string,
    toolbar: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  }

  static defaultProps = {
    addLabel: true,
    options: {},
    record: {},
    toolbar: true,
  }

  componentDidMount() {
    const {
      input: { value },
      toolbar,
    } = this.props

    this.quill = new Quill(this.divRef, {
      modules: {
        toolbar,
        blotFormatter: {},
        imageUpload: {
          url: `${process.env.REACT_APP_API_HOST || window.env.API_URL}/upload`, // server url
          method: 'POST', // change query method, default 'POST'
          headers: { 'form-field': 'image' }, // add custom headers, example { token: 'your-token'}
          // personalize successful callback and call next function to insert new url to the editor
          callbackOK: (id, next) => {
            axios
              .get(
                `${process.env.REACT_APP_API_HOST ||
                  window.env.API_URL}/image/${id}`
              )
              .then(({ data: { uri } }) => next(uri))
          },
          // personalize failed callback
          callbackKO: () => {},
        },
      },
      theme: 'snow',
    })

    this.quill.setContents(this.quill.clipboard.convert(value))

    this.editor = this.divRef.querySelector('.ql-editor')
    this.quill.on('text-change', debounce(this.onTextChange, 500))
  }

  componentWillUnmount() {
    this.quill.off('text-change', this.onTextChange)
    this.quill = null
  }

  onTextChange = () => {
    const value =
      this.editor.innerHTML === '<p><br></p>' ? '' : this.editor.innerHTML
    this.props.input.onChange(value)
  }

  updateDivRef = ref => {
    this.divRef = ref
  }

  render() {
    const { error, helperText = false } = this.props.meta
    return (
      <FormControl error={error} className="ra-rich-text-input">
        <div ref={this.updateDivRef} />
        {error && <FormHelperText>{error}</FormHelperText>}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }
}

const RichRextInputWithField = addField(withStyles(styles)(RichTextInput))

RichRextInputWithField.defaultProps = {
  addLabel: true,
}
export default RichRextInputWithField
