import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import Switch from '@material-ui/core/Switch'
import { Fields } from 'redux-form'
import moment from 'moment-timezone'
import _ from 'lodash'
import FormControlLabel from '@material-ui/core/FormControlLabel'

class DeathdayInput extends Component {
  state = {
    date: null,
    accuracy: 'full',
    dateValues: {
      year: '',
      month: '',
      day: '',
      bc: false,
    },
  }

  static parseDate(date, accuracy) {
    if (!date) {
      return { year: '', month: '', day: '', bc: false }
    } else {
      date = moment(date)
    }

    let year = Number.parseInt(date.tz('UTC').format('YYYY'), 10)
    let month = date.tz('UTC').format('MM')
    let day = date.tz('UTC').format('DD')
    let bc = false

    if (year < 0) {
      bc = true
      year = year * -1
    }

    switch (accuracy) {
      case 'noMonth':
        return { year, month: '', day: '', bc }
      case 'noDay':
        return { year, month, day: '' }
      default:
        return { year, month, day, bc }
    }
  }

  componentDidMount() {
    const date = this.props.deathday.input.value
    const accuracy = this.props.deathdayAccuracy.input.value
    const dateValues = DeathdayInput.parseDate(date, accuracy)

    this.setState({ date, accuracy, dateValues })
  }

  calculateDate(newValues) {
    const dateValues = { ...this.state.dateValues, ...newValues }
    this.setState({ dateValues })

    const { year, month, day, bc } = dateValues

    // If there is no year then the date is essentially null
    if (!year) {
      this.props.deathday.input.onChange(null)
      this.props.deathdayAccuracy.input.onChange('full')
      return
    }

    // Calculate the accuracy of the date based on the values
    let accuracy

    if (_.isEmpty(month) || _.isEmpty(day)) {
      accuracy = _.isEmpty(month) ? 'noMonth' : 'noDay'
    } else {
      accuracy = 'full'
    }

    let YYYYYY = _.padStart(year, 6, '0')
    let MM = month ? _.padStart(month, 2, '0') : '01'
    let DD = day ? _.padStart(day, 2, '0') : '01'

    let date = `${bc ? '-' : '+'}${YYYYYY}-${MM}-${DD}`

    this.setState({ date, accuracy })
    this.props.deathday.input.onChange(date)
    this.props.deathdayAccuracy.input.onChange(accuracy)
  }

  render() {
    return (
      <div>
        <TextField
          label="Năm chết"
          type="number"
          value={this.state.dateValues.year}
          onChange={e => this.calculateDate({ year: e.target.value })}
        />
        <TextField
          label="Tháng chết"
          type="number"
          value={this.state.dateValues.month}
          onChange={e => this.calculateDate({ month: e.target.value })}
        />
        <TextField
          label="Ngày chết"
          type="number"
          value={this.state.dateValues.day}
          onChange={e => this.calculateDate({ day: e.target.value })}
        />
        <FormControlLabel
          control={
            <Switch
              checked={this.state.dateValues.bc}
              onToggle={(_, isInputChecked) =>
                this.calculateDate({ bc: isInputChecked })
              }
            />
          }
          label="B.C."
        />
        <FormControlLabel
          control={
            <Switch
              checked={this.props.deathdayCirca.input.value}
              onChange={(_, isInputChecked) =>
                this.props.deathdayCirca.input.onChange(isInputChecked)
              }
            />
          }
          label="Gần một ngày nào đó"
        />
      </div>
    )
  }
}

const withFields = () => (
  <Fields
    names={['deathday', 'deathdayAccuracy', 'deathdayCirca']}
    component={DeathdayInput}
  />
)

export default withFields
