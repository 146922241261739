import React from 'react'
import Card from '@material-ui/core/Card'
import CardTitle from '@material-ui/core/CardHeader'
import {
  Table,
  TableBody,
  TableCell as TableRowColumn,
  TableRow,
} from '@material-ui/core'
import { translate } from 'react-admin'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'

const GET_TOP_10_ARTIFACTS = gql`
  {
    artifacts(sortField: "views", sortOrder: DSC, limit: 10) {
      _id
      name
      views
    }
  }
`

const styles = {
  card: { borderLeft: 'solid 4px #ff9800', flex: 1, marginLeft: '1em' },
  icon: {
    float: 'right',
    width: 64,
    height: 64,
    padding: 16,
    color: '#ff9800',
  },
  table: { width: '100%', tableLayout: 'auto' },
}

const RankCard = ({ items = [] }) => (
  <Card style={styles.card}>
    <CardTitle title="10 hiện vật được xem nhiều nhất" />
    <Table style={styles.table}>
      <TableBody>
        <TableRow style={{ color: 'rgb(135, 136, 140)' }}>
          <TableRowColumn>Tên</TableRowColumn>
          <TableRowColumn>Lượt xem</TableRowColumn>
        </TableRow>
        {items.map(item => (
          <TableRow key={item._id}>
            <TableRowColumn>
              <a style={{ color: '#ee7b4c' }} href={`/#/artifact/${item._id}`}>
                {item.name}
              </a>
            </TableRowColumn>
            <TableRowColumn>{item.views}</TableRowColumn>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Card>
)

export const Top10Artifacts = () => {
  const RCard = props => <RankCard title="Top 10 Artifacts" {...props} />

  return (
    <Query query={GET_TOP_10_ARTIFACTS}>
      {({ loading, error, data = { artifacts: [] } }) => {
        if (loading) return <RCard loading={true} />
        if (error) return <RCard error="error loading data" />

        return <RCard items={data.artifacts} />
      }}
    </Query>
  )
}

export default translate(RankCard)
