import validator from 'validator'
import _ from 'lodash'

export default value => {
  if (!value) {
    return undefined
  }

  if (!_.startsWith(value, 'http://') && !_.startsWith(value, 'https://')) {
    return "URL phải bắt đầu bằng 'https://' hoặc 'http://'"
  }

  if (!validator.isURL(value)) {
    return 'URL không hợp lệ'
  }

  return undefined
}
