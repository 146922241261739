import React from 'react'
import { translate } from 'react-admin'

const TranslatedTextField = ({
  record = {},
  source,
  namespace = '',
  translate,
}) => {
  const key = namespace ? `${namespace}.${record[source]}` : record[source]
  return <span>{translate(key)}</span>
}

export default translate(TranslatedTextField)
