import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import Publish from '@material-ui/icons/Publish'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { WithPermissions, translate } from 'react-admin'
import compose from 'recompose/compose'
import {
  publishRecord as publishRecordAction,
  unpublishRecord as unpublishRecordAction,
} from '../actions/publishActions'
import { canPublish, canUpdate } from '../resource/util'
import { requireOne } from '../util/permissions'

class PublishButton extends Component {
  handlePublish = () => {
    const {
      publishRecord,
      record,
      resource,
      basePath,
      redirect = true,
    } = this.props

    const bPath = !redirect ? `${basePath}/${record.id}` : basePath

    publishRecord(record.id, record, resource, bPath)
  }

  handleUnpublish = () => {
    const {
      unpublishRecord,
      record,
      resource,
      basePath,
      redirect = true,
    } = this.props

    const bPath = !redirect ? `${basePath}/${record.id}` : basePath

    unpublishRecord(record.id, record, resource, bPath)
  }

  render() {
    const { record, translate, allowUnpublish } = this.props
    return !record ? (
      <span />
    ) : canPublish(record) ? (
      <Button color="primary" onClick={this.handlePublish}>
        <Publish />
        {translate(`actions.publish`)}
      </Button>
    ) : (
      <span>
        <WithPermissions>
          {({ permissions }) =>
            requireOne(allowUnpublish)(permissions) ? (
              <Button color="primary" onClick={this.handleUnpublish}>
                <VisibilityOff />
                {translate(`actions.unpublish`)}
              </Button>
            ) : null
          }
        </WithPermissions>
        {!canUpdate(record) ? (
          <span />
        ) : (
          <Button color="primary" onClick={this.handlePublish}>
            <Publish />
            {translate(`actions.update`)}
          </Button>
        )}
      </span>
    )
  }
}

PublishButton.propTypes = {
  record: PropTypes.object,
  publishRecord: PropTypes.func,
  translate: PropTypes.func,
  resource: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
}

const enhance = compose(
  translate,
  connect(
    null,
    {
      publishRecord: publishRecordAction,
      unpublishRecord: unpublishRecordAction,
    }
  )
)

export default enhance(PublishButton)
