import axios from 'axios'
import axiosRetry from 'axios-retry'
import { set, get } from 'lodash'
import getObjectPaths from '../util/getObjectPaths'

export const uploadFile = async (file, options = {}) => {
  const data = new FormData()
  data.append('file', file)

  // In some cases server will crash after image upload.
  // We should try again in order to send request to new/alternate api server.
  axiosRetry(axios, {
    retries: options.retries,
    retryDelay: axiosRetry.exponentialDelay,
    shouldResetTimeout: false,
  })

  return await axios.post(`${options.apiUrl}/upload`, data, {
    timeout: options.timeout,
  })
}

const processFiles = () => async context => {
  const paths = getObjectPaths(context.data)

  // Only upload one image at a time to prevent overloading the server.
  for (const path of paths) {
    const rawFile = get(context.data, `${path}.rawFile`)

    if (!rawFile || !(rawFile instanceof File)) continue

    const options = path.includes('highQualityImages')
      ? {
          apiUrl: process.env.REACT_APP_API_HQ_HOST,
          timeout: 900000, // 15min : 5min
          retries: 1,
        }
      : {
          apiUrl: process.env.REACT_APP_API_HOST || window.env.API_URL,
          timeout: 300000, // 5min
          retries: 2,
        }

    try {
      const { data } = await uploadFile(rawFile, options)

      if (rawFile.type === 'application/pdf') {
        // PDF files are stored directly in the document.
        set(context.data, path, {
          title: rawFile.name,
          url: data.uri,
          mimeType: data.mimetype,
          size: data.size,
        })
      } else {
        // For image, audio and video files we store an reference id to a document in a separate collection.
        set(context.data, path, data) // The response is the id!
      }
    } catch (e) {
      // If something failed, return from the function and don't try to upload any more images.
      return Promise.reject(e)
    }
  }
}

export default processFiles
