import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import axios from 'axios'
import { AppBarMobile, GET_LIST, translate } from 'react-admin'

import { ArtifactCount, CategoryCount } from './CountCard'
import { Top10Artifacts } from './RankCard'
import { restClient } from '../feathersClient'
import { version } from '../../package.json'

class Dashboard extends Component {
  state = {}

  async componentDidMount() {
    const artifacts = await restClient(GET_LIST, 'artifact', {
      pagination: { page: 1, perPage: 0 },
    })

    const categories = await restClient(GET_LIST, 'category', {
      pagination: { page: 1, perPage: 0 },
    })
    this.setState({ artifactCount: artifacts.total })
    this.setState({ categoryCount: categories.total })
    const {
      data: [museum],
    } = await restClient(GET_LIST, 'museum', {
      pagination: { page: 1, perPage: 1 },
    })

    // TODO: Use a proper GraphQL client
    const res = await axios(
      `${process.env.REACT_APP_API_HOST ||
        window.env.API_URL}/graphql?query=%7B%0A%20%20museum(_id%3A%22${
        museum._id
      }%22)%7B%0A%20%20%20%20_id%0A%20%20%20%20name%0A%20%20%20%20statistics%20%7B%0A%20%20%20%20%20%20totalArtifactViews%0A%20%20%20%20%20%20totalArtifactScans%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`,
          'X-Api-Key': process.env.REACT_APP_API_KEY,
        },
      }
    )
    const { totalArtifactScans } = res.data.data.museum.statistics
    this.setState({ scanCount: totalArtifactScans })
    this.setState({ museum })
  }

  render() {
    const { artifactCount, categoryCount } = this.state

    const { width } = this.props

    const footerStyle = {
      maxWidth: '50%',
      margin: '20px 0px 0px 16px',
    }

    const contentFooterStyle = {
      backgroundColor: '#d1d1d1',
      width: '100%',
      padding: 10,
    }

    return (
      <div>
        {width === 1 && <AppBarMobile title="iMuseum Admin" />}
        <Grid container spacing={24}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <ArtifactCount value={artifactCount} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CategoryCount value={categoryCount} />
          </Grid>
          <Grid item sm={12} md={12} lg={6}>
            <Top10Artifacts />
          </Grid>
        </Grid>
        <footer style={footerStyle}>
          <span style={contentFooterStyle}>
            {`Revision: ${process.env.REACT_APP_REVISION} | Version: ${version}`}
          </span>
        </footer>
      </div>
    )
  }
}

export default translate(Dashboard)
