import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { GET_LIST } from 'react-admin'
import { restClient } from './../components/../feathersClient'
import ReactMapboxGl, { ZoomControl } from 'react-mapbox-gl'
import _ from 'lodash'
import { SizeMe } from 'react-sizeme'
import axios from 'axios'
import LevelControl from '../components/LevelControl'
import { FloorLayer, WallLayer, RoomLayer } from '../components/MapLayers'
import DeviceMarker from '../components/DeviceMarker'
import qs from 'query-string'
import { app } from '../feathersClient'
import turfCenter from '@turf/center'
import globalData from "../global/global-data";

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  minZoom: 4.5,
  maxZoom: 21,
})

class RentalDeviceMap extends React.Component {
  state = {
    data: [],
    floors: globalData.data.floors || [],
    rooms: [],
    currentLevel: _.get(this, 'props.level', 0),
    zoomLevel: 17.5,
  }

  async componentDidMount() {
    const { data } = await restClient(GET_LIST, 'rentaldevice', {})
    const { data: rooms } = await restClient(GET_LIST, 'block', {})
    this.setState({ data, rooms })
  }

  render() {
    const geojson = _.get(
      this,
      `state.floors.[${this.state.currentLevel}].map`,
      { type: 'FeatureCollection', features: [] }
    )

    const location = {
      lng: 105.8369,
      lat: 21.0307,
    }
    const center = [location.lng, location.lat]

    const rooms = []
    this.state.data.forEach(device => {
      const currentRoom = _.get(device, 'location.currentRoom')
      if (currentRoom) {
        rooms.push(currentRoom)
      }
    })

    const blocks = []
    this.state.rooms.forEach(room => {
      if (
        rooms.includes(room.number) &&
        _.get(room, 'location.geoJson.features.0')
      ) {
        const floor = _.find(this.state.floors, { _id: room.floor })
        if (floor && floor.number == this.props.level) {
          blocks.push(room)
        }
      }
    })

    return (
      <Card>
        <CardContent>
          <Map
            style="mapbox://styles/lihop/cjm75gm470dc32rmpfw2bd8x2" // eslint-disable-line
            containerStyle={{
              height: 384,
              width: 'auto',
            }}
            center={center}
            zoom={[this.state.zoomLevel]}
            bearing={[14.5]}
          >
            <ZoomControl />
            <FloorLayer data={geojson} />
            {blocks.map(block => (
              <RoomLayer data={block.location.geoJson.features[0]} />
            ))}
            <WallLayer data={geojson} />
            {this.state.data.map(device => {
              let coordinates = [0, 0]
              let usedGPS = false

              const result = []
              const currentRoom = _.get(device, 'location.currentRoom')
              if (currentRoom) {
                // Center the device in the room.
                const block = _.find(blocks, { number: currentRoom })
                if (block) {
                  const blockCenter = turfCenter(
                    block.location.geoJson.features[0]
                  )
                  coordinates = blockCenter.geometry.coordinates
                }
              } else if (
                _.get(device, 'location.lng') &&
                _.get(device, 'location.lat')
              ) {
                // Use GPS coordinates. Always show GPS on level 0.
                coordinates = [device.location.lng, device.location.lat]
                usedGPS = true
              }

              if (!usedGPS || this.props.level == 2) {
                result.push(
                  <DeviceMarker
                    key={device.id}
                    device={device}
                    coordinates={coordinates}
                    popoverOpen={this.state.selected == device.id}
                    onSelected={this.setSelected}
                  />
                )
              }

              return result
            })}
          </Map>
        </CardContent>
      </Card>
    )
  }
}

export default RentalDeviceMap
