import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
  typography: {
    fontFamily: '"Roboto", sans-serif',
  },
  palette: {
    primary: {
      main: '#ee7b4c',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ee7b4c',
      contrastText: '#fff',
    },
    error: {
      main: '#f44336',
    },
    contrastThreshold: 3,
    tonalOffset: 0,
  },
})
