import React from 'react'
import { TextField } from 'react-admin'
import StatusBadge from './StatusBadge'
import AlertBadge from './AlertBadge'

const TextWithIndicator = props => {
  let text = <TextField {...props} />

  if (props.type == 'alert') {
    return (
      <AlertBadge type="text" record={props.record}>
        {text}
      </AlertBadge>
    )
  }

  return (
    <StatusBadge type="text" record={props.record}>
      {text}
    </StatusBadge>
  )
}

export default TextWithIndicator
